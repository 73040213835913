import Token from '../tools/csrf_token.js'

const options = {
  credentials: 'same-origin',
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-CSRF-Token': Token()
  }
}

export function savePhotoPosition(url, priority) {
  return fetch(url, {
    ...options,
    body: JSON.stringify({ priority: priority })
  })
}

export function savePhotoDescription(url, description) {
  const imgdesc = description || ''
   return fetch(url, {
     ...options,
     body: JSON.stringify({ image_description: description })
     })
}

export async function deletePhoto(url) {
  return fetch(url, {
    ...options,
    method: 'DELETE'
  })
}
