<template lang='pug'>
  .vue-template-wrapper
    .grid.calendar-grid.edge(data-title="Add your week to earn Keys" data-intro="Click on a given week on the calendar." data-step="2" )
      .cell.twelve.medium-six.left-calendar
        CalendarMonth(:startDay='calendarOneStart'
                      :selectedDate='selectedDate'
                      :deposits='allDeposits'
                      :showPreviousArrow='true'
                      :depositRange='depositRange'
                      @previousMonth="$emit('previousMonth')"
                      @dateSelected='dateSelected'
                      :seasonality="seasonality",
                      :keyEarningStartDate='keyEarningStartDate'
                      :selectedRange="selectedRange"
                      )
      .cell.twelve.medium-six.right-calendar
        CalendarMonth(:startDay='calendarTwoStart'
                      :selectedDate='selectedDate'
                      :deposits='allDeposits'
                      :showNextArrow='true'
                      :depositRange='depositRange'
                      @nextMonth="$emit('nextMonth')"
                      @dateSelected='dateSelected'
                      :seasonality="seasonality"
                      :keyEarningStartDate='keyEarningStartDate'
                      :selectedRange="selectedRange"
                      )
</template>

<script>
import CalendarMonth from './CalendarMonth.vue'

export default {
  components: { CalendarMonth },
  props: {
    selectedDate: { type: String, required: true },
    calendarStart: { type: String, required: true },
    existingDeposits: { type: Array },
    selectedDeposits: { type: Array },
    depositRange: { required: true },
    seasonality: { required: true },
    keyEarningStartDate: {required: true},
    selectedRange: {type: Object, required: true }
  },
  computed: {
    calendarOneStart () {
      return this.calendarStart;
    },
    calendarTwoStart () {
      return dayjs(this.calendarStart)
        .add(1, 'months')
        .format('YYYY-MM-DD');
    },
    allDeposits () {
      return this.selectedDeposits.concat(this.existingDeposits);
    }
  },
  methods: {
    dateSelected (date) { 
      this.$emit('dateSelected', date); 
      
    const event = new Event("proceed-introjs-tour", {bubbles: true});
    this.$el.dispatchEvent(event);
    }
  }
}
</script>
