<template lang="pug">
a(:href="notificationLink")
  .card.white-card.small-card.notification(:class="readClass")
    .card-content
      .grid
        .cell.icon.fit.collapse.collapse-padding
          span(:class="selectIcon")
        .cell.fill.collapse
          .grid.collapse-padding.notification-header
            .cell.fill.collapse {{ this.formatCategory(notification.category) }}
            .cell.fit.text-right {{ this.formatDate(notification.created_at) }}
          .notification-content {{ notification.message }}
          div(v-if="hasLinkMessage", class="notification-action underline")
            | {{ notification.link_message }}
</template>

<script>
  import UserIcon from './icons/UserIcon.vue'
  import NewAvailabilityIcon from './icons/NewAvailabilityIcon.vue'
  import formatTheDate from './dateFormatter'
  import startCase from 'lodash/startCase'

  export default {
    props: {
      notification: {type: Object, required: true},
      read: {type: Boolean, required: false, default: false}
    },
    components: { UserIcon, NewAvailabilityIcon },
    methods: {
      formatCategory(category) {
        return startCase(category)
      },
      formatDate(date) {
        return formatTheDate(date)
      },
      iconFor(category) {
        switch(category) {
          case "week_request":
            return 'third-font-email-time'
          case "review_request":
            return 'third-font-speech'
          case "keys_expiring":
            return 'third-font-key'
          case "new_availability": case "new_reservation":
            return 'third-font-new'
          case "membership_expiring":
            return 'third-font-user'
          default:
            return ''
        }
      },
      isRead() {
        return this.read
      }
    },
    computed: {
      selectIcon() {
        return this.iconFor(this.notification.category)
      },
      readClass() {
        let baseClass = "notification"
        return this.isRead() ? `${baseClass} read` : baseClass
      },
      notificationLink() {
        return `/notifications/${this.notification.id}`
      },
      hasLinkMessage() {
        return this.notification.link_message !== "" && this.notification.link_message !== null
      }
    }
  }
</script>
