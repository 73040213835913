import intlTelInput from 'intl-tel-input'
import utilsScript from 'intl-tel-input/build/js/utils.js'
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field"]

  connect() {
    this.telInput = intlTelInput(this.fieldTarget, {
      preferredCountries: ['us', 'gb', 'ca'],
      customPlaceholder: ()=> 'Phone Number'
    })
  }

  submit() {
    let number = this.telInput.getNumber()
    this.fieldTarget.value = number
  }
}
