<template lang="pug">
.photo.cell.six.medium-four.text-right(:photoId="photo.id")
  img(:src="photo.small" alt="image_description")
  a(href="#" @click.prevent="showEditModal(photo)") edit
</template>

<script>
export default {
  props: {
    photo: { type: Object, required: true },
    showEditModal: { type: Function, required: true }
  }
}
</script>
