import googleMaps from '../../google-maps';

let autocompleteSource = undefined;

const placesSearch = {
  search(value) {
    return new Promise((resolve, reject) => {
      googleMaps.then((google) => {
        autocompleteSource = autocompleteSource || new google.maps.places.AutocompleteService();
        autocompleteSource.getPlacePredictions({
            input: value,
            types: ["(regions)"],
            location: new google.maps.LatLng({lat: 0, lng: 0}),
            radius: 1
          }, (suggestions) => {
            if (!Array.isArray(suggestions) || !suggestions.length) return resolve([]);
            return resolve(suggestions.map(suggestion => ({
              data: {place_id: suggestion.place_id},
              description: suggestion.description,
              path: `/properties/search?place_id=${suggestion.place_id}`,
              type: 'place'
          })));
        })
      });
    });
  }
}

export default placesSearch;
