var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("br"),
    _c("h4", [_vm._v("Primary Photo")]),
    _c("div", { staticClass: "primary-photo photo" }, [
      _c("div", { staticClass: "grid edge" }, [
        _c(
          "div",
          { staticClass: "cell twelve medium-eight" },
          [
            _vm.photo
              ? [
                  _c("img", {
                    attrs: {
                      src: _vm.photo.big,
                      alt: _vm.photo.image_description,
                      title: _vm.photo.image_description
                    }
                  })
                ]
              : [_vm._m(0)]
          ],
          2
        ),
        _vm._m(1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "callout" }, [
      _c("br"),
      _c("h5", [_vm._v("There is no primary photo.")]),
      _c("p", [
        _vm._v('Use the "Add Photos" button below to start adding images.')
      ]),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell twelve medium-four" }, [
      _c("p", { staticClass: "small" }, [
        _vm._v(
          "This is the main photo that will be displayed to members as they browse the list of properties. It is also the first photo displayed at the top of your listing page."
        )
      ]),
      _c("p", { staticClass: "small" }, [
        _vm._v(
          "You can upload additional photos below as well. The more you can add the better!"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }