class SelectorProcessor
  constructor: ->
    @selectors = {}
  add: (selector, callback) ->
    @selectors[selector] ?= []
    @selectors[selector].push(callback)
  remove: (selector, callback) ->
    callbacks = @selectors[selector]
    return if !callbacks
    @selectors[selector] = callbacks.filter (_callback) -> _callback != callback
  processElements: (elements) =>
    executions = new Map()
    elements.forEach (e) =>
      @processElement(e, executions)
    executions.forEach (elements, callback) ->
      callback(elements)

  processElement: (element, executions) =>
    for selector, callbacks of @selectors
      for matchingElement in @findMatchingElements(element, selector)
        for callback in callbacks
          selectorArr = executions.get(callback)
          if selectorArr && selectorArr.length
            selectorArr.push(matchingElement)
          else
            executions.set(callback, [matchingElement])

  findMatchingElements: (element, selector) ->
    elements = []
    elements.push(element) if matches(element, selector)
    elements.concat(element.querySelectorAll(selector)...)

  matches = do ->
    element = document.documentElement
    method = element.matches ?
      element.matchesSelector ?
      element.webkitMatchesSelector ?
      element.mozMatchesSelector ?
      element.msMatchesSelector

    (element, selector) ->
      method.call(element, selector)

export default SelectorProcessor;
