import { Controller } from "@hotwired/stimulus"
import LocalStorage from '../../tools/storage-wrapper'
import introJs from 'intro.js/intro.js';

export default class extends Controller {
  static values = {
    steps: { type: Boolean, default: true },
    tourName: String,
    disableAutoTour: { type: Boolean, default: false }
  }
  
  initialize() {
    this._createIntroInstance()

    const startTourByURL = new URLSearchParams(window.location.search).has('tour');
    const startTourByLocalStore = LocalStorage.available && !LocalStorage.get('tours')?.[this.tourNameValue]
    const startTourDisabled = this.disableAutoTourValue

    if (startTourByURL || !startTourDisabled && startTourByLocalStore) {
      setTimeout(() => { this._startTourAutomatically() }, 3000);
    }
  }

  startTour() {
    this.userHasStartedTour = true
    LocalStorage.add('tours', {...LocalStorage.get('tours'), ...{[this.tourNameValue]: true}})
    this._enableIntroInstance()
    this.intro.start();

    document.body.classList.add('active-tour')
  }

  proceedTour() {
    if (this.intro.isActive()) {
      introJs().refresh()
      setTimeout(() => { this.intro.nextStep() }, 500)
    }
  }

  _startTourAutomatically() {
    if (this.userHasStartedTour) return
    this.startTour()
  }

  _createIntroInstance() {
    this.intro = introJs().setOptions({
      'showStepNumbers': this.stepsValue,
      'keyboardNavigation': true,
      'showBullets': false,
      'buttonClass': 'tour-button',
    });

    this.intro.onexit(()=> this._disableIntroInstance())
  }

  _enableIntroInstance() {
    this.intro.setOption("isActive", true);
  }

  _disableIntroInstance() {
    this.intro.setOption("isActive", false);

    document.body.classList.remove('active-tour')
  }
}