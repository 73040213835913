import './floodlight';
import log from '../tools/log';
import init from '../init';

shouldTrack = ->
  $(document.body).hasClass('track-analytics')

debug = (things...) ->
  log(things...)

injectTrackingPixel = (url, service) ->
  service ||= "Pixel"
  if shouldTrack()
    pixel = new Image()
    pixel.src = url
  else
    debug("#{service}:", url)

trackFloodlight = (type, cat) ->
  axel = Math.random() + ""
  a = axel * 10000000000000000
  url = "https://ad.doubleclick.net/ddm/activity/src=6487049;type=#{type};cat=#{cat};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1;num=#{a}?"
  injectTrackingPixel(url, "Floodlight")

init.once ->
  $(document).on 'submit', 'form#new_lead', ->
    trackFloodlight('fireo0', 'reque0')
    true

  $(document).on 'submit', 'form#new-user', ->
    trackFloodlight('fireo0', 'apply0')
    true

  $(document).on 'submit', 'form#contact_form', ->
    trackFloodlight('fireo0', '3rdho0')
    true
