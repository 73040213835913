<template lang='pug'>
span(v-click-outside="clickAway")
  .text-input
    input.word-search(
      type="text" :placeholder="placeholder"
      v-model="rawValue" ref="input"
      @keyup.down="advance" @keyup.up="retreat"
      @keydown.down.prevent="" @keydown.up.prevent=""
      @focus="focus" @keydown="focus" @keydown.enter.prevent="submit"
      @keyup.esc="escape"
      autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false"
    )
  .suggestions.search-suggestions
    ul.options(v-if="active")
      li(v-if="loading")
        .loader-container
          .loader
          .loader-text
              span L
              span o
              span a
              span d
              span i
              span n
              span g

      search-box-choice(
        v-for="choice in results"
        :key="choice.type + choice.description"
        :result='choice' @selected="selected"
        :class="{active: choice === choiceInFocus}"
      )
      li.google-places-logo(v-if="showGoogleLogo")
</template>

<script>
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import SearchBoxChoice from './SearchBoxChoice.vue';

export default {
  props: {
    defaultPlaceHolder: {required: false},
    placeholder: {required: false},
    results: {type: Array, required: true}
  },
  components: { SearchBoxChoice },
  data () {
    return {
      choiceInFocus: null,
      rawValue: "",
      showGoogleLogo: false,
      active: false,
      loading: false
    }
  },
  watch: {
    value () {
      this.debouncedQueryValue();
    },
    results () {
      this.choiceInFocus = this.results[0];
      if (this.results.length) this.loading = false;
    },
    active () {
      this.$emit('active', this.active);
    }
  },
  created () {
    this.debouncedQueryValue = debounce(this.queryValue, 500);
  },
  computed: {
    value () {
      return this.rawValue.replace(/\s+/g, ' ').trim();
    }
  },
  methods: {
    focus () {
      this.active = true
    },
    queryValue () {
      if (this.value.length) this.loading = true;
      this.$emit('query', this.value);
    },
    selected (data) {
      this.$refs.input.blur();
      this.clearSearch();
      this.$emit('selected', data);

      // Advance tour if running
      const event = new Event("proceed-introjs-tour", {bubbles: true})
      this.$el.dispatchEvent(event)
    },
    clickAway () {
      if (this.active) {
        this.clearSearch();
        this.$refs.input.blur();
      }
    },
    clearSearch () {
      this.active = false;
      this.rawValue = "";
      this.showGoogleLogo = false;
      this.choiceInFocus = null;
    },
    escape () {
      this.clearSearch();
      this.active = true;
    },
    submit () {
      if (isEmpty(this.choiceInFocus)) return;
      this.selected(this.choiceInFocus);
    },
    advance () {
      const index = this.results.indexOf(this.choiceInFocus) + 1;
      this.choiceInFocus = this.results[index];
    },
    retreat () {
      const index = this.results.indexOf(this.choiceInFocus) - 1;
      this.choiceInFocus = this.results[index > 0 ? index : 0];
    },
  }
}
</script>
