import manager from '../init/event_manager';
import * as Turbo from "@hotwired/turbo"

class Navigator {
  constructor() {
    this.adminPathRegex = new RegExp("^https?://[^/]+/admin");
    this.useTurboNavigation = false;
    this.browseHomesDelegate = null;
  }
  useTurbo() {
    this.useTurboNavigation = true;
  }
  clickEvent(e) {
    const url = e.detail.url;
    if (!this.useTurboNavigation || this._adminUrl(url)) {
      e.preventDefault();
    }
  }
  popstateEvent(e) {
    const state = history.state;

    if (state && state.browseHomes) {
      if (this.browseHomesDelegate) {
        this.browseHomesDelegate(e);
      } else {
        this.reload();
      }
    } else if (!this.useTurboNavigation) {
      this.reload();
    }
  }
  navigate(url, opts) {
    if (this.useTurboNavigation) {
      let opts = opts || {action: 'advance'};
      Turbo.visit(url, opts);
    } else {
      window.location = url;
    }
  }
  reload() {
    if (this.useTurboNavigation) {
      Turbo.visit(window.location, {action: 'replace'});
    } else {
      window.location.reload();
    }
  }
  connectBrowseHomesDelegate(callback) {
    this.browseHomesDelegate = callback;
  }
  disconnectBrowseHomesDelegate() {
    this.browseHomesDelegate = null;
  }
  _adminUrl(url) {
    return url.match(this.adminPathRegex);
  };
}

const instance = new Navigator();

function navigate(...args) {
  instance.navigate(...args);
}

window.addEventListener('popstate', (e) => instance.popstateEvent(e));

manager.connect({
  'turbo:click': function (e) {
    instance.clickEvent(e);
  }
});

export {navigate};
export default instance;
