import { Controller } from "@hotwired/stimulus";
import { initLazy } from "../../tools/lazy_load";
import { SmoothHorizontalScrolling } from "../../tools/safari_smooth";

export default class extends Controller {

  static get targets() {
    return ['leftButton', 'rightButton', 'row', 'image', 'counter'];
  }

  connect () {
    this._initializeButtons()
    this._initializeJSObserver()
  }

  disconnect () {
    this._disconnectJSObserver()
  }

  _initializeJSObserver() {
    let snapSlides = this.element.querySelectorAll('.lazy')
    let snapSlideTrack = this.rowTarget

    if (snapSlides.length) {
      this.lazyLoadObserver = initLazy(snapSlides,  {
        root: snapSlideTrack,
        rootMargin: '0px 75% 0px 75%',
        threshold: 0
      });

      this.activeObserver = this._initActiveImg({
        root: snapSlideTrack,
        rootMargin: '0px 0px 0px 0px',
        threshold: 1
      });
    }
  }

  _disconnectJSObserver() {
    if (this.lazyLoadObserver) {
      this.lazyLoadObserver.disconnect()
    }

    if (this.activeObserver) {
      this.activeObserver.disconnect()
    }
  }

  get hasButtons () {
    return this.hasLeftButtonTarget && this.hasRightButtonTarget;
  }

  _move (direction) {
    let position;
    const row = this.rowTarget;

    if (direction == 'right') {
      position = row.scrollLeft + row.querySelector('img').clientWidth;
    } else {
      position = row.scrollLeft - row.querySelector('img').clientWidth;
    }

    if (position < 0) {
      position = 0;
    }
    if ( document.documentElement.classList.contains("agent-safari-desktop") ) {

      SmoothHorizontalScrolling(row, 400, position)


    } else {
      row.scrollLeft = position;
    }
    this._setButtonsActivity(position);
  }

  _setButtonsActivity (position) {
    if (!this.hasButtons) return;
    const row = this.rowTarget;
    if (position === undefined) position = row.scrollLeft;

    const hasNext = (position + row.querySelector('img').clientWidth ) < row.scrollWidth;
    const hasPrev = position > 0;

    this.leftButtonTarget.classList.toggle('active', hasPrev);
    this.rightButtonTarget.classList.toggle('active', hasNext);
  }

  _initializeButtons() {
    if (!this.hasButtons) return;
    const row = this.rowTarget;
    if (row.clientWidth == row.scrollWidth) {
      setTimeout(() => this._initializeButtons(), 100);
    } else {
      this._setButtonsActivity();
    }
  }

  moveNext (e) {
    e.preventDefault();
    this._move('right');
  }

  movePrev (e) {
    e.preventDefault();
    this._move('left');
  }


  clickedImg (e) {
    this.dispatch('clicked-image', {target: e.target, detail: {selector: e.target.dataset.id}})
  }

  // Slider Count
  _initActiveImg (options) {
    let snapImages = this.imageTargets

    if (window.IntersectionObserver) {
      let observer = new IntersectionObserver(this._callbackActive, options);
      snapImages.forEach((element) => {
        observer.observe(element)
      })
      return observer
    } else {
      console.log("IntersectionObserver not supported.")
      this._cannotActive()
    }
  }

  _cannotActive () {
    this.counterTarget.remove()
  }

  _callbackActive = (entries, observer) => {
    entries.forEach(entry => {
      let image = entry.target
      let count = this.counterTarget
      if (entry.isIntersecting) {
        count.textContent = image.dataset.index
        image.classList.add('active')
      } else {
        image.classList.remove('active')
      }
    })
  }

}