import Week from './week';

class Schedule
  constructor: (data) ->
    {@wday, @name, @calendar, @property_count} = data
    @label = ko.computed =>
      "#{@name} (#{@property_count} Properties)"
  newWeeks: ->
    for week in @calendar
      new Week(week)

export default Schedule;