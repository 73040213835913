import ScriptLoader from '../tools/script-loader';

const mapsPublicKey = process.env.GOOGLE_MAPS_API_KEY;
const loader = new ScriptLoader(
  `https://maps.googleapis.com/maps/api/js?libraries=places&key=${mapsPublicKey}`,
  function () {
    return window.google;
  }
);

export default loader;
