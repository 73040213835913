import { reflect } from '../lib/reflect';
import timeout from '../tools/promise_timeout';

function flatten (arrays) {
  if (arrays.flat) {
    return arrays.flat();
  } else {
    return Array.prototype.concat.apply([], arrays);
  }
}

class AutocompleteSearch {
  constructor(sources) {
    this.sources = sources;
  }

  search(value, callback) {
    const promises = this.sources
      .map(source => source.search(value))
      .map(promise => timeout(5000, promise));
    Promise.all(promises.map(reflect)).then((resultsCollection) => {
      const successResults = resultsCollection.filter(r => r.resolved).map(r => r.payload);
      const results = flatten(successResults).filter(i => i);
      return callback(null, results);
    }).catch(callback);
  }
}

export default AutocompleteSearch;
