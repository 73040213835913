// Based on ./locator_controller.js, with property-application-specific functions cut
import { Controller } from "@hotwired/stimulus"
import mapboxLoader from "../../maps/mapbox"

export default class extends Controller {
  static targets = ["map", "lat", "lng", 'zoom']
  static values = {
    lng: String,
    lat: String,
    zoom: Number,
  }
  connect() {
    this.initMap()
  }
  initMap() {
    mapboxLoader.then((mapboxgl) => {
      const markerLngLat = this.extractLngLat()
      const startingCenter = markerLngLat || [0,0]
      const startingZoom = this.zoomValue || 1
      this.mapboxgl = mapboxgl
      this.map = new mapboxgl.Map({
        container: this.mapTarget,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: startingCenter,
        zoom: startingZoom,
      });
      this.map.addControl(new mapboxgl.NavigationControl())
      this.map.scrollZoom.disable()
      this.map.dragRotate.disable();
      this.map.touchZoomRotate.disableRotation();
      this.map.on('zoomend', () => this.updateZoom())
      if (markerLngLat) this.placeMarker(markerLngLat)
    })
  }
  placeMarker (lnglat) {
    this.map.resize()
    this.marker = new this.mapboxgl.Marker({
      draggable: true
    }).setLngLat(lnglat).addTo(this.map);
    this.marker.on('dragend', () => this.updatedMarker())
    this.map.flyTo({center: lnglat, zoom: this.zoomValue})
  }
  updatedMarker () {
    const marker = this.marker
    if (!marker) { return }
    const lnglat = marker.getLngLat()
    this.lngTarget.value = lnglat.lng
    this.latTarget.value = lnglat.lat
  }
  updateZoom() {
    this.zoomTarget.value = this.map.getZoom();
  }
  extractLngLat () {
    const lng = this.lngValue
    const lat = this.latValue
    if (lng && lat) {
      return [lng, lat]
    }
  }
  reset(e){
    e.preventDefault();
    let resetLocationUrl = `${e.target.dataset.url}?reset_location=true`

    fetch(resetLocationUrl, {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    })
    .then(response => {
      if(!response.ok) {
        throw new Error('network is not ok');
      }
      return response.json();
    })
    .then(data => {
      if (data.no_lat_lng) {
        this.initMap();
      } else {
        this.latValue = data.lat;
        this.lngValue = data.lng;
        this.latTarget.value = data.lat;
        this.lngTarget.value = data.lng;
        this.initMap();
      }
    })
    .catch(error => {
      console.error('something went wrong:', error);
    });
  }
}
