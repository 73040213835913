var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cell twelve medium-eight medium-order-2" }, [
    _c(
      "div",
      {
        staticClass: "expanding-section closed",
        attrs: {
          "data-controller": "expand-collapse",
          "data-expand-collapse-target": "container"
        }
      },
      [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "grid edge expanding-content",
            attrs: { "data-expand-collapse-target": "expand" }
          },
          [
            _c("div", { staticClass: "cell twelve medium-six" }, [
              _c("div", { staticClass: "input check_boxes small-no-margin" }, [
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.arts,
                        expression: "arts"
                      }
                    ],
                    attrs: { type: "checkbox", id: "arts" },
                    domProps: {
                      checked: Array.isArray(_vm.arts)
                        ? _vm._i(_vm.arts, null) > -1
                        : _vm.arts
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.arts,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.arts = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.arts = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.arts = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "arts" } }, [_vm._v("Arts")])
                ]),
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.beach,
                        expression: "beach"
                      }
                    ],
                    attrs: { type: "checkbox", id: "beach" },
                    domProps: {
                      checked: Array.isArray(_vm.beach)
                        ? _vm._i(_vm.beach, null) > -1
                        : _vm.beach
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.beach,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.beach = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.beach = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.beach = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "beach" } }, [_vm._v("Beach")])
                ]),
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.equestrian,
                        expression: "equestrian"
                      }
                    ],
                    attrs: { type: "checkbox", id: "equine" },
                    domProps: {
                      checked: Array.isArray(_vm.equestrian)
                        ? _vm._i(_vm.equestrian, null) > -1
                        : _vm.equestrian
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.equestrian,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.equestrian = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.equestrian = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.equestrian = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "equine" } }, [_vm._v("Equine")])
                ]),
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fishing,
                        expression: "fishing"
                      }
                    ],
                    attrs: { type: "checkbox", id: "fishing" },
                    domProps: {
                      checked: Array.isArray(_vm.fishing)
                        ? _vm._i(_vm.fishing, null) > -1
                        : _vm.fishing
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.fishing,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.fishing = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.fishing = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.fishing = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "fishing" } }, [
                    _vm._v("Fishing")
                  ])
                ]),
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.golf,
                        expression: "golf"
                      }
                    ],
                    attrs: { type: "checkbox", id: "golfing" },
                    domProps: {
                      checked: Array.isArray(_vm.golf)
                        ? _vm._i(_vm.golf, null) > -1
                        : _vm.golf
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.golf,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.golf = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.golf = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.golf = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "golfing" } }, [
                    _vm._v("Golfing")
                  ])
                ]),
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.hiking,
                        expression: "hiking"
                      }
                    ],
                    attrs: { type: "checkbox", id: "hiking" },
                    domProps: {
                      checked: Array.isArray(_vm.hiking)
                        ? _vm._i(_vm.hiking, null) > -1
                        : _vm.hiking
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.hiking,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.hiking = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.hiking = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.hiking = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "hiking" } }, [_vm._v("Hiking")])
                ])
              ])
            ]),
            _c("div", { staticClass: "cell twelve medium-six" }, [
              _c("div", { staticClass: "input check_boxes" }, [
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pool,
                        expression: "pool"
                      }
                    ],
                    attrs: { type: "checkbox", id: "pool" },
                    domProps: {
                      checked: Array.isArray(_vm.pool)
                        ? _vm._i(_vm.pool, null) > -1
                        : _vm.pool
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.pool,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.pool = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.pool = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.pool = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "pool" } }, [_vm._v("Pool")])
                ]),
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.tennis,
                        expression: "tennis"
                      }
                    ],
                    attrs: { type: "checkbox", id: "tennis" },
                    domProps: {
                      checked: Array.isArray(_vm.tennis)
                        ? _vm._i(_vm.tennis, null) > -1
                        : _vm.tennis
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.tennis,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.tennis = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.tennis = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.tennis = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "tennis" } }, [_vm._v("Tennis")])
                ]),
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shopping,
                        expression: "shopping"
                      }
                    ],
                    attrs: { type: "checkbox", id: "shopping" },
                    domProps: {
                      checked: Array.isArray(_vm.shopping)
                        ? _vm._i(_vm.shopping, null) > -1
                        : _vm.shopping
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.shopping,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.shopping = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.shopping = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.shopping = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "shopping" } }, [
                    _vm._v("Shopping")
                  ])
                ]),
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.skiing,
                        expression: "skiing"
                      }
                    ],
                    attrs: { type: "checkbox", id: "skiing" },
                    domProps: {
                      checked: Array.isArray(_vm.skiing)
                        ? _vm._i(_vm.skiing, null) > -1
                        : _vm.skiing
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.skiing,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.skiing = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.skiing = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.skiing = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "skiing" } }, [_vm._v("Skiing")])
                ]),
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.wine_tasting,
                        expression: "wine_tasting"
                      }
                    ],
                    attrs: { type: "checkbox", id: "winery" },
                    domProps: {
                      checked: Array.isArray(_vm.wine_tasting)
                        ? _vm._i(_vm.wine_tasting, null) > -1
                        : _vm.wine_tasting
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.wine_tasting,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.wine_tasting = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.wine_tasting = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.wine_tasting = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: "winery" } }, [_vm._v("Winery")])
                ])
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h5",
      {
        staticClass: "no-bottom-margin expand-title",
        attrs: {
          "data-action": "click->expand-collapse#toggleOpen",
          "data-expand-collapse-target": "more"
        }
      },
      [
        _c("span", { staticClass: "show-text" }, [_vm._v("Show ")]),
        _c("span", { staticClass: "hide-text" }, [_vm._v("Hide")]),
        _vm._v(" Activities  "),
        _c("span", { staticClass: "down-arrow" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }