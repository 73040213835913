import log from '../tools/log';
import system  from './system';
import PageLoad from './page_load';

const methods = {
  once(initializer) {
    system.once(initializer);
  },
  onLoad(initializer) {
    system.onLoad(initializer);
  },
  onUnload(initializer) {
    system.onUnload(initializer);
  },
  pageLoad(...args) {
    return (new PageLoad(...args));
  }
};


export default methods;
