var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-template-wrapper" },
    [
      _c("Deposit", {
        attrs: {
          deposit: _vm.parent,
          checked: _vm.depositSelected(_vm.parent)
        },
        on: { checkboxToggle: _vm.handleParentCheckboxToggle }
      }),
      _vm.parent.children
        ? _c(
            "div",
            { staticClass: "children" },
            _vm._l(_vm.parent.children, function(deposit) {
              return _c("Deposit", {
                key: deposit.id,
                attrs: {
                  deposit: deposit,
                  checked: _vm.depositSelected(deposit)
                },
                on: { checkboxToggle: _vm.handleChildCheckboxToggle }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }