import log from '../tools/log'

function send(message) {
  window.webkit?.messageHandlers?.nativeApp?.postMessage(JSON.stringify(message))
}

let receivers = []

function receive(messageName, callback) {
  receivers.push({
    message: messageName,
    callback: callback
  })
}

function notifyReceivers(message) {
  let messageName = message.message
  if (!messageName) return

  receivers
    .filter((receiver) => receiver.message == messageName)
    .map((receiver) => receiver.callback)
    .forEach((receiverFunc) => receiverFunc(message))
}

function appBridge (json) {
  var message
  try {
    message = JSON.parse(json)
  } catch (e) {
    // parsing error
    log("Error parsing nativeBridge message:", json, e)
    return
  }
  notifyReceivers(message)
}

window.appBridge = appBridge

export { send, receive }
