function log() {
  let console = window.console;
  if (console && console.log) {
    return console.log.apply(
      console,
      Array.prototype.slice.call(arguments)
    );
  }
}
export default log;
