<template lang="pug">
.cell.twelve.medium-eight.medium-order-2
  .expanding-section.closed(data-controller="expand-collapse" data-expand-collapse-target="container")
    h5.no-bottom-margin.expand-title(data-action="click->expand-collapse#toggleOpen" data-expand-collapse-target="more") 
      span.show-text Show 
      span.hide-text Hide
      |  Activities  
      span.down-arrow
    .grid.edge.expanding-content(data-expand-collapse-target="expand")
      .cell.twelve.medium-six
        .input.check_boxes.small-no-margin
          span.checkbox
            input(type="checkbox" v-model="arts" id="arts")
            label(for="arts") Arts
          span.checkbox
            input(type="checkbox" v-model="beach" id="beach")
            label(for="beach") Beach
          span.checkbox
            input(type="checkbox" v-model="equestrian" id="equine")
            label(for="equine") Equine
          span.checkbox
            input(type="checkbox" v-model="fishing" id="fishing")
            label(for="fishing") Fishing
          span.checkbox
            input(type="checkbox" v-model="golf" id="golfing")
            label(for="golfing") Golfing
          span.checkbox
            input(type="checkbox" v-model="hiking" id="hiking")
            label(for="hiking") Hiking
      .cell.twelve.medium-six
        .input.check_boxes
          span.checkbox
            input(type="checkbox" v-model="pool" id="pool")
            label(for="pool") Pool
          span.checkbox
            input(type="checkbox" v-model="tennis" id="tennis")
            label(for="tennis") Tennis
          span.checkbox
            input(type="checkbox" v-model="shopping" id="shopping")
            label(for="shopping") Shopping
          span.checkbox
            input(type="checkbox" v-model="skiing" id="skiing")
            label(for="skiing") Skiing
          span.checkbox
            input(type="checkbox" v-model="wine_tasting" id="winery")
            label(for="winery") Winery
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  computed: {
    ...sync('browseHomes', {
      arts: 'filters@arts',
      beach: 'filters@beach',
      equestrian: 'filters@equestrian',
      fishing: 'filters@fishing',
      golf: 'filters@golf',
      hiking: 'filters@hiking',
      pool: 'filters@pool',
      tennis: 'filters@tennis',
      shopping: 'filters@shopping',
      skiing: 'filters@skiing',
      watersports: 'filters@watersports',
      wine_tasting: 'filters@wine_tasting'
    })
  }
}
</script>
