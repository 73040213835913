import { make } from 'vuex-pathify'
import omit from 'lodash/omit';
import clone from 'lodash/clone'
import debounce from 'lodash/debounce'

import { send as sendAppMessage } from '../../app/bridge'
import { trackEvent, trackPageNavGA } from '../../analytics';

import { defaultFilters, filterNames, filterCopy } from './defaults.js'

const fetchOptions = {
  headers: {
    Accept: 'application/json'
  },
  credentials: 'same-origin'
}

const state = {
  loggedIn: false,
  canViewAvailability: null,

  filters: clone(defaultFilters),
  filterHumanCopy: clone(filterCopy),
  sort_type: 'featured',
  properties_with_availability: null,
  promo_slug: null,
  seo_json_data: null,
  map_all_properties: false,
  page: 1,

  filterModalOpen: false,
  tentativeSearchId: null,
  tentativeSearch: null,

  tentativeResultsCount: null,
  destination: null,
  savedSearches: [],
  mapHash: null,

  loading: false,
  results: null,
  resultsCount: null,
  lastSearch: null,
  hasFallbackMap: false
}
const getters = {
  ...make.getters(state),
  filtersActive ({ filters, promo_slug }) {
    const active = {}
    if (promo_slug !== filters['promo']){
      filters['promo'] = promo_slug
    }
    for (const filterName in filters) {
      const filterValue = filters[filterName]
      if (filterValue === true ||
          (filterValue && filterValue !== defaultFilters[filterName])) {
        active[filterName] = filters[filterName]
      }
    }

    return active
  },

  filterCount ({ filters }, { filtersActive }) {
    const removeFilters = ['search_type', 'end_date']
    return Object.keys(omit(filtersActive, removeFilters)).length
  },

  desktopFilters ({ filters }, { filtersActive }) {
    const removeFilters = [
      'search_type', 'destination_id', 'word_search',
      'start_date', 'end_date', 'min_beds'
    ]
    return Object.keys(omit(filtersActive, removeFilters))
  },

  desktopFilterCount ({ filters }, { desktopFilters }) {
    return desktopFilters.length
  },

  currentSearchId ({ results, filterModalOpen, tentativeSearchId }) {
    if (filterModalOpen && tentativeSearchId) {
      return tentativeSearchId
    } else {
      return results?.search?.id
    }
  },
  currentSearchIsSaved ({ savedSearches }, { currentSearchId }) {
    return savedSearches.some(saved => saved.id === currentSearchId)
  },

  mapLocation ({ mapHash }) {
    if (mapHash === null) {
      return null
    } else {
      const match = mapHash.match(/#?(-?[\.\d]+\/-?[\.\d]+\/-?[\.\d]+)/)
      return match && match[1]
    }
  },

  stayDurationSelection ({ filters: { start_date, end_date } }) {
    if (start_date.length && end_date.length) {
      return { start_date, end_date }
    } else {
      return null
    }
  },

  destinationSelected ({ destination, filters: { word_search }}) {
    return destination !== null || word_search.trim().length > 0
  },
  destinationSelectionText ({ destination, filters: { word_search }}) {
    if (destination !== null) {
      return 'Destination'
    } else if (word_search.trim()) {
      return 'Keyword'
    } else {
      return 'Destination / Keyword'
    }
  },

  makeFullUrl (state, getters) {
    // Since this is cached, and actions may modify a returned reference's
    // searchParams, we return a function so that a new URL object is
    // created every time.
    return () => {
      let basePath = '/properties'
      if (state.destination !== null) {
        basePath = state.destination.path
      }
      const url = new URL(basePath, window.location.origin)

      Object.keys(omit(getters.filtersActive, 'destination_id')).forEach(filterName => {
        url.searchParams.append(filterName, getters.filtersActive[filterName])
      })

      // These are appended to the URL but do NOT come back as filters listed in
      // the search responce, so we must handle them outside state.filters.
      const {
        filters: { search_type },
        sort_type,
        mapHash,
        properties_with_availability,
        map_all_properties
      } = state

      if (sort_type !== 'featured' && sort_type !== 'chron') {
        url.searchParams.append('sort_type', sort_type)
      }
      if (search_type !== 'availability' && properties_with_availability !== null) {
        url.searchParams.append(
          'properties_with_availability',
          properties_with_availability
        )
      }
      if (mapHash && search_type === 'map') {
        url.hash = mapHash
      }

      return url
    }
  }
}

const actions = {
  ...make.actions(state),
  clearFilters ({ commit }, options) {
    commit('CLEAR_ALL_FILTERS', options || { skipDesktop: false })
  },

  loadFromSearchResponse ({ commit, dispatch, state }, searchResponse) {

    const {
      search,
      search_type,
      sort_type,
      page,
      properties_with_availability,
      can_view_availability: canViewAvailability,
      count: resultsCount,
      seo_json_data,
      has_fallback_map
    } = searchResponse

    const filters = {}
    let changed = false
    for (let filter of filterNames) {
      if (search[filter] !== undefined) {
        filters[filter] = search[filter]
      }
    }
    filters.search_type = search_type

    commit('UPDATE_FROM_SEARCH_RESPONSE', {
      lastSearch: searchResponse,
      filters,
      canViewAvailability,
      properties_with_availability,
      resultsCount: search_type === 'map' ? null : resultsCount,
      hasFallbackMap: has_fallback_map,
      tentativeResultsCount: resultsCount,
      tentativeSearchId: search.id,
      mapHash: null,
      sort_type,
      page,
      results: searchResponse,
      seo_json_data
    })

    if (search.destination_id !== null) {
      commit('SET_DESTINATION', {
        ...search.destination,
        id: search.destination_id
      })
    } else {
      commit('SET_DESTINATION', null)
    }
  },

  updateFilters({ commit, dispatch, state }, { filters, skipFetch }) {
    if (skipFetch !== undefined) {
      commit('UPDATE_FILTERS', filters)
      if (skipFetch === false) {
        dispatch('fetch')
      }
    } else {
      // If skipFetch is not specified, fetch only if something changed.
      const changed = filterNames.some(name => {
        return filters[name] !== undefined &&
               filters[name] !== state.filters[name]
      })
      if (changed) {
        commit('UPDATE_FILTERS', filters)
        dispatch('fetch')
      }
    }
  },

  resetFiltersFromLastSearch ({ state: { lastSearch }, commit, dispatch }) {
    commit('SET_SORT_TYPE', lastSearch.sort_type)
    dispatch('loadFromSearchResponse', lastSearch)
  },

  // Since there are fields in multiple places, and some fields need to
  // either a) Fetch results ASAP (desktop location, beds, dates, etc.)
  //     or b) Fetch only a preliminary count (filter modal open),
  // we define a fetch method that makes the appropriate fetch when called.
  fetch: debounce(async ({ state, getters, dispatch }, url) => {
    if (state.filterModalOpen) {
      dispatch('fetchResultsCountOnly')
    } else {
      dispatch('fetchProperties', { url: url || getters.makeFullUrl() })
    }
  }, 1000),

  async fetchProperties (
    { state, getters, commit, dispatch },
    options = { url: null, updateHistory: true }
  ) {
    const lastPage = state.lastSearch.page
    const url = options.url || getters.makeFullUrl()
    const { updateHistory } = options

    try {
      commit('SET_LOADING', true)
      const data = await fetch(url, fetchOptions
      ).then(response => response.json())
      await dispatch('loadFromSearchResponse', data)
      Turbo.renderStreamMessage(data.search_intro)

      const usePush = data.page !== lastPage
      await dispatch('updateUrl', { url, updateHistory, usePush })

      commit('SET_LOADING', false)

    } catch (e) {
      console.log("Couldn't fetch search results: ", e)
    }
  },

  async fetchResultsCountOnly ({ dispatch, state, getters }) {
    const url = getters.makeFullUrl()
    url.searchParams.append('count_only', true)
    return await fetch(url, fetchOptions
    ).then(response => response.json()
    ).then(data => {
      dispatch('setCanViewAvailability', data.can_view_availability)
      dispatch('setTentativeResultsCount', data.count)
      dispatch('setTentativeSearchId', data.search_id)
      dispatch('setTentativeSearch', data.search)
    })
  },

  async updateSearchLocation ({ commit, dispatch }, location) {
    const { type: locationType, data, path } = location
    if (locationType === 'place' && data.place_id) {
      try {
        const response =
          await fetch(`/destinations/lookup?place_id=${data.place_id}`)
            .then(response => response.json())

        commit('SET_DESTINATION', response)
        commit('UPDATE_FILTERS', { word_search: "" })
        dispatch('fetchProperties')

      } catch (e) {
        console.log('Failed to fetch place: ', e)
      }
    } else if (locationType === 'keyword') {
      commit('SET_DESTINATION', null)
      commit('UPDATE_FILTERS', {
        word_search: location.data.keyword
      })
      dispatch('fetchProperties')

    } else if (locationType === 'zone') {
      commit('UPDATE_FILTERS', { word_search: "" })
      commit('SET_DESTINATION', {
        name: location.description,
        path: path
      })
      dispatch('fetchProperties')
    }
  },

  updateUrl ({ state }, { url, updateHistory = true, usePush = false }) {
    const urlObject = url instanceof URL ? url : new URL(url)
    // Although we send properties_with_availability=false when we fetch,
    // it shouldn't show up in the browser's URL bar.
    urlObject.searchParams.delete('properties_with_availability')

    if (updateHistory) {
      if (usePush) {
        window.history.pushState(
          { browseHomes: true,
            url: urlObject.toString(),
          },
          document.title,
          url
        )
      } else {
        window.history.replaceState(
          { browseHomes: true,
            url: urlObject.toString(),
            replacedState: history.state?.replacedState || history.state
          },
          document.title,
          url
        )
      }
    }
    sendAppMessage({ message: 'changed-url' })
    trackPageNavGA(urlObject.pathname + urlObject.search)
    trackEvent('Properties', 'Browse')
  },

  updateMapHash ({ commit, dispatch, getters, state }, mapHash) {
    commit('SET_MAP_HASH', mapHash || null)
    dispatch('updateUrl', { url: getters.makeFullUrl() })
  },

  updateSearchType({ commit, dispatch }, search_type) {
    commit('UPDATE_SEARCH_TYPE', search_type)
  },

  clearDestination ({ commit, dispatch }) {
    commit('SET_DESTINATION', null)
    commit('UPDATE_FILTERS', { destination_id: null })
    dispatch('fetch')
  },

  async toggleSaveSearch ({ commit, state, getters }) {
    // On mobile, saved search button is on modal. We want to save the search they have configured - else
    // they would have to close the modal, let the results load, and then re-open the modal to save search
   let search = state.filterModalOpen ? state.tentativeSearch?.search : state.results?.search
    search ||= state.results?.search

    if (search) {
      const searchId = search.id
      const subscribe = !getters.currentSearchIsSaved
      try {
        const response =
          await fetch(`/searches/${searchId}/update_subscription`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify({ subscribe })
          })
            .then(response => response.json())

        if (subscribe) {
          commit('ADD_SAVED_SEARCH', search)
        } else {
          commit('REMOVE_SAVED_SEARCH', search)
        }
      } catch (e) {
        console.log('Could not save search: ', e)
      }
    }
  }
}

/* eslint-disable no-useless-computed-key */
const mutations = {
  ...make.mutations(state),
  ['CLEAR_ALL_FILTERS'] (state, { skipDesktop = false }) {
    // Reset all filters to their default values. If skipDesktop is
    // specified, skip destination, start/end dates, min_beds.
    const {
      search_type,
      destination_id,
      start_date,
      end_date,
      min_beds
    } = state.filters
    if (skipDesktop) {
      state.filters = {
        ...clone(defaultFilters),
        destination_id,
        start_date,
        end_date,
        min_beds,
        search_type
      }
      state.promo_slug = null
    } else {
      state.filters = {
        ...clone(defaultFilters),
        search_type
      }
      state.destination = null
      state.promo_slug = null
    }
  },
  ['UPDATE_FILTERS'] (state, newFilters) {
    state.filters = {
      ...state.filters,
      ...newFilters
    }
  },
  ['SET_ALL_FILTERS'] (state, filters) {
    state.filters = Object.assign({}, defaultFilters, filters)
  },
  ['UPDATE_FROM_SEARCH_RESPONSE'] (state, data) {
    Object.assign(state, {
      ...data,
      canViewAvailability: data.canViewAvailability || state.canViewAvailability
    })
    // Sort type concerns:
    // 1) Map search results need to be cleaned up so they don't add
    // to the URL query string.
    // 2) When calling resetFiltersFromLastSearch(), make sure that
    // sort_types are handled correctly when switching between
    // properties/availability: prevent prop & chron, avail. & featured.
    if (data.sort_type === undefined) {
      state.sort_type = 'featured'
    }

  },
  ['SET_TENTATIVE_RESULTS_COUNT'] (state, count) {
    state.tentativeResultsCount = count
  },
  ['ADD_SAVED_SEARCH'] (state, search) {
    state.savedSearches = [...state.savedSearches, search]
  },
  ['REMOVE_SAVED_SEARCH'] (state, search) {
    state.savedSearches = state.savedSearches.filter(saved => saved.id !== search.id)
  },
  // Update the search type, and if it's availability, disable the
  // open_availability filter before fetching anything.
  ['UPDATE_SEARCH_TYPE'] (state, search_type) {
    state.tentativeResultsCount = null
    const open_availability = state.filters.open_availability
    state.filters = {
      ...state.filters,
      search_type,
      open_availability: search_type === 'availability' ? false : open_availability
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
