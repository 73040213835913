import Vue from 'vue';
import VueResource from 'vue-resource';
import init from '../init';
import FlexibleDepositsSelection from './FlexibleDepositsSelection.vue';

init.pageLoad('flexible-deposit-page', {
  load () {
    Vue.use(VueResource);
    /* eslint-disable no-new */
    new Vue({
      el: document.getElementById('flexible-deposits-container'),
      components: { FlexibleDepositsSelection },
    })
  }
});
