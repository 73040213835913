import { Controller } from "@hotwired/stimulus"
import mapboxLoader from "../../maps/mapbox"

const selectedZoom = 17

export default class extends Controller {
  static targets = ["map", "address", "street2"]
  static values = {
    filled: Boolean,
    street1: String,
    street2: String,
    city: String,
    state: String,
    country: String,
    postal: String,
    lng: String,
    lat: String,
    hidden: Boolean,
    requireMap: Boolean
  }
  connect() {
    this.initMap()
    if (!this.requireMapValue) {
      this.mapTarget.style.display = "none";
      this.addressTarget.innerText = this.formatAddress(this.extractAddress())
    } else if (this.filledValue) {
      this.addressTarget.innerText = this.formatAddress(this.extractAddress())
    } else if (this.hiddenValue) {
      this.mapTarget.style.visibility = "hidden";
      this.mapTarget.classList.remove("showmap");
    }
  }
  madeSelection(e) {
    const selection = e.detail.selection
    this.loadSelection(selection)
  }
  initMap() {
    mapboxLoader.then((mapboxgl) => {
      const markerLngLat = this.extractLngLat()
      const startingCenter = markerLngLat || [0,0]
      const startingZoom = markerLngLat ? selectedZoom : 1
      this.mapboxgl = mapboxgl
      this.map = new mapboxgl.Map({
        container: this.mapTarget,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: startingCenter,
        zoom: startingZoom,
      });
      this.map.addControl(new mapboxgl.NavigationControl())
      this.map.scrollZoom.disable()
      this.map.dragRotate.disable();
      this.map.touchZoomRotate.disableRotation();
      if (markerLngLat) this.placeMarker(markerLngLat)
    })
  }
  loadSelection (selection) {
    this.closeQuery()
    this.filledValue = true
    this.addressTarget.innerText = this.formatAddress(selection)
    this.loadAddressFields(selection)
    this.placeMarker(selection.lnglat)
  }
  loadAddressFields (selection) {
    this.street1Value = selection.street
    this.cityValue = selection.city
    this.stateValue = selection.region
    this.countryValue = selection.countryCode?.toUpperCase()
    this.postalValue = selection.postcode
    this.lngValue = selection.lng
    this.latValue = selection.lat
    this.notifyAddressChange()
    this.notifyGeoChange()
  }
  notifyAddressChange() {
    let changes = {
      street1: this.street1Value,
      street2: this.street2Target.value,
      city: this.cityValue,
      region: this.stateValue,
      country: this.countryValue,
      postcode: this.postalValue,
      lng: this.lngValue,
      lat: this.latValue
    }
    let e = new Event('address-change', {bubbles: true})
    e.address = changes
    this.element.dispatchEvent(e)
  }
  notifyGeoChange() {
    let changes = {
      lng: this.lngValue,
      lat: this.latValue
    }
    let e = new Event('geo-change', {bubbles: true})
    e.geo = changes
    this.element.dispatchEvent(e)
  }
  placeMarker (lnglat) {
    this.map.resize()
    this.clearMarker()

    const marker = new this.mapboxgl.Marker({
      draggable: true
    }).setLngLat(lnglat).addTo(this.map);
    this.marker = marker

    marker.on('dragend', () => this.updatedMarker())

    this.map.flyTo({center: lnglat, zoom: selectedZoom})
  }
  clearMarker () {
    if (this.marker) {
      this.marker.remove()
    }
  }
  updatedMarker () {
    const marker = this.marker
    if (!marker) { return }
    const lnglat = marker.getLngLat()
    this.lngValue = lnglat.lng
    this.latValue = lnglat.lat
    this.notifyGeoChange()
  }
  openQuery () {
    this.element.classList.add('querying')
  }
  closeQuery () {
    this.element.classList.remove('querying')
  }
  formatAddress (address) {
    const {street, city, region, country, postcode} = address
    let str = [street, city, region, country].filter(e => e?.trim()).join(", ")
    return [str, postcode].filter(e => e?.trim()).join(" ")
  }
  extractAddress () {
    return {
      street: this.street1Value,
      city: this.cityValue,
      region: this.stateValue,
      country: this.countryValue,
      postcode: this.postalValue,
    }
  }
  extractLngLat () {
    const lng = this.lngValue
    const lat = this.latValue
    if (lng && lat) {
      return [lng, lat]
    }
  }
  filledValueChanged(value){
    if(value) { 
      this.mapTarget.style.visibility = null; 
      this.mapTarget.classList.add("showmap")
    }
  }
}
