import init from '../init';

init.pageLoad('new-backup-reservation', {
  load () {
    const infoModal = document.getElementById('backup-reservation-info')
    infoModal.classList.add('show-modal');

    infoModal.addEventListener('click', function() {
      infoModal.classList.remove('show-modal');
    })
  }
})