class PropertiesSource {
  constructor(propertyUrl) {
    this.propertyUrl = propertyUrl;
  }
  search(value) {
    return new Promise(async (resolve, reject) => {
      if (!/^\d+$/.test(value)) return resolve([]);
      const fetchUrl = `${this.propertyUrl}?id=${value}`;
      const response = await fetch(fetchUrl);
      if (!response.ok) { return reject() };
      const json = await response.json();
      if (!json.exists) return resolve([]);
      const prop = json.property;
      resolve([{
        data: {id: prop.id},
        description: prop.name,
        path: prop.path,
        type: 'property',
      }]);
    });
  }
}

export default PropertiesSource;
