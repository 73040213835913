import Posting from './posting';

class StartState
  constructor: (@form) ->
    @postings = for posting in @form.data("postings")
      new Posting(posting, @_isAccepted(posting.id))
  _isAccepted: (id) ->
    @_accepted().indexOf(id.toString()) >= 0
  _accepted: () ->
    @form.data('accepted-weeks')

export default StartState;
