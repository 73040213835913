import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["street1", "street2Input", "street2", "city", "state", "countryCode", "zip", "lat", "lng"]

  addressChange (e) {
    const {address} = e
    this.street1Target.value = address.street1
    this.cityTarget.value = address.city
    this.stateTarget.value = address.region
    this.countryCodeTarget.value = address.country
    this.zipTarget.value = address.postcode
    this.latTarget.value = address.lat
    this.lngTarget.value = address.lng
    this.street2Target.value = address.street2
  }

  geoChange (e) {
    const {geo} = e
    this.latTarget.value = geo.lat
    this.lngTarget.value = geo.lng
  }
}
