import { Controller } from "@hotwired/stimulus";
import { initLazy } from "../../tools/lazy_load";
import { openModal } from "../../core/modals"

export default class extends Controller {

  static get targets() {
    return ['modal'];
  }

  connect () {
    this._initializeLazyLoad()
  }

  disconnect () {
    this._disconnectLazyLoad()
  }

  _initializeLazyLoad() {
    let photoModal = this.modalTarget
    let photoModalImages = photoModal.querySelectorAll('img')

    if (photoModal) {
      this.modalObserver = initLazy(photoModalImages,  {
        root: photoModal.querySelector('.modal-content'),
        rootMargin: '75% 0px 75% 0px',
        threshold: 0
      });
    }
  }

  _disconnectLazyLoad() {
    if (this.modalObserver) {
      this.modalObserver.disconnect()
    }
  }

  openModal (e) {
    openModal(this.modalTarget)
  }

  openModalScroll (e) {
    const selector = e.detail.selector,
      targetImg = selector && this.element.querySelector(selector)

    if (targetImg) {
      // Scroll to target
      targetImg.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      })
    }
  }

}