var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal",
      attrs: {
        id: "confirm-deposits",
        "aria-labelledby": "confirm-deposit-submission"
      }
    },
    [
      _c(
        "form",
        {
          staticClass: "card modal-card",
          attrs: {
            action: _vm.formatConfirmUrl(),
            method: "post",
            role: "document"
          }
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: "authenticity_token" },
            domProps: { value: _vm.csrf }
          }),
          _c("input", {
            attrs: { type: "hidden", name: "rental_wday" },
            domProps: { value: _vm.rentalWday }
          }),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "modal-content" },
            [
              _c("p", { staticClass: "small" }, [
                _vm._v(
                  "Please verify and confirm the following dates are being deposited\nto ThirdHome. Once accepted and reserved, these stays belong\nto the Club and you will not be able to use them yourself or rent\nthem out through other means."
                )
              ]),
              _vm._l(_vm.selectedDeposits, function(deposit, id) {
                return _c(
                  "div",
                  { staticClass: "deposit-entry" },
                  [
                    _c("DepositConfirmation", {
                      attrs: {
                        deposit: deposit,
                        id: id,
                        requireConfirmationIds: _vm.requireConfirmationIds,
                        ownerCanNyop: _vm.ownerCanNyop
                      },
                      on: { removeDeposit: _vm.removeDeposit }
                    })
                  ],
                  1
                )
              }),
              _c(
                "div",
                {
                  staticClass: "agree-to-terms-container",
                  staticStyle: { padding: "0 4px" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "input check_boxes",
                      staticStyle: { "margin-bottom": "0" }
                    },
                    [
                      _c("span", { staticClass: "checkbox" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.checked,
                              expression: "checked"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            name: "agree_to_liability_for_reservation",
                            id: "agree_to_liability_for_reservation",
                            value: "1"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.checked)
                              ? _vm._i(_vm.checked, "1") > -1
                              : _vm.checked
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.checked,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "1",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checked = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checked = $$c
                              }
                            }
                          }
                        }),
                        _vm._m(1)
                      ])
                    ]
                  )
                ]
              )
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "card-controls",
              staticStyle: { padding: ".5rem 2rem 1rem" }
            },
            [
              _c("input", {
                staticClass: "button small",
                attrs: {
                  type: "submit",
                  value: "Confirm Weeks",
                  "data-disable-with": "Processing...",
                  disabled: _vm.confirmButtonDisabled
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { attrs: { id: "confirm-deposit-submission" } }, [
        _vm._v("Confirm deposit submission")
      ]),
      _c("div", { staticClass: "close", attrs: { "aria-label": "close" } }, [
        _c("div", { staticClass: "x" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { attrs: { for: "agree_to_liability_for_reservation" } },
      [
        _c("p", { staticClass: "small" }, [
          _vm._v("I agree to "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.thirdhome.com/terms-and-conditions/",
                target: "_blank"
              }
            },
            [_vm._v("ThirdHome's Terms and Conditions")]
          ),
          _vm._v(
            ", including that Host Members are not permitted to cancel reservations. \nAll costs incurred by the Guest due to cancelation are my responsibility as Host."
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }