import { Controller } from "@hotwired/stimulus";
import Vue from 'vue'
import OnboardingPropertyPhotos from '../../onboarding-property-photos/OnboardingPropertyPhotos.vue'

export default class extends Controller {
  static targets = ["widget"]

  connect() {
    new Vue({
      el: this.widgetTarget,
      components: { OnboardingPropertyPhotos }
    })
  }
}