import { Controller } from "@hotwired/stimulus";
import dateFormatting from '../../tools/date_formatting.js';

export default class extends Controller {
  static get targets() {
    return ["picker", "startDate", "endDate", "message", "availableCheckbox", "rate"]
  }

  connect() {
    this.setRate()
    this.initSelections()

    const pickerOptions = {
      opens: 'right',
      autoApply: true,
      autoUpdateInput: false,
      locale: {format: this.format}
    }

    if (this.hasDates) {
      pickerOptions.startDate = this.startDate;
      pickerOptions.endDate = this.endDate;
    }

    $(this.pickerTarget).daterangepicker(
      pickerOptions
    , (start, end) => {
        this.startDateTarget.value = start.format(this.format)
        this.endDateTarget.value = end.format(this.format)
        this.initSelections()
      }
    );
  }

  initSelections() {
    this.setSelectionDescription()
    this.setSelectedRange()
  }

  setSelectedRange() {
    var msg = '';
    if (this.hasDates) {
      msg = dateFormatting.formatDateRange(this.startDate, this.endDate);
    }
    this.pickerTarget.value = msg;
  }

  setSelectionDescription() {
    let msg = '';
    if (this.hasDates) {
      const checkInDate = dateFormatting.formatFullDate(this.startDate);
      const checkOutDate = dateFormatting.formatFullDate(this.endDate);
      const availabilityStatus = this.isAvailable ? 'available' : 'unavailable';
      msg = `
        Based on your selections, the property will be ${availabilityStatus} for rent from the afternoon of ${checkInDate}
        to the morning of ${checkOutDate}.
      `;
    }
    this.messageTarget.textContent = msg;
  }

  toggleAvailability() {
    this.setRate()
    this.setSelectionDescription()
  }

  setRate() {
    this.rateTarget.classList.toggle("hideElement", !this.isAvailable)
  }

  get hasDates() {
    return !!(this.startDate && this.endDate);
  }

  get format() {
    return 'YYYY-MM-DD';
  }

  get startDate() {
    return this.startDateTarget.value
  }

  get endDate() {
    return this.endDateTarget.value
  }

  get isAvailable() {
    return this.availableCheckboxTarget.checked
  }
}
