<template lang="pug">
.search-filter-area
  a.filter-toggle(@click.prevent="toggleModal(true)")
    .medium-show
      .pill.icon-pill
        .pill-icon
          .filter-icon(style="margin-right:0")
            .bar
            .bar
        span.pill-text.strong
          | Filters
          span.small(v-show="desktopFilterCount > 0")  ({{ desktopFilterCount }}) 
    .medium-hide.mobile-filter-toggle
      .div
        h3.toggle-text
          | {{ mobileFilterToggleText }}
        span.third-font-search-glyph
        .filter-icon
          .bar
          .bar
          sup.filter-count(v-if="filterCount > 0")
            span.badge.secondary {{ filterCount }}

  .search-filter-modal.modal.large-modal(ref="modal" role="dialog" aria-labelledby="Filters")
    .card.modal-card( role="document")
      .modal-content
        h4.medium-show.no-bottom-margin Filters
        h3.medium-hide.mobile-results-count(style="display:none") {{ modalHeaderText }}

        .search-bar.modal-mobile-location-search.medium-hide
          .grid.form
            .cell.twelve.destination-container.collapse
              label.destination-label(:class="{ selected: destinationSelected }")
                | {{ destinationSelectionText }}
              BrowseSearch(:zones-url="zonesUrl")
            StayDuration(v-if='canViewAvailability' parent='.search-filter-modal .card-content')
            BedroomCount

        .medium-hide.saved-searches(:v-show="loggedIn"
                                                :class="savedSearchClass")
          .menu-title(@click="toggleSavedSearch")
            h5.no-bottom-margin
              span.menu-title-text Saved Searches
          .saved-search-content
            SavedSearches
            .close-saved-searches(@click="toggleSavedSearch") Close

        .grid.edge
          SearchType
          PropertyType(:ypoUser="ypoUser", :ypoOnly="ypoOnly")
          .cell.twelve.medium-four.medium-order-2
            StayOptions
          AvailabilityKeys
          Activities

          
      .modal-controls
        .grid.collapse-padding.align-center
          .cell.fit.collapse
            span.clear(@click="toggleModal(false)") Cancel
          .cell.fill.text-right.collapse
            span.clear.pseudo.medium-hide(
              @click="clearFilters({ skipDesktop: false })") Clear &nbsp;&nbsp;
            span.clear.pseudo.medium-show(
              @click="clearFilters({ skipDesktop: true })") Clear All &nbsp;&nbsp;
            StarButton.medium-hide(v-if='loggedIn')
            button.button.small(@click="seeResults"
                          ref="resultsButton"
                          :data-tipso="tooltipText")
              span.medium-hide View
              span.medium-show
                | See Results
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import BrowseSearch from '../../location-search/BrowseSearch.vue'
import StayDuration from '../StayDuration.vue'
import BedroomCount from '../BedroomCount.vue'
import StarButton from '../SaveBar/StarButton.vue'
import SearchType from './SearchType.vue'
import StayOptions from './StayOptions.vue'
import AvailabilityKeys from './AvailabilityKeys.vue'
import PropertyType from './PropertyType.vue'
import Activities from './Activities.vue'
import SavedSearches from '../SaveBar/SavedSearches.vue'

export default {
  components: {
    BrowseSearch,
    StayDuration,
    BedroomCount,
    StarButton,
    SavedSearches,

    SearchType,
    StayOptions,
    AvailabilityKeys,
    PropertyType,
    Activities,
  },
  props: {
    zonesUrl:  { type: String, required: true },
    introJsEnabled: {type: Boolean, default: false },
    ypoUser: { type: Boolean, default: false },
    ypoOnly: { type: Boolean, default: false }
  },
  data () {
    return {
      count: 0,
      showModalObserver: null,
      showSavedSearch: false,
      $resultsButton: null,
      tooltipText: "No results found. Expand your criteria to see more results."
    }
  },
  computed: {
    ...get('browseHomes', {
      filtersActive: 'filtersActive',
      filterCount: 'filterCount',
      desktopFilterCount: 'desktopFilterCount',
      resultsCount: 'tentativeResultsCount',
      canViewAvailability: 'canViewAvailability',
      loggedIn: 'loggedIn',
      destinationSelected: 'destinationSelected',
      destinationSelectionText: 'destinationSelectionText',
      word_search: 'filters@word_search'
    }),
    ...sync('browseHomes', {
      destination: 'destination',
      search_type: 'filters@search_type',
      filterModalOpen: 'filterModalOpen'
    }),
    modalHeaderText () {
      if (this.resultsCount === null) {
        return ''
      } else if (this.resultsCount <= 0) {
        return 'No Results' // &nbsp;
      } else {
        const singular = this.resultsCount === 1
        if (this.search_type === 'properties' ||
            this.search_type == 'map') {
          return `${this.resultsCount} Propert${ singular ? 'y' : 'ies' }`
        } else if (this.search_type === 'availability') {
          return `${this.resultsCount} Stay${ singular ? '' : 's' }`
        } else {
          return '\xa0'
        }
      }
    },
    modalClass () {
      return {
        'show-modal': this.filterModalOpen
      }
    },
    savedSearchClass () {
      return {
        open: this.showSavedSearch
      }
    },
    mobileFilterToggleText () {
      if (this.destination) {
        return this.destination.name
      } else if (this.word_search.trim().length) {
        return `"${this.word_search}"`
      } else {
        return 'Where To?'
      }
    }
  },
  watch: {
    async filtersActive () {
      if (this.filterModalOpen) {
        await this.fetch()
      }
    },
    resultsCount () {
      this.checkShowResultsTooltip()
    }
  },
  async mounted () {
    // Set up to handle multiple ways (outside vue) modal can be closed.
    window.addEventListener('keydown', this.escKeyHandler)
    window.addEventListener('popstate', this.popstateHandler)

    this.showModalObserver = new MutationObserver((mutationList, observer) => {
      const showModal = mutationList[0].target.classList.contains('show-modal')
      this.toggleModal(showModal)
    })
    this.showModalObserver.observe(this.$refs.modal, {
      attributeFilter: ['class']
    })

    // Set up the tooltip that complains about not enough results.
    this.$resultsButton = $(this.$refs.resultsButton)
    this.count = this.filterCount
  },
  beforeDestroyed () {
    window.removeEventListener('keydown', this.escKeyHandler)
    window.removeEventListener('popstate', this.popstateHandler)
    this.showModalObserver.disconnect()
  },
  methods: {
    ...call('browseHomes', [
      'clearFilters',
      'resetFiltersFromLastSearch',
      'fetch'
    ]),
    toggleModal (showModal, skipReset = false) {
      // Prevents duplicate calls from clicking the Filters button and
      // the MutationObserver triggering the event twice.
      if (showModal === this.filterModalOpen) {
        return
      }

      this.filterModalOpen = showModal !== undefined
                           ? showModal
                           : !this.filterModalOpen
      if (this.filterModalOpen) {
        window.addEventListener('keydown', this.escKeyHandler)
        setTimeout(() => {
          this.checkShowResultsTooltip()
        }, 400)
        this.showSavedSearch = false
      } else {
        if (!skipReset) {
          this.resetFiltersFromLastSearch()
        }
        window.removeEventListener('keydown', this.escKeyHandler)
        this.checkShowResultsTooltip()
      }

      $('body, .search-filter-modal').toggleClass('show-modal', this.filterModalOpen)
    },
    popstateHandler () {
      this.toggleModal(false, true)
    },
    toggleSavedSearch () {
      this.showSavedSearch = !this.showSavedSearch
    },
    updateFilterSearchType (search_type) {
      this.search_type = search_type
    },
    async desktopClear () {
      await this.clearFilters()
      this.fetch()
    },
    seeResults () {
      // Close modal and skip reset.
      this.toggleModal(false, true)
      this.fetch()
      this.count = this.filterCount
    },
    escKeyHandler (e) {
      if (e.key === 'Escape') {
        e.preventDefault()
        this.toggleModal(false)
      }
    },
    checkShowResultsTooltip () {
      if (this.filterModalOpen &&
          this.resultsCount !== null &&
          this.resultsCount <= 0) {
        this.$resultsButton.tipso({
          background: 'black',
          titleBackground: 'black'
        })
        this.$resultsButton.tipso('show')
      } else {
        this.$resultsButton.tipso('hide')
        this.$resultsButton.tipso('destroy')
      }
    }
  },
}
</script>
