var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-template-wrapper" }, [
    _c(
      "nav",
      { staticClass: "notifications-drawer secondary-nav top-second" },
      [
        _vm._m(0),
        _c("div", { staticClass: "nav-content notifications" }, [
          _c(
            "div",
            {
              staticClass: "grid collapse-padding notifications-controls small"
            },
            [
              _c("div", { staticClass: "cell seven collapse" }, [
                _c("a", { on: { click: _vm.markAllAsRead } }, [
                  _vm._v("Mark All As Read")
                ])
              ]),
              _c("div", { staticClass: "cell five text-right collapse" }, [
                _c("a", { attrs: { href: _vm.notificationsPath } }, [
                  _vm._v("View All")
                ])
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "notifications-holder" },
            [_vm._t("announcements")],
            2
          ),
          _vm.userNotifications.length > 0
            ? _c(
                "div",
                { staticClass: "notifications-holder" },
                _vm._l(_vm.notifications, function(notification) {
                  return _c("notification-item", {
                    key: notification.id,
                    attrs: {
                      notification: notification,
                      read: notification.read
                    }
                  })
                }),
                1
              )
            : _c("div", [
                _c("br"),
                _c("p", [
                  _vm._v(
                    "You don't have any notifications yet!  Please check back later."
                  )
                ])
              ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "grid menu-header align-center collapse-padding" },
      [
        _c("div", { staticClass: "cell fill collapse" }, [
          _c("h4", [_vm._v("Notifications")])
        ]),
        _c("div", { staticClass: "cell fit" }, [
          _c("div", { staticClass: "close-x" })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }