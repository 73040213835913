import { Controller } from "@hotwired/stimulus";
import Token from '../../tools/csrf_token'

const handleErrors = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    let error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

const registerView = (userUrl, params, onSuccess) => {
  fetch(userUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify(params)
  })
  .then(handleErrors)
  .then(resp => resp.json())
  .then(onSuccess)
  .catch(error => console.log("There was an error: ", error))
}

export default class extends Controller {
  connect() {
    this.token = Token()
    this.userId = this.data.get("user-id")
    this.readUrl = this.data.get("read-url")
    this.setBellClass()
  }

  markNotificationsRead(event) {
    this.bellState = "default"
  }

  notificationViewParams() {
    return {
      notification_view: {
        user_id: this.userId
      },
      authenticity_token: this.token
    }
  }

  setBellClass() {
    switch(this.bellState) {
      case "new_unread":
        this.element.classList.remove("has-notifications")
        this.element.classList.add("has-new-notifications")
        break
      case "unread":
        this.element.classList.remove("has-new-notifications")
        this.element.classList.add("has-notifications")
        break
      default:
        this.element.classList.remove("has-new-notifications")
        this.element.classList.remove("has-notifications")
    }
  }

  toggleTray() {
    if(!this.open && this.bellState == "new_unread") {
      const url = this.readUrl
      const params = this.notificationViewParams()

      registerView(url, params, (_resp) => {
        this.bellState = "unread"
      })
    }

    this.open = !this.open
  }

  get bellState() {
    return this.data.get("bell-state")
  }

  set bellState(value) {
    this.data.set("bell-state", value)
    this.setBellClass()
  }

  get open() {
    return this.data.get("open") == "true"
  }

  set open(value) {
    this.data.set("open", value)
  }

}
