<template lang="pug">
div
  br
  h4 Primary Photo
  .primary-photo.photo
    .grid.edge  
      .cell.twelve.medium-eight
        template(v-if="photo")
          img(:src="photo.big"
              :alt="photo.image_description"
              :title="photo.image_description")
        template(v-else)
          .callout
            br
            h5 There is no primary photo.
            p Use the "Add Photos" button below to start adding images.
            br
      .cell.twelve.medium-four
          p.small This is the main photo that will be displayed to members as they browse the list of properties. It is also the first photo displayed at the top of your listing page.
          p.small You can upload additional photos below as well. The more you can add the better!
</template>

<script>
export default {
  props: {
    photo: {
      type: Object,
      default: null
    }
  }
}
</script>
