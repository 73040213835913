import Vue from 'vue';
import TurbolinksAdapter from 'vue-turbolinks';
import VueResource from 'vue-resource';
import WhereTo from './WhereTo.vue';
import init from '../init';

init.onLoad(function () {
  const searchBox = document.getElementById('free-search');
  if (searchBox) {
    Vue.use(VueResource);
    Vue.use(TurbolinksAdapter);
    new Vue({
      el: searchBox,
      components: { WhereTo },
    });
  }
});
