class ActivityMonitor
  constructor: (@idleTimeout) ->
    @active = ko.observable(false)
    @lastActivity = ko.observable(new Date())
    $(window).on('mousemove click mouseup mousedown keydown keypress keyup submit change mouseenter scroll resize dblclick', (=> @_handleActivity()))
    $(window).on('focus', => @_handleActivity())
    $(window).on('blur', => @_recordIdle())
    @_resetTimer()
  _touchActivity: ->
    @lastActivity(new Date())
    @active(true)
  _resetTimer: ->
    @_clearTimer()
    @_timer = setTimeout((=> @_recordIdle()), @idleTimeout)
  _handleActivity: ->
    @_resetTimer()
    @_touchActivity()
  _recordIdle: ->
    @_clearTimer()
    @active(false)
  _clearTimer: ->
    clearTimeout(@_timer) if @_timer

export default ActivityMonitor;
