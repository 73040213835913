var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "choice", attrs: { "data-choice-type": _vm.result.type } },
    [
      _c(
        "a",
        {
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.click($event)
            }
          }
        },
        [_vm._v(_vm._s(_vm.result.description))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }