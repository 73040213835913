import { Controller } from "@hotwired/stimulus";
import Token from '../../tools/csrf_token';
import QRious from 'qrious'

export default class extends Controller {
  static targets = ['qrCodeCanvas']
  static values = {
    url: String
  }

  qrCodeCanvasTargetConnected() {
    this._createTotpFactor()
  }

  async _createTotpFactor() {
    try {
      const { totp: { binding: { uri }, totp_factor } } = await this._fetchTotpBinding()
      document.getElementById('user_totp_factor').value = totp_factor
      this._generateQrCode(uri)
    }
    catch (error) {
      document.getElementById('errors').textContent = 'Could not generate a QR code, please refresh and try again.'
      console.log(error)
    }
  }

  _generateQrCode(uri) {
    new QRious({ element: this.qrCodeCanvasTarget, value: uri, size: 300 });
  }

  async _fetchTotpBinding() {
    const response = await fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify({ authenticity_token: Token() })
    })
    if (response.ok) { return response.json() };
    throw { error: response.status }
  }
}