import ScriptLoader from '../tools/script-loader';

const stripePublicKey = process.env.STRIPE_PUBLIC_KEY;

function configureStripe() {
  return Stripe(stripePublicKey)
};

const loader = new ScriptLoader("https://js.stripe.com/v3/", function () {
  const conf = configureStripe();
  return conf;
});

export default loader;
