<template lang="pug">
.vue-template-wrapper
  nav(class="notifications-drawer secondary-nav top-second")
    .grid.menu-header.align-center.collapse-padding
      .cell.fill.collapse
        h4 Notifications
      .cell.fit
        .close-x
    .nav-content.notifications
      .grid.collapse-padding.notifications-controls.small
        .cell.seven.collapse
          a(@click='markAllAsRead')
            | Mark All As Read
        .cell.five.text-right.collapse
          a(:href="notificationsPath") View All

      .notifications-holder
        slot(name="announcements")

      .notifications-holder(v-if="userNotifications.length > 0")
        notification-item(
          v-for="notification in notifications"
          :notification="notification"
          :read="notification.read"
          :key="notification.id"
        )
      div(v-else)
        br
        p You don't have any notifications yet!  Please check back later.
</template>

<script>
  import {updateUserNotifications} from './requests'
  import NotificationItem from './NotificationItem.vue'
  import Token from '../tools/csrf_token'

  export default {
    props: {
      userId: {type: String, require: true},
      notifications: {type: Array, required: true},
      notificationViewsUrl: {type: String, required: true},
    },
    components: { NotificationItem },
    data () {
      let event = document.createEvent('Event')
      event.initEvent('all-notifications-read', true, true)

      return {
        userNotifications: this.notifications,
        customEventTarget: document.getElementById("notificationBell"),
        customEvent: event,
        csrfToken: Token()
      }
    },
    methods: {
      markAllAsRead() {
        const url = this.notificationViewsUrl
        const params = this.notificationViewParams()

        updateUserNotifications(url, params, (_resp) => {
          this.setNotifications(this.userNotifications.map(n => {
            return Object.assign(n, {read: true})
          }))

          // Tell the notification bell to change its class
          this.customEventTarget.dispatchEvent(this.customEvent)
        })
      },
      notificationViewParams() {
        return {
          notification_view: {
            user_id: this.userId
          },
          authenticity_token: this.csrfToken
        }
      },
      setNotifications(notifications) {
        this.userNotifications = notifications
      },
    },
    computed: {
      notificationsPath() {
        return "/notifications"
      }
    }
  }
</script>

