import Vue from 'vue'
import init from '../init'
import './jquery.fineuploader-3.2.js'
import PropertyPhotos from './PropertyPhotos.vue'

init.pageLoad('property-photos', {
  load () {
    /* eslint-disable no-new */
    new Vue({
      el: '#edit-property-photos',
      components: { PropertyPhotos }
    })
  }
})
