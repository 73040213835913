import { Controller } from "@hotwired/stimulus";
import Token from '../../tools/csrf_token';

export default class extends Controller {
  static values = {
    url: String
  }
  static targets = [ "sendSMS", "verifySMS" ]

  async confirmPhoneNumber({ target }) {
    try {
      this.phoneNumberInput = document.getElementById('phone').value
      await this._createSmsAuth()
      this._toggleFormDisabledElements()
      this._setButtonCooldown(target)
    }
    catch (error) {
      document.getElementById('sms-errors').textContent = 'Could not set up SMS authentication, please refresh and try again.'
      console.log(error)
    }
  }

  _toggleFormDisabledElements() {
    this.sendSMSTarget.hidden = true
    this.verifySMSTarget.hidden = false
    document.getElementById('user_sms_code').disabled = false
    document.getElementById('sms-auth-submit').disabled = false
  }

  _setButtonCooldown(button) {
    const notice = document.getElementById('sms-disabled-notice')
    button.disabled = true
    notice.innerText = 'Please wait 30 seconds before sending another text message'
    setTimeout(() => {
      button.disabled = false
      notice.innerText = ''
    }, 30000)
  }

  async _createSmsAuth() {
    const response = await fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify(this._phoneNumberUpdateParams())
    })
    if (response.ok) { return true };
    throw { error: response.status }
  }

  _phoneNumberUpdateParams() {
    return {
      user: {
        phone: this.phoneNumberInput
      },
      authenticity_token: Token()
    }
  }
}