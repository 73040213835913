<template lang="pug">
  .cell.fill.medium-four.arrive-depart-container(:class="{ longtext }")
    label from / to
    input.search-date-range-picker(:value="stayText" ref="picker" type="text" placeholder="ANYTIME" readonly)
    .input-clear(:class="{ hide: !startDate && !endDate }"
                 @click="drpCancel")
      .close
        .x
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
export default {
  props: {parent: { type: String, default: ''}},
  data () {
    return {
      stayText: "",
      longtext: false,
      picker: null
    }
  },
  computed: {
    ...sync('browseHomes', {
      startDate: 'filters@start_date',
      endDate: 'filters@end_date',
      properties_with_availability: 'properties_with_availability',
      map_all_properties: 'map_all_properties'
    }),
    ...get('browseHomes', ['stayDurationSelection'])
  },
  watch: {
    stayDurationSelection (selection, oldSelection) {
      if (selection === null) {
        this.stayText = ''
        this.picker.setStartDate(dayjs())
        this.picker.setEndDate(dayjs())
      } else if (
        oldSelection === null ||
        selection.start_date !== oldSelection.start_date ||
        selection.end_date !== oldSelection.end_date
      ){
        // Selection has changed, so update the selection text.
        const start = dayjs(selection.start_date)
        const end = dayjs(selection.end_date)
        this.picker.setStartDate(start)
        this.picker.setEndDate(end)
        this.formatStayDuration({ start, end })

        // Advance tour if running
        const event = new Event("proceed-introjs-tour", {bubbles: true})
        this.$el.dispatchEvent(event)

      }
    }
  },
  methods: {
    updateFilters: call('browseHomes/updateFilters'),
    drpApply (event, picker) {
      this.properties_with_availability = null
      this.updateFilters({
        filters: {
          start_date: picker.startDate.format("YYYY-MM-DD"),
          end_date: picker.endDate.format("YYYY-MM-DD")
        }
      })
    },
    drpCancel (event) {
      this.updateFilters({ filters: { start_date: "", end_date: "" } })
      this.stayText = ''
    },
    formatStayDuration ({ start, end }) {
      const stayStart = start || this.picker?.startDate || dayjs()
      const stayEnd = end || this.picker?.endDate || dayjs()
      if (!stayStart.isValid() || !stayEnd.isValid()) {
        this.stayText = ''
      } else if (stayStart.isSame(stayEnd, 'year')) {
        this.longtext = false
        if (stayStart.isSame(stayEnd, 'month')) {
          if (stayStart.isSame(stayEnd, 'day')) {
            this.stayText = stayStart.format(`MMM D, YYYY`)
          } else {
            this.stayText = stayStart.format(`MMM D-${stayEnd.format("D")}, YYYY`)
          }
        } else {
          this.stayText = `${stayStart.format("MMM D")} - ${stayEnd.format("MMM D, YYYY")}`
        }
      } else {
        this.longtext = true
        this.stayText = `${stayStart.format("MMM D, 'YY")} - ${stayEnd.format("MMM D, 'YY")}`
      }
    },
  },
  mounted() {
    const $drp = $(this.$refs.picker)
    const options = {
      locale: {
        cancelLabel: 'Clear',
        format: "YYYY-MM-DD",
      },
      autoApply: true,
      autoUpdateInput: false,
      opens: 'right',
      minDate: new Date(),
      parentEl: this.parent || this.$el
    }
    if (this.startDate && this.endDate) {
      const start = dayjs(this.startDate)
      const end = dayjs(this.endDate)
      Object.assign(options, { startDate: start, endDate: end })
      this.formatStayDuration({ start, end })
    }
    $drp.daterangepicker(options)
    $drp.on('apply.daterangepicker', this.drpApply)
    $drp.on('cancel.daterangepicker', this.drpCancel)
    this.picker = $drp?.data()?.daterangepicker
  }
}
</script>