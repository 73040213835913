class FetchLoader {
  constructor (args) {
    this.fetchArgs = args.fetchArgs;
    this.parser = args.parser;
  }
  load () {
    if (this.loader) { return this.loader };
    this.loader = new Promise((resolve, reject) => {
      const data = this._fetchRequest();
      resolve(data);
    });
    return this.loader;
  }
  async _fetchRequest() {
    const response = await fetch(...this.fetchArgs);
    if (!response.ok) { throw `Did not get ok response: ${response.status}` }

    let responseData = await response.json();

    if (this.parser) {
      responseData = await this.parser(responseData);
    }

    return responseData;
  }
}

export default FetchLoader;
export { FetchLoader };
