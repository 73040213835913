import log from '../tools/log';
import RunQueue from './run_queue';
import manager from './event_manager';

const queues = {
  onces: new RunQueue(true),
  onLoads: new RunQueue(),
  onUnloads: new RunQueue(),
};

const methods = {
  once(initializer) {
    queues.onces.add(initializer);
  },
  onLoad(initializer) {
    queues.onLoads.add(initializer);
  },
  onUnload(initializer) {
    queues.onUnloads.add(initializer);
  },
  _useTurbo() {
    manager.disconnect(normalMap);
    manager.connect(turboMap);
  }
};

const normalMap = {
  DOMContentLoaded() {
    queues.onces.run();
    queues.onLoads.run();
  },
  beforeunload() {
    queues.onUnloads.run();
  }
}

let pageLoaded = false;

const turboMap = {
  "turbo:load": function () {
    if (pageLoaded) return; // turbo:render handles loading hereafter
    pageLoaded = true
    queues.onces.run();
    queues.onLoads.run();
  },
  "turbo:render": function () {
    queues.onces.run();
    queues.onLoads.run();
  },
  "turbo:before-cache": function () {
    queues.onUnloads.run();
  },
  "turbo:before-render": function () {
    queues.onUnloads.run();
  },
  "beforeunload": function () {
    queues.onUnloads.run();
  }
}
manager.connect(normalMap);

export default methods;
