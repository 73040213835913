import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static get targets() {
    return ['container', 'expand', 'more'];
  }

  toggleOpen (e) {
    this.containerTarget.classList.toggle('closed')
    this.moreTarget.classList.toggle('less')
  }

}