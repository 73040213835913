import { Controller } from "@hotwired/stimulus"
import { openModal, closeModal } from '../../core/modals'

export default class extends Controller {
  static targets = ["frame", "modal"]

  edited (e) {
    if (e.detail.success) {
      this.frameTarget.dataset.needsReload = "true"
      closeModal(this.modalTarget)
    }
  }

  open (e) {
    if (this.modalTarget.matches(e.detail.modal)) {
      this._loadFrame()
      openModal(this.modalTarget)
    }
  }

  _loadFrame() {
    if (this.frameTarget.src === null || this.frameTarget.dataset.needsReload == "true") {
      this.frameTarget.src = this.frameTarget.dataset.src
      this.frameTarget.dataset.needsReload = "false"
    }
  }
}