import geoJson from './geo-json';

class MapDataset {
  constructor (url, parser) {
    this.url = url;
    this.data = null;
    this.parser = parser;
  }
  async load() {
    if (this.data) return this.data;
    const response = await fetch(this.url, {
      headers: {
        'Accept': 'application/json'
      },
      credentials: 'same-origin'
    });

    const responseData = await response.json();
    this.data = this.parser || this._buildGeoJson(responseData);
    return this.data;
  }
  _buildGeoJson(data) {
    const {rows, columns} = data;
    return geoJson.fromColumns(rows, columns);
  }
}

export default MapDataset;
export { MapDataset };
