<template lang='pug'>
  .modal.no-print#not-key-earning-deposit(role="dialog")
    .card.modal-card(role="document")
      .modal-header
        h4 Non-Key-earning week added
        .close(aria-label="close")
          .x
      .modal-content(role="document")
        p
          span(v-if="formattedRange")
            span.starts-on.date {{formattedRange.formattedStart}}
            |
            | to
            |
            span.ends-on.date {{formattedRange.formattedEnd}}
          |  will not earn upfront Keys. Keys can only be earned if this week is reserved by a member. To earn upfront Keys, withdraw the week and add a week that is:
          ul
            li(v-if="isAffiliateProperty") 60+ days out
            li(v-else) 90+ days out
            li a Super Peak, Peak, or Non-Peak week
        .buttons.text-right
          a.button.white(@click="remove") Remove
          | &nbsp;
          a.button(@click="close") Add Anyway
</template>

<script>
import { openModal, closeModal } from "../../core/modals";

export default {
  props: { selectedDeposit: { type: Object },
           isAffiliateProperty: { type: Boolean }
         },
  data() {
    return {}
  },
  computed: {
    formattedRange () {
      return this.selectedDeposit && formattedStayRange(this.selectedDeposit.starts_on, this.selectedDeposit.ends_on)
    },
  },
  watch: {
    selectedDeposit (deposit) {
      if (deposit && deposit.delayed_keys) this.open()
    }
  },
  methods: {
    open() {
      openModal(document.querySelector("#not-key-earning-deposit"))
    },
    close() {
      closeModal(document.querySelector("#not-key-earning-deposit"))
    },
    remove() {
      this.$emit("remove", this.selectedDeposit.identifier)
      this.close()
    }
  }
}
</script>
