var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-template-wrapper" }, [
    _vm.selectedDate
      ? _c("div", [
          _vm.multipleStays
            ? _c("div", [
                _c("label", [_vm._v("Select an end date")]),
                _c(
                  "div",
                  { staticClass: "grid" },
                  _vm._l(_vm.orderedOptions, function(deposit) {
                    return _c("DepositRadio", {
                      key: deposit.identifier,
                      attrs: { deposit: deposit },
                      on: { stayPicked: _vm.stayPicked }
                    })
                  }),
                  1
                )
              ])
            : _vm.singleStay
            ? _c(
                "div",
                _vm._l(_vm.stayOptions, function(deposit) {
                  return _c("Deposit", {
                    key: deposit.identifier,
                    attrs: { deposit: deposit }
                  })
                }),
                1
              )
            : _vm._e(),
          _c(
            "p",
            { staticClass: "small", staticStyle: { "margin-top": ".25rem" } },
            [
              _vm._v(
                "You’ll have the opportunity to review your added week and Name Your Price (if applicable) before it is final."
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }