import WeekSelection from './week_selection';

class WeekPostingForm
  constructor: (@freeKeys, selectedDates, @schedule, @_priceList) ->
    @schedule = @schedule
    @wday = ko.computed =>
      @schedule().wday
    @selections = ko.observableArray()
    @holdKeys = ko.computed =>
      key = 0
      for week in @selections()
        weekKeys = week.keys()
        if weekKeys > key
          key = weekKeys
      key
    @anyKeys = ko.computed =>
      @holdKeys() > 0
    @tooFewKeys = ko.computed =>
      @holdKeys() > @freeKeys
    @showingOffer = ko.computed =>
      !!(week for week in @selections() when week.makeOffer()).length
    @canOffer = ko.computed =>
      @selections().length == 1
    if !selectedDates.length
      selectedDates.push undefined
    @addOne(date) for date in selectedDates
    @hasSelections = ko.computed =>
      selections = (selection for selection in @selections() when selection.hasSelected())
      selections.length > 0
    @canSubmit = ko.computed =>
      !@tooFewKeys() && @hasSelections()
  addOne: (date) =>
    @selections.push(new WeekSelection(date, @schedule, @freeKeys, (keys) => @_calculatePrice(keys)))
  submitForm: ->
    if @canSubmit()
      $('#new-request-modal').reveal()
  choiceDescription: (index) =>
      "Week choice"
  _calculatePrice: (keys) ->
    @_priceList[keys]

export default WeekPostingForm;