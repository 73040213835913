export const flashMessage = (type, message) => {
  let flashContainer = document.getElementById('flash-container')
  let flashHTML = `
    <div class="flash-message ${type}">
      <div class="row">
        <div class="columns">
          <h5>${type}</h5>
          ${message}
        </div>
        <div class="columns shrink drop-close">
          <div class="close-x">
          </div>
        </div>
      </div>
    </div>
    `
  if (flashContainer) {
    flashContainer.insertAdjacentHTML('beforeend', flashHTML)
  } else {
    document.getElementById('top-bar').insertAdjacentHTML('afterend', `<div id="flash-container">${flashHTML}</div>`)
  }
}

export const clearFlash = () => {
  const flashContainer = document.getElementById('flash-container');

  if (flashContainer) {
    flashContainer.innerHTML = "";
  }
}
