import init from '../init';

function testUserAgent(regex) {
  const agentString = navigator.userAgent;
  return regex.test(agentString);
}

function addDocumentClass(klass) {
  document.documentElement.classList.add(klass)
}

window.isMobile = testUserAgent(/Android|webOS|iPhone|iPad|iPod|BlackBerry/i);
document.documentElement.setAttribute('data-useragent', navigator.userAgent);

if (testUserAgent(/Safari/) && !testUserAgent(/Chrome/)) {
  addDocumentClass('agent-safari');
  if (testUserAgent(/Mobile/)) {
    addDocumentClass('agent-safari-mobile');
  } else {
    addDocumentClass('agent-safari-desktop');
  }
}
if (testUserAgent(/Exchange iOS/)) {
  addDocumentClass('agent-safari-mobile');
  addDocumentClass('agent-exchange-ios');

  if(testUserAgent(/Exchange iOS 3.0.6-42/)) {
    addDocumentClass('new-app-version')
  }
}
