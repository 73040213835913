import isEmpty from 'lodash/isEmpty'

class ZonesSource {
  constructor(zonesUrl) {
    this.zonesUrl = zonesUrl;
  }
  search(value) {
    return new Promise(async (resolve, reject) => {
      const fetchUrl = `${this.zonesUrl}?value=${value}`;
      const response = await fetch(fetchUrl);
      if (!response.ok) { return reject() };
      const json = await response.json();
      const zones = json.zones;

      if (isEmpty(zones)) return resolve([]);
      const zoneEntries = zones.map((zone) => {
        return {
          data: { id: zone.id },
          description: zone.name,
          path: zone.path,
          type: 'zone'
        }
      });

      return resolve(zoneEntries);
    });
  }
}

export default ZonesSource;
