import observer from '../init/observer';
import tipsoTooltip  from '../tools/tipso-tooltip'

observer.add('[data-tipso]', function (elements) {
  tipsoTooltip.init(elements);
});

observer.remove('[data-tipso]', function (elements) {
  tipsoTooltip.destroy(elements);
});

$(document).on('click', 'a [data-tipso],a .tipso_style', function (e) {
  if (isMobile) {
    e.preventDefault();
  }
});
