function wrapHandler(selector, handler) {
  return (function (e) {
    const target = e.target
    const matchingElement = target.closest(selector)

    if (!matchingElement) return;

    if ((matchingElement === target) || e.currentTarget.contains(matchingElement)) {
      handler(e)
    }
  })
}

function delegateEvent(...args) {
  if (args.length == 3) {
    var delegate = document
    var [eventName, selector, handler] = args
  } else {
    var [delegate, eventName, selector, handler] = args
  }

  const wrapped = wrapHandler(selector, handler)
  delegate.addEventListener(eventName, wrapped)

  return wrapped
}

export default delegateEvent
export { delegateEvent }
