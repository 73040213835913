var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-template-wrapper" },
    [
      _c("ConfirmationModal", {
        attrs: {
          selectedDeposits: _vm.selectedDeposits,
          confirmUrl: _vm.confirmUrl,
          requireConfirmationIds: _vm.requireConfirmationIds,
          shouldTrackPrompt: _vm.shouldTrackPrompt,
          redirectPath: _vm.redirectPath,
          ownerCanNyop: _vm.ownerCanNyop,
          rentalWday: _vm.rentalWday
        },
        on: { removeDeposit: _vm.removeDeposit }
      }),
      _vm.ownerCanLastMinute
        ? _c(
            "div",
            {
              staticClass: "expanding-section closed",
              attrs: {
                "data-controller": "expand-collapse",
                "data-expand-collapse-target": "container"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "expand-toggle",
                  attrs: { "data-action": "click->expand-collapse#toggleOpen" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "deposit-entry grid",
                      staticStyle: {
                        "padding-top": ".5rem",
                        "padding-bottom": ".5rem"
                      },
                      attrs: { "data-expand-collapse-target": "more" }
                    },
                    [
                      _c("div", { staticClass: "cell" }, [
                        _c("strong", [
                          _vm._v("Add\n"),
                          _vm.anyBefore
                            ? _c("span", [_vm._v("additional\n")])
                            : _vm._e(),
                          _vm._v("weeks before " + _vm._s(_vm.firstRegWeek)),
                          _c("span", { staticClass: "down-arrow" })
                        ])
                      ])
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "expanding-content",
                  attrs: { "data-expand-collapse-target": "expand" }
                },
                [
                  _c("br"),
                  _c(
                    "div",
                    {
                      staticClass: "callout",
                      attrs: { id: "last-minute-info" }
                    },
                    [
                      _c("h5", { staticClass: "sub-header" }, [
                        _vm._v("* About Last Minute Deposits")
                      ]),
                      _c("p", { staticClass: "small" }, [
                        _vm._v(
                          "Take advantage of any upcoming availability at your property!\nGiven the last-minute nature of these dates (they start in the\nnext " +
                            _vm._s(_vm.depositWindow) +
                            " days), you will only receive Keys if the\ndeposits are booked by another member."
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { attrs: { id: "bookings-soon" } },
                    _vm._l(_vm.before, function(deposit) {
                      return _c("DepositGroup", {
                        key: deposit.id,
                        attrs: {
                          parent: deposit,
                          selectedDeposits: _vm.selectedDeposits
                        },
                        on: { staySelected: _vm.staySelected }
                      })
                    }),
                    1
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "bookings-later" },
        _vm._l(_vm.after, function(deposit) {
          return _c("DepositGroup", {
            key: deposit.id,
            attrs: { parent: deposit, selectedDeposits: _vm.selectedDeposits },
            on: { staySelected: _vm.staySelected }
          })
        }),
        1
      ),
      _vm._m(0),
      _c("div", { staticStyle: { margin: "20px 0 0" } }, [
        _c(
          "a",
          {
            staticClass: "button",
            class: _vm.modalToggleActive,
            attrs: {
              href: "#confirm-deposits",
              disabled: !_vm.hasSelectedDeposits
            }
          },
          [_vm._v("Submit Deposits")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("br"),
      _c("span", { staticClass: "asterisk-size" }, [_vm._v("*")]),
      _vm._v(
        "These Keys will be awarded when the deposit is reserved by a guest."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }