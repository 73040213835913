import { Controller } from "@hotwired/stimulus";
import { SmoothHorizontalScrolling } from "../../tools/safari_smooth";

export default class extends Controller {
  static get targets() {
    return ['leftButton', 'rightButton', 'row'];
  }

  connect () {
    this._initializeButtons();
  }

  get hasButtons () {
    return this.hasLeftButtonTarget && this.hasRightButtonTarget;
  }

  _move (direction) {
    let position;
    const row = this.rowTarget;

    if (direction == 'right') {
      position = row.scrollLeft + row.clientWidth;
    } else {
      position = row.scrollLeft - row.clientWidth;
    }

    if (position < 0) {
      position = 0;
    }
    if ( document.documentElement.classList.contains("agent-safari-desktop") ) {
      SmoothHorizontalScrolling(row, 350, position)
    } else {
      row.scrollLeft = position;
    }
    this._setButtonsActivity(position);
  }

  _setButtonsActivity (position) {
    if (!this.hasButtons) return;
    const row = this.rowTarget;
    if (position === undefined) position = row.scrollLeft;

    const hasNext = (position + row.clientWidth) < row.scrollWidth;
    const hasPrev = position > 0;

    this.leftButtonTarget.classList.toggle('disabled', !hasPrev);
    this.rightButtonTarget.classList.toggle('disabled', !hasNext);
  }

  _initializeButtons() {
    if (!this.hasButtons) return;
    const row = this.rowTarget;
    if (row.clientWidth == row.scrollWidth) {
      setTimeout(() => this._initializeButtons(), 100);
    } else {
      this._setButtonsActivity();
    }
  }

  moveNext (e) {
    e.preventDefault();
    this._move('right');
  }

  movePrev (e) {
    e.preventDefault();
    this._move('left');
  }
}
