import { Controller } from "@hotwired/stimulus";
import { trackEvent } from '../../analytics';
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

// Determines if any part of the supplied element
// is shown in the window.
function isShown(el) {
  const rect = el.getBoundingClientRect();
  const elTop = rect.top;
  const elBottom = rect.bottom;

  return (
    0 < elTop && elTop < window.innerHeight
  ) || (
    0 < elBottom && elBottom < window.innerHeight
  );
}

export default class extends Controller {
  initialize() {
    this.started = false;
    this.anticipated = false;
    this.resize = debounce(this._refreshSlick, 500);
    this.scroll = throttle(this._handleScroll, 250, {leading: false, trailing: true})
  }
  connect() {
    this._startIfShown();
  }
  disconnect() {
    this._stopSlider();
  }
  hover() {
    this._startSlider();
    this._anticipateSlider();
  }
  touch() {
    this.hover();
  }
  _handleScroll() {
    this._startIfShown();
  }
  _startIfShown() {
    if (this.started) return;

    if (isShown(this.element)) {
      this._startSlider();
    }
  }
  _startSlider() {
    if (this.started) return;

    this.started = true;
    $(this.element).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 0,
      prevArrow: '<a class="slick-prev"><span></span></a>',
      nextArrow: '<a class="slick-next"><span></span></a>',
      infinite: true,
      lazyLoad: 'ondemand'
    })
    .on('afterChange', () => this._slideChanged())
    .on('mousedown', '.slick-arrow', e => e.stopPropagation());
  }
  _anticipateSlider() {
    if (this.anticipated) return;

    this.anticipated = true;
    $(this.element).slick('slickSetOption', 'lazyLoad', 'anticipated', true);
  }
  _slideChanged() {
    this._anticipateSlider();
    trackEvent('Properties', 'Browse Photo')
  }
  _refreshSlick() {
    if (this.started) {
      $(this.element).slick('setPosition');
    }
  }
  _stopSlider() {
    if (!this.started) return;

    const $el = $(this.element);
    if ($el.hasClass('slick-initialized')) {
      $el.slick('unslick');
    }
  }
}
