var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cell collapse day",
      class: _vm.dayClasses,
      attrs: {
        "data-start-range": _vm.startRangeAttr,
        "data-end-range": _vm.endRangeAttr,
        "data-during": _vm.duringAttr,
        "data-season": _vm.season
      },
      on: { click: _vm.dateSelected }
    },
    [
      _c("div", { staticClass: "day-number" }, [
        _vm._v(_vm._s(_vm.dayOfMonth))
      ]),
      _c("span", { staticClass: "day-span" }),
      _c("span", { staticClass: "day-span" }),
      _c("div", { staticClass: "season" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }