import { openModalSelector } from '../core/modals';
import tooltips from '../tools/tooltips';

class ShareButton {
  constructor(el) {
    this._el = $(el);
  }

  triggerShare() {
    if (window.navigator.share && isMobile) {
      this._triggerNativeShare();
    } else {
      openModalSelector('#property-share');
    }
  }

  _triggerNativeShare() {
    window.navigator.share({
      title: this._shareTitle(),
      url: this._shareUrl(),
    });
  }

  _shareTitle() {
    return this._el.data("share-title");
  }

  _shareText() {
    return this._el.data("share-text");
  }

  _shareUrl() {
    return this._el.attr("href");
  }
}

export default ShareButton;
