export default {
  dateInRange (date, range) {
    return range.contains(dayjs(date));
  },
  dateOverlapsRanges (date, ranges) {

    return ranges.reduce((accumulator, range) => {
      if(date === range.starts_on) { return false; }
      if(date === range.ends_on) { return true; }

      range = dayjs.range(range.starts_on, range.ends_on);
      let inRange = true;

      inRange = this.dateInRange(date, range);

      return accumulator && !inRange;
    }, true);
  },
  datesOverlapRanges (startsOn, endDate, ranges) {

    return ranges.reduce((accumulator, range) => {
      if(startsOn === range.starts_on) { return false; }

      range = dayjs.range(range.starts_on, range.ends_on);
      let inRange = true;

      const testRange = dayjs.range(startsOn, endDate);
      inRange = range.overlaps(testRange);

      return accumulator && !inRange;
    }, true);
  },
  filterRangesByDate (date, ranges) {
    return ranges.filter((range) => this.dateInRange(date, this.rangeForDeposit(range)));
  },
  rangeForDeposit (deposit) {
    return this.range(deposit.starts_on, dayjs(deposit.ends_on).endOf('day'));
  },
  range (start, end) {
    return dayjs.range(start, end);
  }
};
