import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect () {
    this.slides = document.getElementsByClassName('featured-search');
    this.sliderLength = this.slides.length;

    if (getComputedStyle(this.element.querySelector('.mobile-blur')).getPropertyValue('display') == 'block') {
      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          const entryInView = entry.isIntersecting;
          const background = this.getMobileBackground(entry.target.getAttribute('data-index'));
          entry.target.classList.toggle('in-view', entryInView);
          if (background) background.classList.toggle('active', entryInView);
        });
      }, { threshold: [1] });

      [...this.slides].forEach((slide) => {
        this.observer.observe(slide);
      })
    }
  }

  disconnect () {
    if (this.observer) this.observer.disconnect();
  }

  getMobileBackground (index) {
    return this.element.querySelector(`.blur-in[data-index="${index}"]`);
  }

  showSlide (e) {
    var slide = e.currentTarget;
    if (!this.elementIsFullyVisibleInViewport(slide)) {
      e.preventDefault();
    }
    if (!slide.classList.contains('active')) {
      this.promoteSlide(slide);
    }
  }

  next (e) {
    e.preventDefault();
    const position = 1;
    this.promoteSlide(this.getSlide(position));
  }

  prev (e) {
    e.preventDefault();
    const position = this.sliderLength - 1;
    this.promoteSlide(this.getSlide(position));
  }

  getSlide (position) {
    return this.element.querySelector(`.featured-search[data-position="${position}"]`);
  }

  restoreSlide (slide) {
    slide.classList.add('last-active')
    slide.classList.remove('active', 'transitioning')
    this.element.classList.remove('moving-left')
    setTimeout(() => {
      slide.classList.remove('last-active')
    }, 60)
  }

  handleTransitionEnd (e) {
    if (e.propertyName == 'width' && e.target.matches('.featured-search.active')) {
      let outgoing = this.element.querySelector('.featured-search.active.transitioning');
      if (outgoing) this.restoreSlide(outgoing);
    }
  }

  promoteSlide (target) {
    const outgoing = this.element.querySelector('.featured-search.active');
    outgoing.classList.add('transitioning');
    target.classList.add('active');

    const offset = parseInt(target.getAttribute('data-index'));
    const curPosition = parseInt(target.getAttribute('data-position'));

    if (curPosition == (this.sliderLength-1)) {
      this.element.classList.add('moving-left')
    }

    for(let i=0; i < this.sliderLength; i++) {
      let position = (i + offset) % this.sliderLength
      this.slides[position].setAttribute('data-position', i)
    }
  }

  elementIsFullyVisibleInViewport (el) {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
  }

}
