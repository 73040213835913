#Expand / Collapse
$(document).on 'click', '.expanding-section .expand-title', (e) ->
  e.preventDefault()
  if $(this).parent().hasClass('mobile')
    if window.innerWidth <= 768
      $(this).toggleClass 'opened'
      $(this).siblings('.expand-content').slideToggle()
  else
    $(this).toggleClass 'opened'
    $(this).siblings('.expand-content').slideToggle()

$(document).on 'click', '.collapsible-section .collapsible-shown', (e) ->
  e.preventDefault()
  $(this).parent().toggleClass('show-full')