import init from '../init';
import ActivityMonitor from './activity_monitor';
import PropertyViewTracker from './property_view_tracker';
import { trackEvent } from '../analytics';

init.once ->
  $(document).on 'click', '.book-now-link', (e) ->
    e.preventDefault()
    scrollId = event.target.hash.substr(1)
    scrollTarget = document.getElementById(scrollId)

    scrollTarget.scrollIntoView({behavior: "smooth", block: "start"})

init.pageLoad 'property-profile',
  load: ->

    url = $('#property-profile').data('property-view-update-url')
    if url
      @viewTracker = new PropertyViewTracker(url)
      activityMonitor = new ActivityMonitor(10000)
      @viewTracker.start(url, activityMonitor)

    trackEvent('Properties', 'View')
  unload: ->
    if @viewTracker
      @viewTracker.stop()