import Vue from 'vue'
import VueResource from 'vue-resource'
import Vue2TouchEvents from 'vue2-touch-events'
import StayRequestCalendarList from './StayRequestCalendarList.vue'
import StayRequestOptions from './StayRequestOptions.vue'
import { createStore } from '../vuex-store/index.js'
import init from '../init'

Vue.use(VueResource)
Vue.use(Vue2TouchEvents)

init.onLoad(function () {
  const store = createStore()
  $.exists('#stay-request-calendar', ($el) => {
    /* eslint-disable no-new */
    new Vue({
      el: $el[0],
      components: { StayRequestCalendarList },
      store
    })
  })
  $.exists('#additional-request-details', ($el) => {
    /* eslint-disable no-new */
    new Vue({
      el: $el[0],
      components: { StayRequestOptions },
      store
    })
  })
})
