<template lang="pug">
ul.saved-search-list
  li(v-for="search in searchesDisplay"
     :key="search.id"
     @click.stop="selectSearch(search)")
    .name {{search.summary}}
  li
    a(href="/account/searches") Manage Saved Searches

</template>

<script>
import clone from 'lodash/clone'
import { get, call } from 'vuex-pathify'

export default {
  computed: {
    searches: get('browseHomes/savedSearches'),
    searchesDisplay () {
      const searches = clone(this.searches)
      searches.sort(function (a,b) {
        if (a.summary < b.summary) {
          return -1;
        }
        if (a.summary > b.summary) {
          return 1;
        }
        return 0;
      });
      return searches
    },
  },
  methods: {
    ...call('browseHomes', {
      updateFilters: 'updateFilters',
      setDestination: 'setDestination'
    }),
    selectSearch (search) {
      // Strip out the details related to the saved search, leaving the filters.
      const {
        id, summary, category_name, category_type, destination,
        ...filters
      } = search

      if (search.destination_id) {
        this.setDestination({
          id: search.destination_id,
          ...search.destination
        })
      } else {
        this.setDestination(null)
      }
      this.updateFilters({ filters, skipFetch: false })
      this.$emit('search-selected', search)
    },
  }
}
</script>
