// A Rails template & Stimulus implementation of the star button located in app/javascript/browse-homes/SaveBar/StarButton.vue
// This is to allow for a lightweight insert of this button by itself without having to create new Vue instances
// and rely on the chain of methods and data that the overall BrowseHomes package is built in mind with

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['starButton']
  static values = {
    existingSearches: Array,
    active: Boolean,
    searchId: Number,
  }

  activeValueChanged = () => this.starButtonTargets.forEach(el => el.classList.toggle('active', this.activeValue))

  async toggleSaveSearch() {
    try {
      const response = await fetch(`/searches/${this.searchIdValue}/update_subscription`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ subscribe: !this.activeValue })
        
      })
      if (response.status == 200) {
        this.activeValue = !this.activeValue
      }
    } catch (e) {
      console.log('Could not save search: ', e)
    }
  }
}