var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "pill icon-pill saved-search",
      class: { active: _vm.saved },
      attrs: {
        "data-step": "4",
        "data-title": "Stay in the know",
        "data-intro":
          "Receive notifications when new availability is added to this search by clicking the 'Save Search' button."
      },
      on: { click: _vm.toggleSaveSearch }
    },
    [
      _vm._m(0),
      _c("span", { staticClass: "pill-text" }, [_vm._v("Save Search")])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pill-icon" }, [
      _c("span", { staticClass: "third-font-star default-icon" }),
      _c("span", { staticClass: "third-font-star-glyph active-icon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }