import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ['tooltip', 'paginationText']
  initialize () {
    this.index = 0;
    this.totalPages = this.data.get('total-pages')
    this.currentPage = 1;
  }

  connect () {
    this.setPaginationText();
    this.showCurrentTooltip();
  }

  prev () {
    if (this.currentPage == 1) {
      this.index = this.totalPages - 1;
      this.currentPage = this.totalPages;
    } else {
      this.index--;
      this.currentPage--;
    }

    this.setPaginationText();
    this.showCurrentTooltip();
  }

  next () {
    if (this.currentPage == this.totalPages) {
      this.index = 0;
      this.currentPage = 1;
    } else {
      this.index++;
      this.currentPage++;
    }

    this.setPaginationText();
    this.showCurrentTooltip();
  }

  setPaginationText () {
    this.paginationTextTarget.innerHTML = `${this.currentPage} of ${this.totalPages}`;
  }

  showCurrentTooltip () {
    this.tooltipTargets.forEach((tooltip, i) => {
      tooltip.classList.toggle("property-tooltip--current", this.index == i)
    })
  }
}