import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkboxForm', 'approveButton']

  validateForm() {
    const checkboxes = this.checkboxFormTarget.querySelectorAll('input[type="checkbox"]')
    let allChecked = true

    for (let i = 0; i < checkboxes.length; i++) {
      if (!checkboxes[i].checked) allChecked = false
    }

    if (allChecked) {
      this.approveButtonTarget.classList.remove('disabled')
    } else  {
      this.approveButtonTarget.classList.add('disabled')
    }
  }
}
