import './knockout/binding-handlers/confirm-click';

Array.prototype.equals = (anotherArray) ->
  return false if not Array.isArray(anotherArray)
  return false if @length != anotherArray.length
  @every (value, index) ->
    anotherArray[index] == value

Array.prototype.hasElement = (value) ->
  @indexOf(value) != -1

window.formatDateRangeArray = (startDate, endDate) ->
  start = dayjs(startDate)
  end = dayjs(endDate)
  includeStartYear = start.year() != end.year()
  includeEndWeekday = start.weekday() != end.weekday()
  startFormat = "ddd, MMM Do"
  if includeStartYear
    startFormat += ", YYYY"
  endFormat = "MMM Do, YYYY"
  if includeEndWeekday
    endFormat = "ddd, #{endFormat}"
  [start.format(startFormat), end.format(endFormat)]

window.formatDateRange = (startDate, endDate) ->
  [startDate, endDate] = formatDateRangeArray(startDate, endDate)
  "#{startDate} to #{endDate}"

window.keyCount = (count) ->
  """<span class="key-container"><span class="key-count" title="#{count} Keys"><span class="count">#{count}&nbsp;</span><span class="third-font-key-glyph icon"></span></span></span>"""

$.exists = (selector, initializer) ->
  $ ->
    $e = $(selector)
    if $e.exists()
      initializer($e)

$.fn.exists = ->
  return @length > 0

window.formatStayRange = (startDate, endDate) ->
  range          = formattedStayRange(startDate, endDate)

  return "#{range.formattedStart} to #{range.formattedEnd}"

window.formattedStayRange = (startDate, endDate) ->
  start = dayjs(startDate)
  end = dayjs(endDate)
  fullDate = 'ddd, MMM D, YYYY'
  noYear = 'ddd, MMM D'
  noWeekday = 'MMM D, YYYY'

  if start.get('year') == end.get('year')
    startFormatter = noYear
  else
    startFormatter = fullDate

  if start.get('day') == end.get('day')
    endFormatter = noWeekday
  else
    endFormatter = fullDate

  return {
    formattedStart: start.format(startFormatter),
    formattedEnd: end.format(endFormatter)
  }

