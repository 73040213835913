import currentPath from '../tools/current-path';

function push (array) {
  const _hsq = window._hsq = window._hsq || [];
  _hsq.push(array);
}

function identify (object) {
  push(["identify", object]);
}

function setPath (path) {
  push(['setPath', path]);
}

function trackPageView () {
  push(['trackPageView']);
}

function trackCurrentPageView () {
  setPath(currentPath());
  trackPageView();
}

function trackEvent (eventName) {
  push(['trackEvent', {
    id: eventName
  }])
}

export default {identify, trackCurrentPageView, trackEvent}
