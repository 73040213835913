import Vue from 'vue'
import getCsrfToken from '../../tools/csrf_token.js'
import { navigate } from '../../navigator';
import {
  generateMonthWeeks,
  MIN_STAY,
  MAX_STAY
} from './common.js'
import {
  SET_SELECTION,
  SET_RANGE,
  SET_UNAVAILABLE,
  SET_OPTIONS,
  SET_URLS,
  SET_QUOTE,
  SET_ERROR
} from './mutation-types.js'

const state = {
  range: null, // dayjs range object
  selection: {
    start: null,
    hoverEnd: null,
    end: null
  },
  unavailable: [],
  options: {
    keys: 0,
    guests: 1,
    message: '',
    keyMultiplier: 1
  },
  quote: null,
  error: null,
  urls: {}
}

const getters = {
  selectionComplete: ({ selection: { start, end } }) => start && end,
  selectionStart: ({ selection: { start } }) => start,
  stayRange: ({ selection: { start, end } }) => {
    if (start && end) {
      return dayjs.range(start, end)
    } else {
      return null
    }
  },
  hoverStayRange: ({ selection: { start, hoverEnd, end } }) => {
    if (start && hoverEnd && !end) {
      return dayjs.range(start, hoverEnd)
    } else {
      return null
    }
  },
  weeksForMonth: ({ unavailable }) => {
    return (month) => generateMonthWeeks(month, unavailable)
  },
  lastValidEndDate: ({ selection: { start }, unavailable }) => {
    if (!start) {
      return null
    }

    let maxSelection = dayjs.range(start, dayjs(start).add(MAX_STAY, 'days'))

    for (const reserved of unavailable) {
      if (maxSelection.overlaps(reserved.range, { adjacent: false })) {
        if (start.diff(reserved.range.start, 'days') > MIN_STAY) {
          return null
        } else {
          maxSelection = dayjs.range(start, reserved.range.start)
        }
      }
    }
    return maxSelection.end
  },
  availableRange: ({ selection: { start } }, { lastValidEndDate }) => {
    return dayjs.range(start, lastValidEndDate)
  },
  selectableEndRange: ({ selection: { start } }, { lastValidEndDate }) => {
    if (start && lastValidEndDate) {
      return dayjs.range(
        dayjs(start).add(MIN_STAY, 'days'),
        lastValidEndDate
      )
    } else {
      return null
    }
  }
}

const mapUnavailableRange = (calendar) => {
  return calendar.map(reserved => {
    return {
      ...reserved,
      range: dayjs.range(reserved.starts_on, reserved.ends_on)
    }
  })
}

const actions = {
  initialize ({ commit }, { range, calendar }) {
    commit(SET_SELECTION, {
      start: null,
      hoverEnd: null,
      end: null
    })
    commit(SET_OPTIONS, {
      keys: 0,
      guests: 1,
      message: ''
    })
    commit(SET_QUOTE, null)
    commit(SET_RANGE, dayjs.range(range.start, range.end))
    commit(SET_UNAVAILABLE, mapUnavailableRange(calendar))
  },

  selectDate ({ commit, state, getters, dispatch }, date) {
    const selectedDate = dayjs(date)
    const { start, end } = state.selection

    if (selectedDate.isSame(start, 'day')) {
      // 1) User clicked the start date, so we remove the whole selection.
      dispatch('clearSelection')
    } else if (start && !end && selectedDate.isSameOrAfter(dayjs(start).add(3, 'days'), 'day')) {
      // 2) User has clicked a day in the range possible to end the selection.
      commit(SET_SELECTION, {
        start: start,
        end: selectedDate
      })
      commit(SET_OPTIONS, { keys: 0 })
      dispatch('fetchQuote')
    } else {
      // 3) User has selected the (new) start of a stay range.
      commit(SET_QUOTE, null)
      commit(SET_SELECTION, {
        start: selectedDate,
        hoverEnd: dayjs(selectedDate).add(3, 'days'),
        end: null
      })
    }
  },

  hoverDate ({ commit, state: { selection, unavailable }, getters }, hoverDate) {
    if (selection.start && !selection.end) {
      const validEnds = dayjs.range(
        dayjs(selection.start).add(MIN_STAY, 'days'),
        getters.lastValidEndDate
      )
      if (validEnds.contains(hoverDate)) {
        commit(SET_SELECTION, {
          ...selection,
          hoverEnd: hoverDate
        })
      }
    }
  },

  setSelection ({ commit }, selection) {
    commit(SET_SELECTION, selection)
  },
  setUrls ({ commit }, urls) {
    commit(SET_URLS, urls)
  },
  setOptions ({ commit }, options) {
    commit(SET_OPTIONS, options)
  },

  fetchQuote ({ commit, state, dispatch }) {
    const { start, end } = state.selection
    commit(SET_ERROR, null)

    Vue.http.post(state.urls.quote, {
      week_request: {
        starts_on: start,
        ends_on: end,
        offer_keys: state.options.keys,
        key_multiplier: state.options.keyMultiplier,
        starts_on_with_offset: toIsoString(start.toDate()),
        ends_on_with_offset: toIsoString(end.toDate())
      },
      authenticity_token: getCsrfToken()
    }).then((response) => {
      if (response.ok && !response.body.error_code) {
        commit(SET_QUOTE, response.body)

        // eslint-disable-next-line camelcase
        const { keys, offer_keys } = response.body
        commit(SET_OPTIONS, { keys: Math.max(keys, offer_keys) })
      } else if (response.body.error_code) {
        commit(SET_ERROR, response.body)
      }
    }).catch(async (error) => {
      console.log('Error quoting: ', error)
      if (error.body?.request_calendar) {
        await dispatch('clearSelection')
        commit(SET_UNAVAILABLE, mapUnavailableRange(error.body.request_calendar))
      }
      commit(SET_ERROR, error.body)
    })
  },

  submitStayRequest ({ commit, dispatch, state }) {
    const { selection, options } = state
    Vue.http.post(state.urls.submit, {
      starts_on: toIsoString(selection.start.toDate()),
      ends_on: toIsoString(selection.end.toDate()),
      offer_keys: options.keys,
      number_of_guests: options.guests,
      additional_notes: options.message,
      authenticity_token: getCsrfToken()
    }).then((response) => {
      if (response.ok && response.body.property_url) {
        navigate(response.body.property_url)
      } else if (response.body.error_code && response.body.redirect_url) {
        navigate(response.body.redirect_url)
      }
        else if (response.body.error_code) {
        commit(SET_ERROR, response.body)
      }
    }).catch(error => {
      if (error.data.redirect_url) {
        navigate(error.data.redirect_url)
      } else {
        commit(SET_ERROR, error.body)
      }
    })
  },

  clearSelection ({ commit }) {
    commit(SET_SELECTION, { start: null, hoverEnd: null, end: null })
    commit(SET_QUOTE, null)
    commit(SET_OPTIONS, { keys: 0 })
    commit(SET_ERROR, null)
  }
}

function toIsoString (date) {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
}

const mutations = {
  [SET_SELECTION]: (state, selection) => {
    state.selection = selection
  },
  [SET_RANGE]: (state, range) => {
    state.range = range
  },
  [SET_UNAVAILABLE]: (state, unavailable) => {
    state.unavailable = unavailable
  },
  [SET_OPTIONS]: (state, options) => {
    state.options = {
      ...state.options,
      ...options
    }
  },
  [SET_URLS]: (state, urls) => {
    state.urls = urls
  },
  [SET_QUOTE]: (state, quote) => {
    state.quote = quote
  },
  [SET_ERROR]: (state, error) => {
    state.error = error
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
