import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["photoInput", "preview", "placeholder"];

  connect() {
    this.photoInputTarget.addEventListener("change", (event) => {
      this.previewImage(event);
    });
  }

  previewImage(event) {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewTarget.style.backgroundImage = `url('${e.target.result}')`;

        if (this.hasPlaceholderTarget) {
          this.placeholderTarget.remove();
        }
      };
      reader.readAsDataURL(file);
    }
  }
}
