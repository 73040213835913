<template lang='pug'>
  #card-collector
    .stripe_cc_number_wrapper.input(v-bind:class="{'invalid': stripeErrorsMessages}")
      #cc-number(ref="cc")
      small.error(v-if="stripeErrorsMessages") {{stripeErrorsMessages}}
    .input(v-bind:class="{'invalid': localErrorMessages}")
      label(for="card-collector-name-on-card") Name on card
      input#card-collector-name-on-card(ref='name' type="text" v-model="name" @keyup.enter="submit" autocapitalize="words")
      .small.feedback(v-if="localErrorMessages") {{localErrorMessages}}
</template>
  
<script>
import stripeLoader from './stripe-loader';
const defaultStyles = {
  style: {
    base: {
      color: '#797979',
      fontFamily: '"montserratlight", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
    },
    empty: {
      color: '#797979',
      fontFamily: '"montserratlight", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
      '::placeholder': {
        color: 'rgba(121, 121, 121, 0.64)'
      }
    }
  }
}
export default {
  data() {
    return {
      stripeToken: "",
      name: "",
      styles: defaultStyles,
      stripeErrors: [],
      localErrors: []
    }
  },
  mounted() {
    stripeLoader.then(() => {
      this.card.unmount();
      this.card.mount(this.$refs.cc);
      this.card.on('ready', () => this.initFocus());
    })
  },
  created() {
    stripeLoader.then((stripe) => {
      this.stripe = stripe;
      const elements = this.stripe.elements();
      this.card = elements.create('card', this.styles);
    })
  },
  computed: {
    validationErrors() {
      return this.stripeValidationErrors.concat(this.localErrors);
    },
    stripeErrorsMessages() {
      return this.stripeValidationErrors.join('. ');
    },
    stripeValidationErrors() {
      return this.stripeErrors
        .filter(e => e.type == "validation_error")
        .map(e => e.message);
    },
    localErrorMessages() {
      return this.localErrors.join('. ');
    },
    hasValidationErrors() {
      return this.validationErrors.length > 0;
    }
  },
  methods: {
    initFocus() {
      if ($(this.$refs.cc).is(':visible')) {
        this.focusCardInput();
      }
    },
    focus() {
      this.focusCardInput();
    },
    focusCardInput() {
      this.card.focus();
    },
    focusNameInput() {
      this.$refs.name.focus();
    },
    submit() {
      this.$emit('submit');
    },
    validate() {
      let nameValue = this.name;
      if (nameValue.trim) nameValue = nameValue.trim();
      let validationErrors = [];
      if (!nameValue) {
        validationErrors.push("Name on card must be supplied.");
        this.focusNameInput();
      }
      this.localErrors = validationErrors;
      this.stripeErrors = [];
    },
    attempt() {
      return new Promise((resolve, reject) => {
        this.validate();
        if (this.hasValidationErrors) {
          return this.rejectWithErrors(reject);
        };
        this.stripe.createToken(this.card, {name: this.name}).then((result) => {
          let {error, token} = result;
          if (error) {
            this.stripeErrors = [error];
            this.rejectWithErrors(reject);
          } else {
            this.stripeToken = token;
            resolve(this.stripeToken.id);
          }
        })
      })
    },
    rejectWithErrors(reject) {
      let errorMessages = this.validationErrors;
      if (!errorMessages.length) {
        errorMessages == ["An unknown error occurred. Please try again."];
      }
      reject(errorMessages);
    }
  }
}
</script>