<template lang="pug">
.request-calendar-month.cell.calendar-holder(:class='monthClasses')
  .grid.edge.month.align-center.collapse-padding
    .cell.fit.collapse
      .arrow.new-arrow.left(@click="previous")
        .notch
    .cell.fill {{ monthLabel }}
    .cell.fit.collapse
      .arrow.new-arrow.right(@click="next")
        .notch

  .grid.days.collapse-padding
    .cell.collapse Sun
    .cell.collapse Mon
    .cell.collapse Tue
    .cell.collapse Wed
    .cell.collapse Thu
    .cell.collapse Fri
    .cell.collapse Sat

  .grid.collapse-padding.cal-row(v-for='(week, i) in weeksForMonth(month)' :key='i')
    calendar-day(v-for='(day, i) in week'
                 :key='i'
                 v-bind='day'
                )

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CalendarDay from './CalendarDay.vue'

export default {
  components: {
    CalendarDay
  },
  props: {
    month: { type: Object, required: true },
    previous: { type: Function, required: true },
    next: { type: Function, required: true }
  },
  computed: {
    ...mapState('calendars/stay-request', ['range']),
    ...mapGetters('calendars/stay-request', ['weeksForMonth']),
    monthLabel () {
      return this.month.format('MMMM YYYY')
    },
    monthClasses () {
      if (!this.range) {
        return {}
      } else {
        return {
          'month-is-range-start': this.month.isSame(this.range.start, 'month'),
          'month-is-range-end': this.month.isSame(this.range.end, 'month')
        }
      }
    }
  }
}
</script>
