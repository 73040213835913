var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("search-box", {
        attrs: { placeholder: "Where to?", results: _vm.results },
        on: {
          selected: _vm.selected,
          active: _vm.active,
          query: _vm.queryResults
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }