import { Controller } from "@hotwired/stimulus"
import { send } from "../../app/bridge";

const promptMessage =  'prompt-push-notifications'
const dismissMessage = 'dismiss-push-notifications'

export default class extends Controller {
  enable () {
    send({message: promptMessage})
  }
  dismiss () {
    send({message: dismissMessage})
  }
}
