<template lang="pug">
.property-photos.edit-property-form
  PrimaryPhoto(:photo="primaryPhoto")

  #photos-for-editing
    h4(v-if="photos.length > 0") All Photos
    .instructions(v-if="photos.length > 1")
      p.small(style="margin-top:-1rem") Put your best photos first by dragging and  dropping. The photo in the first position is your property&apos;s primary photo.
    draggable.thumbnails.grid.edge(v-model="photos" @end="savePositions")
      PhotoThumbnail(v-for="(photo, i) in photos"
                      :key="photo.id"
                      :photo="photo"
                      :showEditModal="() => showEditModal(i)")

  AddPhoto(:photoUploadUrl="photoUploadUrl"
           @update:photos="setPhotos")

  .edit-photo-img-description-modal.modal(ref="modal")
    .card.property-card(v-if="modalPhoto")
      .card-image
        .slick-arrow.slick-prev(:class='leftArrowClass' @click="navigateLeft")
          span
        img(:src="modalPhoto.small"
            width="100%"
            height="100%"
            alt="image_description"
            title="image_description")
        .slick-arrow.slick-next(:class='rightArrowClass' @click="navigateRight")
          span
      .card-content
        h4.text-left Image Description
        .input
          input.image-description(v-model='imageDescriptionText'
                                  placeholder='ex: Master bedroom balcony with forest views'
                                  maxlength="70"
                                  @blur="setCurrentImageDescription")
        p.text-right.character-count(:class="countClass") 
          | {{ descriptionCharCount }}/60 Characters
      .card-controls
        .grid.edge
          .cell.fill
            a.make-featured(v-if="modalPhotoIndex > 0"
                            href="#"
                            @click.prevent="makePrimary")
              | Make Featured Image
            a.delete-image(href="#" @click.prevent="askDeleteImage")
              | Delete Image
          .cell.fit
            a.text-close(href="#") Close
</template>

<script>
import clone from 'lodash/clone'
import draggable from 'vuedraggable'
import PrimaryPhoto from './PrimaryPhoto.vue'
import PhotoThumbnail from './PhotoThumbnail.vue'
import AddPhoto from './AddPhoto.vue'
import { savePhotoPosition, savePhotoDescription, deletePhoto } from './api.js'
import { openModal, closeModal } from "../core/modals"

export default {
  components: {
    PrimaryPhoto,
    PhotoThumbnail,
    AddPhoto,
    draggable
  },
  props: {
    propertyPhotos: { type: Object, required: true },
    photoUploadUrl: { type: String, required: true }
  },
  data () {
    return {
      photos: clone(this.propertyPhotos.photos),
      modalPhotoIndex: 0,
      imageDescriptionText: ''
    }
  },
  computed: {
    primaryPhoto () {
      if (this.photos.length > 0) {
        return this.photos[0]
      } else {
        return null
      }
    },
    modalPhoto () {
      return this.photos[this.modalPhotoIndex]
    },
    descriptionCharCount () {
      return this.imageDescriptionText.length
    },
    leftArrowClass () {
      return {
        unclickable: this.modalPhotoIndex === 0
      }
    },
    rightArrowClass () {
      return {
        unclickable: this.modalPhotoIndex === (this.photos.length - 1)
      }
    },
    countClass () {
      return { 'error': this.descriptionCharCount > 60 }
    }
  },
  methods: {
    savePositions (e) {
      const photoId = e.item.getAttribute('photoId')
      const url = `${this.photoUploadUrl}/${photoId}/prioritize`
      savePhotoPosition(url, e.newIndex)
    },
    setPhotos (photos) {
      this.photos = photos
    },
    setCurrentImageDescription () {
      // This is a workaround for vue 2's reactivity. We want to change
      // only one element of a reactive array, and only if its description
      // has changed. In this case we both update it on both the client
      // and backend.
      const descriptionText = this.imageDescriptionText.trim()
      const photos = this.photos.map((photo, i) => {
        if (i !== this.modalPhotoIndex ||
            photo.image_description === descriptionText ||
            (photo.image_description === null && !descriptionText.length)
        ) {
          return photo
        } else {
          savePhotoDescription(
            `${this.photoUploadUrl}/${photo.id}`,
            descriptionText || null
          )
          return {
            ...photo,
            image_description: this.imageDescriptionText
          }
        }
      })
      this.photos = photos
    },
    showEditModal (index) {
      this.modalPhotoIndex = index
      this.imageDescriptionText = this.modalPhoto.image_description || ''
      openModal(this.$refs.modal)
    },
    closeModal () {
      closeModal(this.$refs.modal)
    },
    async askDeleteImage () {
      const result = window.confirm('Are you sure you want to delete this photo?')
      if (result) {
        deletePhoto(
          `${this.photoUploadUrl}/${this.modalPhoto.id}`
        ).then(response => response.json()).then((json) => {
          this.photos = json.photos
          if (this.modalPhotoIndex >= this.photos.length) {
            this.modalPhotoIndex = this.photos.length - 1
          }
          this.closeModal()
        })
      }
    },
    async makePrimary () {
      const photoId = this.modalPhoto.id
      const url = `${this.photoUploadUrl}/${photoId}/prioritize`
      savePhotoPosition(url, 0)
        .then(response => response.json())
        .then(json => {
          this.photos = json.photos
          this.modalPhotoIndex = 0
        })
    },
    navigateLeft () {
      this.setCurrentImageDescription()
      if (this.modalPhotoIndex > 0) {
        this.modalPhotoIndex = this.modalPhotoIndex - 1
        this.imageDescriptionText = this.modalPhoto.image_description || ''
      }
    },
    navigateRight () {
      this.setCurrentImageDescription()
      if (this.modalPhotoIndex < (this.photos.length - 1)) {
        this.modalPhotoIndex = this.modalPhotoIndex + 1
        this.imageDescriptionText = this.modalPhoto.image_description || ''
      }
    }
  }
}
</script>
