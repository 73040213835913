ko.bindingHandlers.confirmClick =
  init: (element, valueAccessor, allBindings, viewModel) ->
    value = valueAccessor()
    message = ko.unwrap(value.message)
    click = value.click
    ko.applyBindingsToNode(element, {
      click: ->
        if (confirm(message))
          return click.apply(this, Array.prototype.slice.apply(arguments))
    }, viewModel)
