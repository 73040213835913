var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "primary-photo photo" },
      [
        _vm.photo
          ? [
              _c("h4", [_vm._v("Primary Photo")]),
              _c("img", {
                attrs: {
                  src: _vm.photo.big,
                  alt: _vm.photo.image_description,
                  title: _vm.photo.image_description
                }
              }),
              _c("br"),
              _c("br"),
              _c("br")
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }