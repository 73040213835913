function createTag(scriptSrc, callback) {
  var s = document.createElement('script');
  s.src = scriptSrc;
  s.onload = callback;
  document.head.appendChild(s);
}

class ScriptLoader {
  constructor(scriptUrl, promiseFunction) {
    this.scriptUrl = scriptUrl;
    this.promiseFunction = promiseFunction;
    this._promise = null;
  }
  get promise() {
    if (this._promise) { return this._promise };
    this._promise = new Promise((resolve, reject) => {
      createTag(this.scriptUrl, () => {
        resolve(this.promiseFunction && this.promiseFunction());
      });
    });
    return this._promise;
  }
  then(callback) {
    return this.promise.then(callback);
  }
}

export default ScriptLoader;
