<template lang='pug'>
.result-entries
  .cluster-map.map-port(ref='map-port')
  br
  br
</template>

<script>
import Vue from 'vue';
import ClusterMap from '../../maps/cluster-map';
import geoJson from '../../maps/geo-json';
import { expand } from '../../maps/columnar-json';
import twoFingerDrag from '../../maps/two-finger-drag';

export default {
  data () {
    return {
      pointData: {
        geo: geoJson.fromObjects([]),
        viewport: null
      },
      location: '',
      setLocation: null
    }
  },
  mounted () {
    this.clusterMap = new ClusterMap({element: this.$refs['map-port'], zoom: 1, center: [0.0, 0.0]})
    this.clusterMap.on('move', (map, data) => {
      const initialLocation = this.setLocation;

      this.captureLocation();

      if (initialLocation && this.setLocation && this.setLocation != initialLocation) {
        this.$emit('move', this.setLocation);
      }
    });
  },
  methods: {
    loadData (mapData, location) {
      const expandedMapData = this.expandMapData(mapData);
      this.pointData = this.parseResponse(expandedMapData);

      this.location = location;

      if (this.clusterMap.started) {
        this._updateMap();
      } else {
        this._initMap();
      }
    },
    parseResponse (json) {
      return {
        geo: geoJson.fromObjects(json.pointData),
        viewport: json.viewport
      }
    },
    captureLocation () {
      const {lng, lat, zoom} = this.clusterMap.location;

      const location = [zoom.toFixed(2), lat.toFixed(4), lng.toFixed(4)].join('/');
      this.setLocation = location;
    },
    toggleResize () {
      Vue.nextTick(() => this.clusterMap.resize());
    },
    _initMap () {
      const {geo, viewport} = this.pointData;
      let opts = {data: geo};
      const [zoom, lat, lng] = (this.location || '').split('/');
      if (lng && lat && zoom) {
        opts.center = [lng, lat];
        opts.zoom = zoom;
        opts.viewport = null;
      } else {
        opts.viewport = viewport && viewport.split(',');
      }
      this.setLocation = null;
      this.clusterMap.start(opts).then((map) => {
        this.captureLocation();
        twoFingerDrag(map);
        map.scrollZoom.disable();
        map.dragRotate.disable();
        map.touchZoomRotate.disableRotation();
      });
    },
    _updateMap () {
      const {geo, viewport} = this.pointData;
      const [zoom, lat, lng] = (this.location || '').split('/');
      this.setLocation = null;
      this.clusterMap.start({data: geo}).then((map) => {
        if (lng && lat && zoom) {
          this.clusterMap.updateView([lng, lat], zoom);
        } else if (viewport) {
          this.clusterMap.updateViewport(viewport && viewport.split(','));
        } else {
          this.clusterMap.fitPoints();
        }
        if (!map.isMoving() && !map.isZooming()) {
          this.captureLocation();
        }
      });
    },
    expandMapData (data) {
      const {rows, columns, ...expandedMapData} = data;
      expandedMapData.pointData = expand(data.rows, data.columns);
      return expandedMapData;
    }
  }
}
</script>
