<template lang='pug'>
  .input.calendar-input(style="pointer-events:none").hide
    label Start Date
    input(type='text'
          v-model='formattedDate'
          placeholder='Select a calendar day'
          @change="$emit('dateSelected', dateInput)")
</template>

<script>

export default {
  props: { selectedDate: { type: String } },
  data () {
    return { dateInput: this.selectedDate }
  },
  computed: {
    formattedDate () {
      if (!this.dateInput) {
        return ''
      }
      return dayjs(this.dateInput).format("ddd, MMM D, YYYY")
    }
  },
  watch: {
    selectedDate (newDate, oldDate) {
      this.dateInput = newDate;
    }
  }
}
</script>
