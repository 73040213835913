export const randomizeCoords = (lng, lat) => {
  const points = [getPoint(lng), getPoint(lat)]
  return points
}

const getPoint = (point) => {
  const digitSum = sumDigits(point)
  const offset = (digitSum % 20 - 10) * magnitude

  return parseFloat(point) + offset
}

const magnitude = Math.pow(10, -4); // .0001

const sumDigits = (point) => {
  return point
    .replace(/\.|\-/g, "")
    .split("")
    .map(n => parseInt(n))
    .reduce((sum, v) => sum + v, 0)
}


