function isOS() {
  return navigator.userAgent.match(/ipad|iphone/i);
}

function createTextArea(text) {
  var textArea = document.createElement('textArea');
  textArea.value = text;
  textArea.readOnly = true;
  document.body.appendChild(textArea);
  return textArea;
}

function selectText(element) {
  if (isOS()) {
    let range,
        selection;
    range = document.createRange();
    range.selectNodeContents(element);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    element.setSelectionRange(0, 999999);
  } else {
    element.select();
  }
  document.execCommand('copy');
}

function removeElement(element) {
  document.body.removeChild(element);
}

function copy(copyText) {
  let element = createTextArea(copyText);
  selectText(element);
  removeElement(element);
}

export default copy;
