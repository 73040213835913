import { Controller } from "@hotwired/stimulus";
const maxBedroomCount = 25

export default class extends Controller {
 static targets = ['template', 'count', 'bedroomSelector', 'selectorContainer']

  connect() {
    this.assignTemplate()
    this.updateCount()
  }

  updateCount() {
    // If a non-numeric is entered or user backspaces, set to 0 rather than empty
    const countInput = parseInt(this.countTarget.value) || 0
    this.countTarget.value = Math.min(countInput, maxBedroomCount)
    this.bedroomCount = this.countTarget.value
    this.updateContainers()
  }

  updateContainers() { 
    while (this.bedroomCount > this.bedroomSelectorTargets.length) {
      this.selectorContainerTarget.insertAdjacentHTML('beforeend', this.selectorTemplate)
    }
    while (this.bedroomCount < this.bedroomSelectorTargets.length) {
      this.bedroomSelectorTargets.at(-1).remove()
    }
  }

  assignTemplate() {
    this.selectorTemplate = this.templateTarget.innerHTML
  }
}