var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { attrs: { href: _vm.notificationLink } }, [
    _c(
      "div",
      {
        staticClass: "card white-card small-card notification",
        class: _vm.readClass
      },
      [
        _c("div", { staticClass: "card-content" }, [
          _c("div", { staticClass: "grid" }, [
            _c(
              "div",
              { staticClass: "cell icon fit collapse collapse-padding" },
              [_c("span", { class: _vm.selectIcon })]
            ),
            _c("div", { staticClass: "cell fill collapse" }, [
              _c(
                "div",
                { staticClass: "grid collapse-padding notification-header" },
                [
                  _c("div", { staticClass: "cell fill collapse" }, [
                    _vm._v(
                      _vm._s(this.formatCategory(_vm.notification.category))
                    )
                  ]),
                  _c("div", { staticClass: "cell fit text-right" }, [
                    _vm._v(_vm._s(this.formatDate(_vm.notification.created_at)))
                  ])
                ]
              ),
              _c("div", { staticClass: "notification-content" }, [
                _vm._v(_vm._s(_vm.notification.message))
              ]),
              _vm.hasLinkMessage
                ? _c("div", { staticClass: "notification-action underline" }, [
                    _vm._v(_vm._s(_vm.notification.link_message))
                  ])
                : _vm._e()
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }