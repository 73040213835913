import { Controller } from "@hotwired/stimulus";
import SingleMap from '../../maps/single-map';
import geoJson from '../../maps/geo-json';

export default class extends Controller {
  static values = {
    lng: String,
    lat: String,
    zoom: String,
    mapMode: String,
    centerLat: String,
    centerLng: String,
    highContrastStyles: Boolean
  }
  static targets = ['update']
  initialize () {
    this.mapData = this._buildGeoJson()
    let startingPoint
    if (this.centerLngValue && this.centerLatValue) {
      startingPoint = [this.centerLngValue, this.centerLatValue]
    } else if (this.lngValue && this.latValue) {
      startingPoint = [this.lngValue, this.latValue]
    } else {
      startingPoint = [0, 0]
    }

    this.singleMap = new SingleMap({
      element: this.element,
      center: startingPoint,
      zoom: this.zoomValue || 1,
      data: this.mapData,
      highContrastStyles: this.highContrastStylesValue
    });
    this.singleMap.on('move', (_, location) => this.updateLocation(location))
  }

  connect () {
    this.singleMap.start();
  }

  disconnect () {
    this.singleMap.remove();
  }

  updateLocation(location) {
    const {lng, lat, zoom} = location
    if (lng) this.centerLngValue = lng
    if (lat) this.centerLatValue = lat
    if (zoom) this.zoomValue = zoom
  }
  updatePoint(location) {
    const {lng, lat, zoom} = location
    if (lng) this.lngValue = lng
    if (lat) this.latValue = lat
    if (zoom) this.zoomValue = zoom
    this.mapData = this._buildGeoJson()
    this.singleMap.updateData(this.mapData)
  }
  updateTargetConnected(element) {
    const json = JSON.parse(element.innerText)
    this.updatePoint(json)
    element.remove()
  }
  _buildGeoJson() {
    const data = {
      lat: this.latValue,
      lng: this.lngValue,
      map_mode: this.mapModeValue || "exact"
    }
    return geoJson.fromObjects([data]);
  }
}
