function formatDateRange(startDate, endDate) {
  startDate = dayjs(startDate);
  endDate = dayjs(endDate);

  if (!(startDate.isValid() && endDate.isValid())) return '';

  if (startDate.year() === endDate.year() && startDate.month() === endDate.month()) {
    return startDate.format(`MMM D-${endDate.format("D")}, YYYY`);
  } else if (startDate.year() === endDate.year()) {
    return `${startDate.format("MMM D")} - ${endDate.format("MMM D, YYYY")}`;
  } else {
    return `${startDate.format("MMM D, YYYY")} - ${endDate.format("MMM D, YYYY")}`;
  }
};

function formatFullDate(date) {
  date = dayjs(date);
  if (!date.isValid()) return '';

  return date.format("ddd MMM D, YYYY");
};


export default {
  formatDateRange: formatDateRange,
  formatFullDate: formatFullDate
}
