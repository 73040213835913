import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["display", "field" , "increase", "decrease"]

  // Both connected callbacks check for both to be connected before setting initial page values
  fieldTargetConnected() {
    if (this.hasDisplayTarget) { this._setDefaults() }
  }

  displayTargetConnected() {
    if (this.hasFieldTarget) { this._setDefaults() }
  }

  // Click -
  decrement() {
    let newVal = this.currentValue - 1
    if (this.min || this.min === 0) {
      newVal = Math.max(newVal, this.min)
    }
    this._updateValues(newVal)
  }

  // Click +
  increment() {
    let newVal = this.currentValue + 1
    if (this.max) {
      newVal = Math.min(newVal, this.max)
    }
    this._updateValues(newVal)
  }
  
  // Parse form values as integers and store on controller on page load
  _setDefaults() {
    if (this.fieldTarget.min) { this.min = parseInt(this.fieldTarget.min) }
    if (this.fieldTarget.max) { this.max = parseInt(this.fieldTarget.max) }
    const defaultValue = (parseInt(this.fieldTarget.value) || this.min)
    this._updateValues(defaultValue)
  }

  // Update controller-level value, update form and display div number
  _updateValues(value) {
    this.currentValue = value
    this.fieldTarget.value = this.currentValue
    this.displayTarget.textContent = this.currentValue
    this._disableButtons()
  }
  
  // Disable - button when at min, + button when at max
  _disableButtons() {
    this.increaseTarget.classList.toggle('disabled', (this.currentValue === this.max))
    this.decreaseTarget.classList.toggle('disabled', (this.currentValue === this.min))
  }
}
