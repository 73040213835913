<template lang='pug'>
div
  search-box(
    :placeholder="placeholderName"
    :propertySearch="propertySearch" @selected='loadPlace'
    :defaultPlaceHolder="defaultPlaceHolder" :results="results"
    @query="queryResults"
  )
  a.search-choice-close.input-clear(:class="{ hide: hideClose }"
                        @click="clearWhereToText")
    .close
      .x
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import { navigate } from '../navigator';
import SearchBox from './SearchBox.vue'
import AutocompleteSearch from './autocomplete_search'
import ZonesSource from './sources/zones'
import placesSource from './sources/places'
import keywordSource from './sources/keyword'
import PropertiesSource from './sources/properties';

export default {
  props: {
    // Used for updating the location name in the placeholder.
    // categoryNameSource: {required: true},
    zonesUrl: {required: true},
    propertyUrl: {type: String }
  },
  components: { SearchBox },
  created () {
    const zonesSource = new ZonesSource(this.zonesUrl);
    const propertiesSource = new PropertiesSource(this.propertyUrl);
    const sources = [propertiesSource, zonesSource, placesSource, keywordSource];
    this.autocompleteSearch = new AutocompleteSearch(sources);
  },
  data() {
    return {
      locationName: '',
      propertySearch: false,
      defaultPlaceHolder: "WHERE TO?",
      results: []
    }
  },
  computed: {
    ...sync('browseHomes', {
      destination: 'destination',
      word_search: 'filters@word_search'
    }),
    placeholderName () {
      if (this.destination) {
        return this.destination.name
      } else if (this.word_search.length > 0) {
        return `"${this.word_search}"`
      } else {
        return this.defaultPlaceHolder
      }
    },
    hideClose () {
      return this.destination === null && this.word_search.length === 0
    }
  },
  methods: {
    updateSearchLocation: call('browseHomes/updateSearchLocation'),
    clearDestination: call('browseHomes/clearDestination'),
    fetch: call('browseHomes/fetch'),
    queryResults (inputString) {
      if (inputString) {
        this.autocompleteSearch.search(inputString, (err, results) => {
          if (err) return this.$emit('error', err);
          this.results = results;
        });
      } else {
        this.clearResults();
      }
    },
    clearResults () {
      this.results = [];
    },
    clearWhereToText () {
      if (this.destination !== null) {
        this.clearDestination()
      } else if (this.word_search.length > 0) {
        this.word_search = ""
        this.fetch()
      }
    },
    loadPlace (data) {
      if (data.type == 'property') navigate(data.path);
      this.locationName = data.description;
      this.updateSearchLocation(data)
    }
  }
}
</script>
