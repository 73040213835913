<template lang="pug">
.vue-template-wrapper.stay-request-calendar-list
  .grid.stay-request-title.align-center.edge
    .cell.twelve.medium-fill
      h4.no-bottom-margin Select Your Dates
    .cell.twelve.medium-fit.medium-text-right
      button.button.small(:disabled='!selectionStart' @click.prevent='clearSelection' style="margin-bottom:.25rem;margin-top:.25rem;") Clear
  .grid.edge.stay-request-legend
    .cell.fill
      div.legend-item.unavailable
        div.icon
        span.description Unavailable
      div.legend-item.available
        div.icon
        span.description Available Stay
      div.legend-item.selected
        div.icon
        span.description Selected
  br
  .grid.edge.calendar-build(:class='calendarClass' v-touch:swipe.right='previous', v-touch:swipe.left='next')
    calendar-month(:month='firstMonth', :previous='previous', :next='next')
    calendar-month(:month='secondMonth', :previous='previous', :next='next')
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CalendarMonth from './CalendarMonth.vue'

export default {
  components: {
    CalendarMonth
  },
  props: {
    monthRange: {type: Object, required: true},
    weekRequestCalendar: {type: Array, required: true},
    startingMonth: { type: Object, default: null }
  },
  data () {
    return {
      firstMonth: dayjs(this.monthRange.start)
    }
  },
  computed: {
    ...mapState('calendars/stay-request', ['range']),
    ...mapGetters('calendars/stay-request', ['hoverStayRange', 'stayRange', 'selectionStart']),
    secondMonth () {
      return dayjs(this.firstMonth).add(1, 'month')
    },
    calendarClass () {
      return {
        'no-selection': !this.selectionStart,
        'selection-started': this.selectionStart && !this.stayRange,
        'selection-complete': this.stayRange
      }
    }
  },
  mounted () {
    this.initialize({
      range: this.monthRange,
      calendar: this.weekRequestCalendar
    })
    if (this.startingMonth) {
      this.firstMonth = this.startingMonth
    }
  },
  methods: {
    ...mapActions('calendars/stay-request', ['initialize', 'clearSelection']),
    previous () {
      const month = dayjs(this.firstMonth).subtract(1, 'month')
      if (this.range.contains(month)) {
        this.firstMonth = month
      }
    },
    next () {
      const month = dayjs(this.firstMonth).add(1, 'month')
      if (this.range.contains(month)) {
        this.firstMonth = month
      }
    }
  }
}
</script>
