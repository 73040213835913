<template lang="pug">
.cell.twelve.medium-four.medium-order-2
  h5.no-bottom-margin Property Type
  .input.check_boxes
    span.checkbox
      input(type="checkbox" v-model="affiliate" id="affiliate")
      label(for="affiliate") Affiliate Resorts
    span.checkbox
      input(type="checkbox" v-model="boutique_hotel" id="boutique")
      label(for="boutique") Boutique Hotels
    span.checkbox
      input(type="checkbox" v-model="estate" id="estate")
      label(for="estate") Estate
    span.checkbox
      input(type="checkbox" v-model="private_residence" id="private")
      label(for="private") Private Residence
    span.checkbox
      input(type="checkbox" v-model="reserve" id="reserve")
      label(for="reserve") Reserve Tier
    span.checkbox(v-show="showYpoFilter")
      input(type="checkbox" v-model="ypo" id="ypo")
      label(for="ypo") Executive Tier
    span.checkbox.option-disabled(v-show="showYpoOnly")
      input(type="checkbox" v-model="ypo" id="ypo" disabled)
      label(for="ypo") Executive Tier
    span.checkbox
      input(type="checkbox" v-model="yacht" id="yacht")
      label(for="yacht") Yacht
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  props: {
    ypoUser: { type: Boolean, default: false},
    ypoOnly: { type: Boolean, default: false }
  },
  computed: {
    ...sync('browseHomes', {
      affiliate: 'filters@affiliate',
      boutique_hotel: 'filters@boutique_hotel',
      estate: 'filters@estate',
      private_residence: 'filters@private_residence',
      reserve: 'filters@reserve',
      yacht: 'filters@yacht',
      ypo: 'filters@ypo'
     }),
     showYpoOnly() {
      return this.ypoUser && this.ypoOnly
     },
     showYpoFilter() {
      return this.ypoUser && !this.ypoOnly
     }
  },
}
</script>
