var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stay-options medium-order-2" }, [
    _c("h5", { staticClass: "no-bottom-margin" }, [_vm._v("Stay Options")]),
    _c("div", { staticClass: "input check_boxes" }, [
      _c("span", { staticClass: "checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.handicap_accessible,
              expression: "handicap_accessible"
            }
          ],
          attrs: { type: "checkbox", id: "handicap" },
          domProps: {
            checked: Array.isArray(_vm.handicap_accessible)
              ? _vm._i(_vm.handicap_accessible, null) > -1
              : _vm.handicap_accessible
          },
          on: {
            change: function($event) {
              var $$a = _vm.handicap_accessible,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.handicap_accessible = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.handicap_accessible = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.handicap_accessible = $$c
              }
            }
          }
        }),
        _c("label", { attrs: { for: "handicap" } }, [
          _vm._v("Handicap Accessible")
        ])
      ]),
      _c("span", { staticClass: "checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.pet_friendly,
              expression: "pet_friendly"
            }
          ],
          attrs: { type: "checkbox", id: "pets" },
          domProps: {
            checked: Array.isArray(_vm.pet_friendly)
              ? _vm._i(_vm.pet_friendly, null) > -1
              : _vm.pet_friendly
          },
          on: {
            change: function($event) {
              var $$a = _vm.pet_friendly,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.pet_friendly = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.pet_friendly = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.pet_friendly = $$c
              }
            }
          }
        }),
        _c("label", { attrs: { for: "pets" } }, [_vm._v("Pet Friendly")])
      ]),
      _c("span", { staticClass: "checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.requestable,
              expression: "requestable"
            }
          ],
          attrs: { type: "checkbox", id: "week-requests" },
          domProps: {
            checked: Array.isArray(_vm.requestable)
              ? _vm._i(_vm.requestable, null) > -1
              : _vm.requestable
          },
          on: {
            change: function($event) {
              var $$a = _vm.requestable,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.requestable = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.requestable = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.requestable = $$c
              }
            }
          }
        }),
        _c("label", { attrs: { for: "week-requests" } }, [
          _vm._v("Accepts Week Requests")
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }