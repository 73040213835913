var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result-entries" }, [
    _c("div", { ref: "map-port", staticClass: "cluster-map map-port" }),
    _c("br"),
    _c("br")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }