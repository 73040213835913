class PasswordValidator
  constructor: (score, length) ->
    @score     = parseInt(score)
    @length    = parseInt(length)
  valid: ->
    @_longEnough() && @_strongEnough()
  message: ->
    if !@_longEnough()
      'Your password must be 8 characters or more'
    else if !@_strongEnough()
      'Your password needs to be stronger'

  # internal
  _longEnough: ->
    @length >= 8
  _strongEnough: ->
    @score >= 2

export default PasswordValidator;
