var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-template-wrapper" }, [
    _c("div", { staticClass: "row-error" }),
    _c("div", { staticClass: "grid confirm-deposits-row edge" }, [
      !_vm.autoAddDeposits
        ? _c(
            "div",
            { staticClass: "cell twelve medium-four order-2 medium-order-1" },
            [
              _c(
                "button",
                {
                  staticClass: "button full",
                  attrs: {
                    disabled: !_vm.optionSelected,
                    "data-title": "Add weeks",
                    "data-intro":
                      "Click and select another start date or continue and confirm added weeks.",
                    "data-step": "4"
                  },
                  on: { click: _vm.addDeposit }
                },
                [_vm._v("Add and Continue")]
              )
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.autoAddDeposits,
              expression: "!autoAddDeposits"
            }
          ],
          staticClass: "cell twelve medium-eight order-1 medium-order-2",
          attrs: {
            "data-title": "See how many keys the week you are adding is worth",
            "data-intro":
              "Key values are based on seasonality and color coded on your calendar",
            "data-step": "3"
          }
        },
        [
          _c("DepositOptions", {
            attrs: {
              selectedDate: _vm.selectedDate,
              selectedDeposits: _vm.selectedDeposits,
              optionUrl: _vm.optionUrl
            },
            on: { stayPicked: _vm.setStay, error: _vm.handleError }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "cell twelve medium-four hide order-3" },
        [
          _c("DateInput", {
            attrs: { selectedDate: _vm.selectedDate },
            on: { dateSelected: _vm.dateSelected }
          })
        ],
        1
      ),
      _c("div", { staticClass: "cell twelve medium-eight order-4" }, [
        _c("p", { staticClass: "error-message" }, [
          _vm._v(_vm._s(_vm.errorMessage))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }