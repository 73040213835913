import PasswordAttributes from '../../new_strong_password/password_attributes';
import PasswordValidator from '../../new_strong_password/password_validator';
import intlTelInput from 'intl-tel-input'
import utilsScript from 'intl-tel-input/build/js/utils.js'
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["tel-input"]

  connect() {
    this.pwAttr = new PasswordAttributes()
  }

  submit(e) {
    e.preventDefault()

    let pwCheck = new PasswordValidator(
      this.pwAttr.score,
      this.pwAttr.length
    )

    if (!pwCheck.valid()) {
      return false
    }

    this.telInputOutlet.submit()

    const button = this.element.querySelector('.submit input')
    button.disabled = true
    button.value = button.dataset.processing || "Processing..."

    this.element.submit()
  }
}
