import log from '../tools/log';

class RunQueue {
  constructor(runOnce = false) {
    this.collection = [];
    this.runOnce = runOnce;
    this.ran = false;
  }

  add(callback) {
    if (this.runOnce && this.ran) {
      callback();
    } else {
      this.collection.push(callback);
    }
  }

  run() {
    if (this.runOnce && this.ran) { return }
    this.ran = true;
    this.collection.forEach((callback) => this._runCallback(callback));
  }

  _runCallback(callback) {
    try {
      callback();
    } catch (e) {
      log("EventQueue error:", e);
    }
  }
}

export default RunQueue;
