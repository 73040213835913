import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["form", "select"]

    // connect() {}

    
    submit(event) {
        this.formTarget.requestSubmit()
    }
}