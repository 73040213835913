function twoFingerDrag (map) {
  map.on('touchstart', event => {
    const element = map.getContainer();
    map.dragPan.disable();
    const e = event.originalEvent;
    if (e && e.touches) {
      if (e.touches.length > 1) {
        map.dragPan.enable();
        element.classList.remove('touch-drag-guidance');
      } else {
        element.classList.add('touch-drag-guidance');
      }
    }
  });

  map.on('touchend', event => {
    const element = map.getContainer();
    map.dragPan.disable();
    element.classList.remove('touch-drag-guidance');
  });
}

export default twoFingerDrag;
