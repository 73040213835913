var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 24 24" },
      attrs: {
        version: "1.1",
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 24 24",
        "xml:space": "preserve"
      }
    },
    [
      _c("g", { attrs: { id: "XMLID_23_" } }, [
        _c("path", {
          staticClass: "st0",
          attrs: {
            id: "XMLID_1304_",
            d:
              "M22,20.5H2c-0.8,0-1.5-0.7-1.5-1.5V8c0-0.8,0.7-1.5,1.5-1.5h20c0.8,0,1.5,0.7,1.5,1.5v11\n    C23.5,19.8,22.8,20.5,22,20.5z"
          }
        }),
        _vm._v(" "),
        _c("circle", {
          staticClass: "st0",
          attrs: { id: "XMLID_1303_", cx: "12", cy: "2", r: "0.5" }
        }),
        _vm._v(" "),
        _c("polyline", {
          staticClass: "st0",
          attrs: { id: "XMLID_1302_", points: "7.5,6.5 12,2 16.5,6.5  " }
        })
      ]),
      _vm._v(" "),
      _c("g", { attrs: { id: "XMLID_1397_" } }, [
        _c("path", {
          staticClass: "st0",
          attrs: {
            id: "XMLID_1391_",
            d: "M5,16v-4.7h1.2c0.6,0,1.2,0.5,1.2,1.2V16"
          }
        }),
        _vm._v(" "),
        _c("polyline", {
          staticClass: "st0",
          attrs: {
            id: "XMLID_1392_",
            points: "12,11.4 9.7,11.4 9.7,16 12,16  "
          }
        }),
        _vm._v(" "),
        _c("line", {
          staticClass: "st0",
          attrs: {
            id: "XMLID_1393_",
            x1: "9.7",
            y1: "13.7",
            x2: "11.5",
            y2: "13.7"
          }
        }),
        _vm._v(" "),
        _c("polyline", {
          staticClass: "st0",
          attrs: {
            id: "XMLID_1390_",
            points: "14.4,11.4 14.4,14.3 15.5,16 16.7,14.3 16.7,11.4  "
          }
        }),
        _vm._v(" "),
        _c("polyline", {
          staticClass: "st0",
          attrs: {
            id: "XMLID_1395_",
            points: "16.7,11.4 16.7,14.3 17.9,16 19,14.3 19,11.4  "
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }