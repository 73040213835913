import system from './system';
import log from '../tools/log';

const activePageLoads = [];
window.activePageLoads = activePageLoads;

function loadPage(pageLoad) {
  activePageLoads.push(pageLoad);
}

function hasClass(element, className) {
  if (element.classList) {
    return element.classList.contains(className);
  } else {
    return element.className.indexOf(className) !== -1;
  }
};

function listenForPageLoad(pageLoad) {
  system.onLoad(function(e) {
    if (hasClass(document.body, pageLoad.pageName)) {
      pageLoad.load();
    }
  });
};

window.addEventListener('resize', function () {
  activePageLoads.forEach(function (pageLoad) { pageLoad.resize() });
});

document.addEventListener('scroll', function () {
  activePageLoads.forEach(function (pageLoad) { pageLoad.scroll() });
});

function unloadAll() {
  const unloadingPages = [];
  while(activePageLoads.length) {
    unloadingPages.push(activePageLoads.shift());
  }
  unloadingPages.forEach(function (pageLoad) { pageLoad.unload() });
}

system.onUnload(unloadAll);

class PageLoad {
  constructor(pageName, options) {
    this.pageName = pageName;
    this.loader = options.load;
    this.unloader = options.unload;
    this.resizer = options.resize;
    this.scroller = options.scroll;
    this.methods = options.methods;
    this.state = null;
    listenForPageLoad(this);
  }
  newState() {
    if (this.methods) {
      return Object.create(this.methods);
    } else {
      return {};
    }
  }
  load() {
    this.state = this.newState();
    this._runCallback(this.loader, this.state);
    loadPage(this);
  }
  unload() {
    try {
      let state = this.state;
      this._runCallback(this.unloader, state);
    } finally {
      this.state = null;
    }
  }
  scroll() {
    this._runCallback(this.scroller, this.state);
  }
  resize() {
    this._runCallback(this.resizer, this.state);
  }
  _runCallback(callback, state) {
    if (callback) {
      try {
        callback.apply(state, [state]);
      } catch (error) {
        log(`Caught error in ${this.pageName} callback:`, error);
        throw error;
      }
    }
  }
}

export default PageLoad;
