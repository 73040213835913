var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "saved-search-list" },
    [
      _vm._l(_vm.searchesDisplay, function(search) {
        return _c(
          "li",
          {
            key: search.id,
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.selectSearch(search)
              }
            }
          },
          [_c("div", { staticClass: "name" }, [_vm._v(_vm._s(search.summary))])]
        )
      }),
      _vm._m(0)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "/account/searches" } }, [
        _vm._v("Manage Saved Searches")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }