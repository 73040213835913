<template lang="pug">
.stay-request-options
  .grid(v-if='notEnoughKeys')
    .cell.twelve.stay-request-error
      p You do not have enough available Keys (
        | {{ quote.keys }} 
        span.third-font-key-glyph
        |  needed) to make this request. 
      p(v-if='depositUrl')
        a(:href='depositUrl')
          | Consider depositing more Weeks to earn Keys.
  .grid(v-else-if='error !== null')
    .cell.twelve.stay-request-error
      strong.request-error {{ error.message }}

  .grid.collapse-padding.medium-show
    .cell.fill Dates
    .cell.two Season
    .cell.two Fee
    .cell.key-column Keys
    .cell.one Guests
    .cell.one
        
  .grid.align-center.collapse-padding.week-meta
    .cell.twelve.medium-fill(v-if='!selectionStart')
      | Please select a check in date.
    .cell.twelve.medium-fill(v-else-if='selectionStart && !stayRange')
      | Please select check out date up to {{ lastDateLabel }}.
    .cell.twelve.medium-fill(v-else-if='quote')
      span.stay-label Selected Stay
      | {{ quote.dates }}
      span.night-count-summary ({{ stayDuration }}
        span.third-font-nights-glyph
        | )
    .cell.twelve.medium-fill(v-else)

    .cell.six.medium-two
      span.stay-label Season
      template(v-if="validQuoteState")
        | {{ seasonText(quote.season) }}
      template(v-else)
        | --

    .cell.six.medium-two
      span.stay-label Fee
      template(v-if="validQuoteState")
        | {{ formatFee(quote.fee) }}
      template(v-else)
        | --

    .cell.six.key-column
      span.stay-label Keys Offered
      .key-count-select.input
        select.no-bottom-margin(v-model='keyCount' :disabled='!validQuoteState')
          option(v-if="validQuoteState"
                  v-for='n in keyRange()'
                  :value='n') {{ n }}
        span.third-font-key-glyph

    .cell.six.medium-one
      span.stay-label Guests
      .input
        select.no-bottom-margin(v-model='guestCount' :disabled='!validQuoteState')
          option(v-if="validQuoteState"
                  v-for='n in occupancyRange()'
                  :value='n') {{ n }}

    .cell.twelve.medium-one.text-right
      a.small.underline(href="#" @click.prevent='clearSelection') remove
  br

  .grid
    .cell.twelve.medium-eight.order-2.medium-order-1
      h5 Add Personalized Message (OPTIONAL)
      p.body
        | Please add a message with any information you would like to send the property owner. Letting them know the reason for a request, such as birthdays or anniversaries, will add a personal touch to the request.
        br
        small 
          em  
            strong Please note: 
            | To protect our club environment members are prohibited from arranging stays outside of the #{third_home} platform and messages are regularly moderated.
    .cell.twelve.medium-four.order-1.medium-order-2.property-sidebar
      template(v-show="profileCard.display_profile_card")
        div
        .property-profile.property-sidebar.card.white-card.sidebar-owner-bio.no-hover
          .card-content
            .grid.edge
              .cell.fit.collapse-padding
                .bio-pic(v-if="profileCard.display_bio_pic")
                  img(:src="profileCard.details.bio_pic")
                .bio-pic.initials(v-else)
                  | {{ profileCard.details.user_initials }}
              .cell.fill.collapse-padding(style="margin-right:0;")
                ul.none(style="margin-bottom:0")
                  li
                    strong {{ profileCard.details.public_name }}
                  li.small.strong(v-if="profileCard.display_response_rate_percentage")
                    span
                      | Host Response Rate:
                    span(:class="profileCard.details.score_color")
                      | {{ profileCard.details.score }}
                  li.small(v-if="profileCard.display_city_state")
                    span.third-font-map-marker-glyph
                    span {{ profileCard.details.location }}
                  li.member-since.small
                    span.third-font-user-glyph
                    span {{ profileCard.details.member_since }}
                  li.small
                    span.third-font-bed-glyph
                    span {{ profileCard.details.property_count }}



  .grid
    .cell.twelve.medium-eight.text-area
      textarea.text-area(v-model='message' placeholder='Your personal message...' rows='4')
  br
  .grid
    .cell.twelve.auto.submit-request
      button(type='button'
             class='button'
             :disabled="disableButton"
             @click.prevent="showModal")
        | Submit Request

  br
  br
  br

  // TODO: Update modal to new styles with whole page.
  #submit-stay-request.modal(ref="weekRequestModal" role="dialog")
    .card.modal-card(role="document")
        .modal-header
          h4 REQUEST A STAY
          .close(aria-label="close")
            .x
        .modal-content
          template(v-if='error === null')
            p 
              | IMPORTANT: Only request dates you are certain you will use.
              br
              | 1. If your request is accepted by the owner, it will be a binding reservation.
              br
              | 2. When you submit the request, the keys will automatically be deducted from your account.
              br
              | 3. If the request is accepted by the owner, you will be required to complete your reservation by paying your exchange fee.
            .submit.text-center
              a.button.submit-new-week-request(:disabled="submitting"
                                              @click.prevent='submitRequest')
                | Submit Request

          template(v-else)
            p.error-message {{ error.message }}
            .refresh.text-center
              a.button.refresh-page-on-error(@click.prevent='refreshPage')
                | Refresh Page
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import range from 'lodash/range'
import {
  SET_OPTIONS,
  SET_URLS
} from '../vuex-store/calendars/mutation-types.js'
import { openModal } from '../core/modals'

const seasonLabel = {
  'super_peak': 'Super Peak',
  'peak': 'Peak',
  'non_peak': 'Non Peak',
  'off_season': 'Off Season'
}

export default {
  props: {
    quoteUrl: { type: String, required: true },
    submitUrl: { type: String, required: true },
    freeKeyCount: { type: Number, required: true },
    depositUrl: { type: String, default: null },
    maxOccupancy: { type: Number, required: true },
    keyMultiplier: { type: Number, required: true },
    profileCard: { type: Object, required: true }
  },
  data () {
    return {
      submitting: false
    }
  },
  computed: {
    ...mapState('calendars/stay-request', ['options', 'quote', 'error']),
    ...mapGetters('calendars/stay-request', ['stayRange', 'selectionStart', 'lastValidEndDate']),
    // todo - refactor with vuex-pathify
    keyCount: {
      get () { return this.options.keys },
      set (keys) {
        this.setOptions({ keys })
        this.fetchQuote()
      }
    },
    guestCount: {
      get () { return this.options.guests },
      set (guests) { this.setOptions({ guests }) }
    },
    message: {
      get () { return this.options.message },
      set (message) { this.setOptions({ message }) }
    },
    stayDuration () {
      if (this.stayRange) {
        return this.stayRange.diff('days', { exclusive: true })
      } else {
        return ''
      }
    },
    disableButton () {
      return this.stayRange === null ||
             this.quote === null ||
             this.error !== null ||
             this.notEnoughKeys
    },
    notEnoughKeys () {
      if (this.quote && this.quote.keys) {
        return this.freeKeyCount < this.options.keys
      } else {
        return false
      }
    },
    lastDateLabel () {
      if (this.lastValidEndDate) {
        return this.lastValidEndDate.format('MMM Do')
      }
    },
    validQuoteState () {
      return this.quote !== null && !this.error && !this.notEnoughKeys
    },
  },
  mounted () {
    document.body.classList.add('property-profile');
    this.setUrls({
      quote: this.quoteUrl,
      submit: this.submitUrl
    })
    this.setOptions({ keyMultiplier: this.keyMultiplier})
  },
  methods: {
    ...mapActions('calendars/stay-request',
                  ['fetchQuote', 'clearSelection', 'submitStayRequest']
    ),
    ...mapMutations('calendars/stay-request', {
      setOptions: SET_OPTIONS,
      setUrls: SET_URLS
    }),
    formatFee (fee) {
      return Number(fee).toLocaleString(
        'en-US', { style: 'currency', currency: 'USD' }
      );
    },
    seasonText (season) {
      return seasonLabel[season]
    },
    submitRequest () {
      if (!this.submitting) {
        this.submitting = true
        this.submitStayRequest()
      }
    },
    keyRange () {
      const minKeys = this.quote && this.quote.keys || 3
      if (minKeys <= this.freeKeyCount) {
        return range(minKeys, this.freeKeyCount + 1)
      } else {
        return []
      }
    },
    occupancyRange () {
      return range(1, this.maxOccupancy + 1)
    },
    showModal () {
      openModal(this.$refs.weekRequestModal)
    },
    refreshPage () {
      window.location.reload()
    }
  }
}
</script>