<template lang='pug'>
.deposit
  .grid.align-middle.edge
    .cell.date-range.twelve(v-bind:class="{'shrink': requireConfirmationIds}", style="margin-bottom:.25rem")
      span.starts-on.date {{formattedRange.formattedStart}}
      |
      | to
      |
      span.ends-on.date {{formattedRange.formattedEnd}}
      input(type='hidden', name='dates[]', v-model='deposit.identifier')
    
    .cell.key-count-column.fill
      span(v-if="hasKeyPriceOverride()")
        | {{key_price_override}}
      span(v-else)
        | {{deposit.keys}}
      | &nbsp;
      span.third-font-key-glyph
      span.tipso_styles.asterisk(v-if='showTipso',
           data-tipso="You will not earn upfront keys for this week. You'll earn keys once it's booked.")
        | &#10033;
    .cell.fit.actions.text-right(v-bind:class="{'small-shrink medium-12': requireConfirmationIds, 'shrink': !requireConfirmationIds}")
      a.small.remove-booking.underline(href='#', @click.prevent="$emit('removeDeposit', id)")
        | remove
    .cell.twelve.resort-confirmation(v-if="requireConfirmationIds")
      .input(style="margin-top:.75rem")
        label 
          | Resort Reservation Confirmation Number* 
          span.info(data-tipso='Provided by your resort when you reserved the deposited week.') i
        input(type='text', :name="formatResortIdInputName()", v-model.trim="deposit.resort_confirmation_id")
        
  .grid.align-top.edge.name-your-price(v-if="canNamePrice")
    .cell.twelve
      .input.small.check_boxes(style="margin-bottom:0")
        span.checkbox
          input(type="checkbox"
                :id="deposit.identifier"
                :name="deposit.identifier"
                v-model="nameYourPrice" value="1")
          label(:for="deposit.identifier", :data-tipso="nameYourPriceTooltip" style="padding-left:0;margin-left:8px") Name Your Price
    .cell.fit(v-if="nameYourPrice")
      label New Price:
    .cell.fill(v-if="nameYourPrice")
      .input.small
        select(:name="formatKeyPriceOverrides()" class="select-box" v-model="key_price_override" style="margin-bottom:0")
          option(v-for="index in keyOptions" :key="index" :value="index")
            | {{index}}
      br

</template>

<script>
export default {
  props: {
    deposit: { type: Object, required: true },
    id: { type: String, required: true },
    requireConfirmationIds: { type: Boolean, required: true },
    ownerCanNyop: { type: Boolean, required: false }
  },
  data() {
    return {
      nameYourPrice: false,
      key_price_override: this.deposit.keys.toString()
    }
  },
  methods: {
    formatResortIdInputName () {
      return `confirmation_ids[${this.deposit.identifier}]`
    },
    formatKeyPriceOverrides () {
      return `key_price_overrides[${this.deposit.identifier}]`
    },
    hasKeyPriceOverride () {
      return this.nameYourPrice && this.key_price_override;
    }
  },
  computed: {
    canNamePrice () {
      if (!this.ownerCanNyop) return false 
      return ['peak', 'super_peak', 'non_peak', 'off_season'].includes(this.deposit.season)
    },
    showTipso () {
      return this.deposit.delayed_keys || this.nameYourPrice
    },
    formattedRange () {
      return formattedStayRange(this.deposit.starts_on, this.deposit.ends_on)
    },
    keyOptions () {
      let arry = []
      for (let i=this.deposit.keys; i <= this.maxKeys; i++) {
        arry.push(i)
      }
      return arry
    },
    maxKeys () {
      return Math.ceil(this.deposit.keys * 1.5)
    },
    nameYourPriceTooltip () {
      return "You can now choose to receive up to 50% more Keys for this stay.  However, if you elect to do so, you will only get the Keys when the stay is booked and it will not go Keyless."
    }
  }
}
</script>
