import { Controller } from "@hotwired/stimulus";
import { openModalSelector } from "../../core/modals"

export default class extends Controller {
  static values = {
    modalSelector: String
  }

  connect() {
    this.openTargetModal()
    this.element.remove()
  }

  openTargetModal() {
    openModalSelector(this.modalSelectorValue)
  }
}