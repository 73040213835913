const callbackLazy = (entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const image = entry.target
      loadImage(image)
      observer.unobserve(image)
    }
  })
}

function loadImage(image) {
  image.src = image.dataset.lazy
  image.classList.remove('lazy')
  if (image.dataset.srcset) {
    image.srcset = image.dataset.srcset
    image.sizes = image.dataset.sizes
  }
}

export const cannotLazy = (elements) => {
  elements.forEach((image) => {
    loadImage(image)
  })
}

export const initLazy = (elements, options) => {
  if (window.IntersectionObserver) {
    let observer = new IntersectionObserver(callbackLazy, options);

    elements.forEach((element) => {
      observer.observe(element)
    })

    return observer
  } else {
    console.log("IntersectionObserver not supported.")
    cannotLazy(elements)
  }
}
