<template lang='pug'>
  .modal(id='confirm-deposits', aria-labelledby="confirm-deposit-submission")
    form.card.modal-card(:action='formatConfirmUrl()', method='post', role="document")
      input(type='hidden' name="authenticity_token" :value="csrf")
      input(type='hidden' name='rental_wday' :value='rentalWday')
      .modal-header
        h4#confirm-deposit-submission Confirm deposit submission
        .close(aria-label="close")
          .x

      .modal-content
        p.small
          | Please verify and confirm the following dates are being deposited
          | to ThirdHome. Once accepted and reserved, these stays belong
          | to the Club and you will not be able to use them yourself or rent
          | them out through other means.
        .deposit-entry(v-for='(deposit, id) in selectedDeposits')
          DepositConfirmation(:deposit='deposit'
                              :id='id'
                              :requireConfirmationIds='requireConfirmationIds',
                              :ownerCanNyop='ownerCanNyop',
                              v-on:removeDeposit='removeDeposit')

        .agree-to-terms-container(style="padding:0 4px")
          .input.check_boxes(style="margin-bottom:0")
            span.checkbox
              input(type='checkbox'
                    name='agree_to_liability_for_reservation'
                    id='agree_to_liability_for_reservation'
                    v-model='checked'
                    value='1')
              label(for='agree_to_liability_for_reservation')
                p.small
                  | I agree to 
                  a(href="https://www.thirdhome.com/terms-and-conditions/" target="_blank") ThirdHome's Terms and Conditions
                  | , including that Host Members are not permitted to cancel reservations. 
                  | All costs incurred by the Guest due to cancelation are my responsibility as Host.
      .card-controls(style="padding:.5rem 2rem 1rem")
        input(type='submit'
              class='button small'
              value='Confirm Weeks'
              data-disable-with='Processing...'
              :disabled='confirmButtonDisabled')

</template>

<script>
import DepositConfirmation from './DepositConfirmation.vue'
import Token from '../tools/csrf_token.js'

export default {
  components: { DepositConfirmation },
  data () {
    return {
      checked: false,
      csrf: Token()
    }
  },
  props: {
    selectedDeposits: { type: Object, required: true},
    confirmUrl: { type: String },
    requireConfirmationIds: { type: Boolean, required: true },
    shouldTrackPrompt: { type: Boolean, required: true },
    redirectPath: { type: String, required: false },
    ownerCanNyop: { type: Boolean, required: false },
    rentalWday: { type: Number, required: false }
  },
  methods: {
    removeDeposit (id) {
      this.$emit('removeDeposit', id)
    },
    formatConfirmUrl () {
      const queryString = this.buildQueryString()
      if(queryString == "") { return this.confirmUrl }

      return `${this.confirmUrl}?${queryString}`
    },
    buildQueryString () {
      let queryObj = {}
      if(this.shouldTrackPrompt) { queryObj.dnr = '1' }
      if(this.redirectPath) { queryObj.redirect_path = this.redirectPath }

      return Object.keys(queryObj).map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(queryObj[key])
      }).join("&");
    }

  },
  computed: {
    hasSelectedDeposits () {
      return Object.keys(this.selectedDeposits).length >= 1
    },
    needsResortConfirmationIds () {
      if(this.requireConfirmationIds) {
        return !Object.values(this.selectedDeposits).every(d => d.resort_confirmation_id)
      } else {
        return false
      }
    },
    confirmButtonDisabled () {
      return (!this.checked || !this.hasSelectedDeposits || this.needsResortConfirmationIds)
    }
  }
}
</script>
