import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['tab', 'content', 'link']
  
  initialize () {
    this.activeClass = this.data.get('class')
  }
  
  toggle(e) {
    e.preventDefault();
    this.index = this.tabTargets.indexOf(e.currentTarget);
    if(e.currentTarget.classList.contains(this.activeClass)) return;
    this.tabTargets.forEach((tab, index) => {
      const is_selected = index == this.index;
      const content = this.contentTargets[index]
      const link = this.linkTargets[index]
      
      tab.classList.toggle(this.activeClass, is_selected);
      content.classList.toggle(this.activeClass, is_selected);
      link.setAttribute('aria-selected', is_selected)
    });
  }
}