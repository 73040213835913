import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['tab', 'tabPanel'];
  }
  
  toggle(e) {
    e.preventDefault();
    let _targetPanel = document.getElementById( e.target.dataset.target )

    // remove Active Classes
    this.tabTargets.forEach((tab) => {
      tab.classList.remove('active')
    })
    this.tabPanelTargets.forEach((panel) => {
      panel.classList.remove('active')
      panel.setAttribute('aria-hidden', 'true')
    })

    // add Active Classes
    e.target.classList.add('active')
    _targetPanel.classList.add('active')
    _targetPanel.setAttribute('aria-hidden', 'false')
  }
}