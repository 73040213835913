import { receive } from '../app/bridge'

# Notification Menu Toggle
$(document).on 'click', '.notification-link, .notifications-drawer .close-x', (e) ->
  e.preventDefault()
  $('body').toggleClass 'show-notifications'
  $('body').removeClass 'show-menu show-account show-search'

# More Menu Toggle
$(document).on 'click', '.more-link', (e) ->
  e.preventDefault()
  $('body').toggleClass 'show-menu show-sub'
  $('body').removeClass 'show-account show-search show-notifications'

# Account Menu Toggle
$(document).on 'click', '.account-link', (e) ->
  e.preventDefault()
  $('body').toggleClass 'show-account'
  $('body').removeClass 'show-menu show-search show-notifications'

# Mobile Search Toggle
$(document).on 'click', '.search-link', (e) ->
  e.preventDefault()
  $('body').toggleClass 'show-search'

# Desktop Close Menu on outside click
$(document).on 'click', 'main', (e) ->
  $('body').removeClass 'show-menu show-account show-search show-notifications'

# Close menus when caching page with Turbo 
$(document).on 'turbo:before-cache', ->
  $('body').removeClass 'show-menu show-account show-search show-notifications show-sub'

receive 'toggle-notification-menu', ->
  event = new Event("app-open-notifications", bubbles: true, cancelable: false)
  window.dispatchEvent(event)

  $('body').toggleClass 'show-notifications'
  $('body').removeClass 'show-menu show-account show-search'

receive 'toggle-account-menu', ->
  $('body').toggleClass 'show-account'
  $('body').removeClass 'show-menu show-search show-notifications'

receive 'toggle-more-menu', ->
  $('body').toggleClass 'show-menu'
  $('body').removeClass 'show-account show-search show-notifications'