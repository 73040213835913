<template lang='pug'>
  li.choice(:data-choice-type='result.type')
    a(@click.prevent="click") {{result.description}}
</template>

<script>
export default {
  props: {
    result: {required: true},
  },
  data () {
    return {}
  },
  methods: {
    click () {
      this.$emit('selected', this.result);
    }
  }
}
</script>
