import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "street1", "city", "region", "country", "postal",
    "street1Feedback", "cityFeedback", "regionFeedback", "countryFeedback"
  ]

  submitAddress () {
    let address = {
      street1: this.street1Target.value,
      city: this.cityTarget.value,
      region: this.regionTarget.value,
      country: this.countryTarget.value,
      postal: this.postalTarget.value,
    }

    const fieldsMissingValues =
      ['street1', 'city', 'region', 'country'].
        filter((field) => {
          const feedback = this[`${field}FeedbackTarget`]
          const fieldSupplied = !!address[field] || this[`${field}Target`].disabled
          if (feedback) {
            feedback.classList.toggle('hide', fieldSupplied)
          }
          return !fieldSupplied
        })

    if (fieldsMissingValues.length) return

    let event = new Event("address-input:submitted", {bubbles: true, cancelable: false})
    event.address = address
    this.element.dispatchEvent(event)
  }
  handleEnterKey (e) {
    if (e.keyCode == 13) {
      e.preventDefault()
      this.submitAddress()
      return false
    }
  }
}
