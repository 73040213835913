<template lang='pug'>
  .vue-template-wrapper
    ConfirmationModal(:selectedDeposits='selectedDeposits',
                      :confirmUrl='confirmUrl',
                      :requireConfirmationIds='requireConfirmationIds',
                      :shouldTrackPrompt='shouldTrackPrompt'
                      :redirectPath='redirectPath',
                      :ownerCanNyop='ownerCanNyop',
                      :rentalWday='rentalWday',
                      v-on:removeDeposit='removeDeposit')
    .expanding-section.closed(data-controller="expand-collapse", data-expand-collapse-target="container" v-if='ownerCanLastMinute')
      .expand-toggle(data-action="click->expand-collapse#toggleOpen")
        .deposit-entry.grid(data-expand-collapse-target="more", style="padding-top:.5rem;padding-bottom:.5rem;")
          .cell
            strong
              | Add
              |
              span(v-if='anyBefore')
                | additional
                |
              | weeks before {{firstRegWeek}}
              span.down-arrow
      .expanding-content(data-expand-collapse-target="expand")
        br
        #last-minute-info.callout
          h5.sub-header &#42; About Last Minute Deposits
          p.small
            | Take advantage of any upcoming availability at your property!
            | Given the last-minute nature of these dates (they start in the
            | next {{depositWindow}} days), you will only receive Keys if the
            | deposits are booked by another member.
        #bookings-soon
          DepositGroup(v-for='deposit in before',
                       :key='deposit.id',
                       :parent='deposit',
                       :selectedDeposits='selectedDeposits',
                       v-on:staySelected='staySelected')
    .bookings-later
      DepositGroup(v-for='deposit in after',
                   :key='deposit.id',
                   :parent='deposit',
                   :selectedDeposits='selectedDeposits',
                   v-on:staySelected='staySelected')
    div
      br
      span.asterisk-size *
      | These Keys will be awarded when the deposit is reserved by a guest.
    div(style="margin:20px 0 0;")
      a(href='#confirm-deposits',
        class='button',
        :class='modalToggleActive',
        :disabled='!hasSelectedDeposits') Submit Deposits
</template>

<script>
import DepositGroup from './DepositGroup.vue'
import ConfirmationModal from './ConfirmationModal.vue'

export default {
  components: { DepositGroup, ConfirmationModal },
  props: {
    before: { type: Array },
    after: { type: Array },
    firstRegWeek: { type: String },
    depositWindow: { type: String },
    confirmUrl: { type: String },
    requireConfirmationIds: { type: Boolean, required: true},
    shouldTrackPrompt: { type: Boolean, required: true  },
    redirectPath: { type: String, required: false },
    ownerCanNyop: { type: Boolean, required: false },
    rentalWday: { type: Number, required: false },
    ownerCanLastMinute: { type: Boolean, required: true }
  },
  data () { return { selectedDeposits: {} } },
  methods: {
    removeDeposit (id) {
      this.$delete(this.selectedDeposits, id)
    },
    staySelected (id, selectedDeposit) {
      if(selectedDeposit === null) {
        this.$delete(this.selectedDeposits, id)
      } else {
        this.$set(this.selectedDeposits, id, selectedDeposit)
      }
    }
  },
  computed: {
    modalToggleActive () {
      return {
        'modal-pop': this.hasSelectedDeposits
      }
    },
    anyBefore () {
      return this.before.some(function(elem) {
        return elem.existing_deposit
      });
    },
    hasSelectedDeposits () {
      return Object.keys(this.selectedDeposits).length !== 0
    }
  }
}
</script>
