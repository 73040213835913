<template lang="pug">
.stay-options.medium-order-2
  h5.no-bottom-margin Stay Options
  .input.check_boxes
    span.checkbox
      input(type="checkbox" v-model="handicap_accessible" id="handicap")
      label(for="handicap") Handicap Accessible

    span.checkbox
      input(type="checkbox" v-model="pet_friendly" id="pets")
      label(for="pets") Pet Friendly

    span.checkbox
      input(type="checkbox" v-model="requestable" id="week-requests")
      label(for="week-requests") Accepts Week Requests

</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  data () {
    return {
      $oaLabel: null,
      $extended: null,
      $short: null
    }
  },
  computed: {
    ...sync('browseHomes', {
      search_type: 'filters@search_type',
      open_availability: 'filters@open_availability',
      extended_stays: 'filters@extended_stays',
      short_stays: 'filters@short_stays',
      pet_friendly: 'filters@pet_friendly',
      full_service: 'filters@full_service',
      requestable: 'filters@requestable',
      handicap_accessible: 'filters@handicap_accessible',
      loggedIn: 'loggedIn'
    }),
    disableOA () {
      return this.search_type === 'availability'
    },
    tipsoTextExtended () {
      return this.loggedIn ? null :  "Please log in to filter by extended stays."
    },
    tipsoTextShort () {
      return this.loggedIn ? null :  "Please log in to filter by short stays."
    }
  },
  methods: {
    setupOpenAvailTooltip () {
      if (this.search_type === 'availability') {
        this.$oaLabel.tipso({
          background: 'black',
          titleBackground: 'black',
          position: 'left',
          offsetY: -4,
          content: 'Use the Properties search type to filter properties by Open Availability.'
        })
      } else {
        this.$oaLabel.tipso('destroy')
      }
    },
    setupShortExtendedTooltip () {
      if (!this.loggedIn) {
        this.$extended.tipso({
          background: 'black',
          titleBackground: 'black',
          position: 'left',
          offsetY: -4,
          content: 'Please log in to filter by extended stays.'
        })
        this.$short.tipso({
          background: 'black',
          titleBackground: 'black',
          position: 'left',
          offsetY: -4,
          content: 'Please log in to filter by short stays.'
        })
      } else {
        this.$extended.tipso('destroy')
        this.$short.tipso('destroy')
      }
    }
  },
  mounted () {
    this.$oaLabel = $(this.$refs.oaLabel)
    this.$extended = $(this.$refs.extended)
    this.$short = $(this.$refs.short)
    this.setupOpenAvailTooltip()
    this.setupShortExtendedTooltip()
  },
  watch: {
    search_type () {
      this.setupOpenAvailTooltip()
    },
    loggedIn () {
      this.setupShortExtendedTooltip()
    }
  }
}
</script>
