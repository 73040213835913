import formatCurrency from '../tools/format_currency';

class WeekSelection
  constructor: (date, @schedule, @freeKeys, @_priceCalculator) ->
    weeks = @schedule().newWeeks()
    @weeks = ko.observableArray(weeks)
    @date = ko.observable(date)
    @schedule.subscribe (newSchedule) =>
      @date null
      @weeks newSchedule.newWeeks()
    @selectedDate = ko.computed =>
      newDate = @date()
      matches = (week for week in @weeks() when week.startsOn is newDate)
      matches[0]
    @hasSelected = ko.computed =>
      !!@selectedDate()
    @makeOffer = ko.observable(false)
    @overrideKeys = ko.observable()
    @setKeys = ko.computed =>
      date = @selectedDate()
      if date
        date.keys
    @offerKeys = ko.computed =>
      top = @freeKeys
      if top > 50
        top = 50
      ko.utils.range(@setKeys(), top)
    @setPrice = ko.computed =>
      date = @selectedDate()
      if date
        date.fee
    @keys = ko.computed =>
      if @makeOffer()
        keys = @overrideKeys()
      else
        keys = @setKeys()
      keys || 0
    @price = ko.computed =>
      if @makeOffer()
        price = @_priceCalculator(@keys())
      else
        price = @setPrice()
      price || 0.0
    @formattedPrice = ko.computed =>
      formatCurrency(@price())
    @selectedDate.subscribe (date) =>
      @makeOffer(false)
      if date
        @overrideKeys(date.keys)
    @makeOffer.subscribe (bool) =>
      selected = @selectedDate()
      for week in @weeks()
        if week == selected
          week.makeOffer(bool)
        else
          week.makeOffer(false)
    @canOffer = ko.computed =>
      @offerKeys().length > 0

export default WeekSelection;