import './floodlight';
import log from '../tools/log';

debug = (things...) ->
  log(things...)

shouldTrack = ->
  $(document.body).hasClass('track-analytics')

gtag = (params...) ->
  if shouldTrack() && window.gtag
    window.gtag(params...)
  else
    debug("GTAG:", params...)

trackEvent = (category, event, label, value) ->
  args =
    event_category: category
  if label
    args.event_label = label
  if value != undefined
    args.value = value
  gtag('event', event, args)

trackPageNavGA = (path, title) ->
  args =
    page_path: path
  args.page_title = title if title
  gtag('config', 'UA-12148864-1', args)

lib =
  trackEvent: trackEvent
  trackPageNavGA: trackPageNavGA

export { trackEvent, trackPageNavGA };
export default lib;
