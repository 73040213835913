var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-template-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "grid calendar-grid edge",
        attrs: {
          "data-title": "Add your week to earn Keys",
          "data-intro": "Click on a given week on the calendar.",
          "data-step": "2"
        }
      },
      [
        _c(
          "div",
          { staticClass: "cell twelve medium-six left-calendar" },
          [
            _c("CalendarMonth", {
              attrs: {
                startDay: _vm.calendarOneStart,
                selectedDate: _vm.selectedDate,
                deposits: _vm.allDeposits,
                showPreviousArrow: true,
                depositRange: _vm.depositRange,
                seasonality: _vm.seasonality,
                keyEarningStartDate: _vm.keyEarningStartDate,
                selectedRange: _vm.selectedRange
              },
              on: {
                previousMonth: function($event) {
                  return _vm.$emit("previousMonth")
                },
                dateSelected: _vm.dateSelected
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "cell twelve medium-six right-calendar" },
          [
            _c("CalendarMonth", {
              attrs: {
                startDay: _vm.calendarTwoStart,
                selectedDate: _vm.selectedDate,
                deposits: _vm.allDeposits,
                showNextArrow: true,
                depositRange: _vm.depositRange,
                seasonality: _vm.seasonality,
                keyEarningStartDate: _vm.keyEarningStartDate,
                selectedRange: _vm.selectedRange
              },
              on: {
                nextMonth: function($event) {
                  return _vm.$emit("nextMonth")
                },
                dateSelected: _vm.dateSelected
              }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }