addCommasToNumber = (x) ->
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

formatCurrency = (float) ->
  float = parseFloat(float) unless typeof(float) == "number"
  return "$NaN" if isNaN(float)
  [dollars, cents] = float.toFixed(2).split(".")
  dollars = addCommasToNumber(dollars)
  amount = if cents == "00"
    dollars
  else
    "#{dollars}.#{cents}"
  "$#{amount}"

export default formatCurrency;
