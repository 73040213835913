import loader from './mapbox';
import twoFingerDrag from './two-finger-drag';
import EventEmitter from '../tools/event-emitter';

class SingleMap {
  constructor (options) {
    this.element = null;
    this.data = null;
    this.center = null;
    this.zoom = null;
    this.mapboxgl = null;
    this.map = null;
    this.highContrastStyles = options['highContrastStyles'] || false
    this._assignOptions(options);
    this.events = new EventEmitter();
  }
  start () {
    const styleSheet = this.highContrastStyles ? 'mapbox://styles/thirdhome/clgv0b924002001pfdl0hd2av' : 'mapbox://styles/thirdhome/ck864eblw0s581inps0bvfwz4'
    const initParams = {
      container: this.element,
      style: styleSheet,
      maxZoom: 15,
      center: this.center,
      zoom: this.zoom
    }

    loader.then((mapboxgl) => {
      this.mapboxgl = mapboxgl;
      const map = new mapboxgl.Map(initParams);
      this.map = map;
      map.addControl(new mapboxgl.NavigationControl({showCompass: false}), 'top-left');
      map.on('load', () => {
        this._mapLoaded();
      });
    });
  }
  updateData(newData) {
    if (JSON.stringify(this.data) === JSON.stringify(newData)) return
    this.data = newData
    if (this.map) {
      this.map.getSource('points').setData(newData)
      this.fitPoints()
    }
  }

  fitPoints () {
    const features = this.data.features
    const currentBounds = this.map.getBounds()
    if (features.every((feature) => currentBounds.contains(feature.geometry.coordinates))) return

    const fitBounds = features.reduce(function(bounds, feature) {
      return bounds.extend(feature.geometry.coordinates)
    }, new this.mapboxgl.LngLatBounds());

    this.map.fitBounds(fitBounds, {
      maxZoom: this.zoom,
      padding: this._boundPadding
    })
  }

  get _boundPadding () {
    return (this.element.offsetHeight * .25) / 2;
  }
  remove () {
    this.map && this.map.remove();
  }
  on (eventName, listener) {
    this.events.on(eventName, listener);
  }
  _emit (eventName, ...data) {
    this.events.emit(eventName, this, ...data);
  }
  _emitMoved () {
    this._emit('move', this.location);
  }
  get location () {
    const coords = this.map.getCenter();
    const zoom = this.map.getZoom();
    return {lng: coords.lng, lat: coords.lat, zoom: zoom};
  }

  _assignOptions (options) {
    ['element', 'data', 'zoom', 'center'].forEach((field) => {
      if (options[field]) {
        this[field] = options[field];
      };
    });
  }

  _mapLoaded () {
    const map = this.map;
    const controller = this;
    map.addSource('points', {
      type: 'geojson',
      data: this.data
    });

    map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'points',
      filter: ['all', ['!', ['has', 'point_count']], ['==', 'exact', ['get', 'map_mode']]],
      paint: {
        'circle-color': '#148BE0',
        'circle-radius': 6.5,
        'circle-stroke-width': 2.5,
        'circle-stroke-color': '#fff'
      }
    });

    map.addLayer({
      id: 'unclustered-point-fuzzy',
      type: 'circle',
      source: 'points',
      filter: ['all', ['!', ['has', 'point_count']], ['==', 'fuzzy', ['get', 'map_mode']]],
      paint: {
        'circle-color': '#148BE0',
        'circle-radius': [
          "interpolate", ["linear"], ["zoom"],
          10.5, 6.5,
          13, 40,
          14, 100,
          14.5, 200,
        ],
        'circle-stroke-width': 2.5,
        'circle-opacity': [
          "interpolate", ["linear"], ["zoom"],
          10.5, 1,
          11, .15
        ],
        'circle-stroke-color': '#fff'
      }
    });

    twoFingerDrag(map);
    map.scrollZoom.disable();
    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();
    map.on('moveend', function () {
      controller._emitMoved();
    });

    map.on('zoomend', function () {
      controller._emitMoved();
    });
  }
}

export default SingleMap;
