<template lang="pug">
  .cell.fit.beds-container
    label Bedrooms
    input.min-beds(:value="beds" @input="changeMinBeds"
                   type="number"
                   class="number"
                   size="3"
                   min="1" max="30")
</template>

<script>
import { sync, call } from 'vuex-pathify'

export default {
  data () {
    return { beds: 1 }
  },
  computed: {
    ...sync('browseHomes', {
      min_beds: 'filters@min_beds',
      filterModalOpen: 'filterModalOpen'
    })
  },
  methods: {
    fetch: call('browseHomes/fetch'),
    changeMinBeds (e) {
      const min_beds = e.target.value
      if (this.beds !== min_beds &&
          !isNaN(parseInt(min_beds), 10)
      ) {
        this.beds = min_beds
        this.min_beds = min_beds
        this.fetch()
      }
    }
  },
  mounted () {
    this.beds = this.min_beds
  },
  watch: {
    min_beds (value) {
      if (this.beds.toString() !== value.toString()) {
        this.beds = value
      }
    }
  }
}
</script>
