<template lang='pug'>
  .end-select(:class='deposit.season')
    label.single-checkbox-container.grid
      .cell.checkbox-column.fit
        input(type='radio'
              name='depositLengthSelection'
              @change="$emit('stayPicked', deposit)")
        span.single-checkbox
      .cell.date
        span.ends-on.date {{endsOn}}
        div
          span.third-font-calendar
          |  {{deposit.length}} Nights
          | &nbsp; / &nbsp;
          span.key-count(:title='keyCountTitle')
            strong.count
              | {{deposit.keys}}
            | &nbsp;
            span.third-font-key
          span.tipso_styles.asterisk(v-if='showTipso',
               data-tipso="You will not earn upfront keys for this week. You'll earn keys once it's booked.")
            | &#10033;
</template>

<script>
export default {
  props: {
    deposit: { type: Object, required: true },
  },
  computed: {
    showTipso () {
      return this.deposit.delayed_keys
    },
    endsOn () {
      return dayjs(this.deposit.ends_on).format('ddd, MMM D, YYYY')
    },
    keyCountTitle () {
      return `${this.deposit.keys} Keys`
    },
  }
}
</script>
