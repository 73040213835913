import { Controller } from "@hotwired/stimulus";
import Token from '../../tools/csrf_token';

export default class extends Controller {
  static values = {
    url: String,
    sendCodeOnLoad: Boolean
  }
  static targets = [ "sendSMS", "verifySMS" ]

  connect() {
    if (this.sendCodeOnLoadValue) this._makeAuthenticationRequest();
  }

  async sendAuthentication({ target }) {
    try {
      await this._makeAuthenticationRequest();
      this._setButtonCooldown(target)
      this._toggleModalPage()
    }
    catch(error) { console.log(error) }
  }

  _setButtonCooldown(button) {
    const notice = document.getElementById('sms-disabled-notice')
    if(notice) {
      button.disabled = true
      notice.innerText = 'Please wait 30 seconds before sending another code.'
      setTimeout(() => {
        button.disabled = false
        notice.innerText = ''
      }, 30000)
    }
  }

  _toggleModalPage() {
    if (this.hasSendSMSTarget && this.hasVerifySMSTarget) {
      this.sendSMSTarget.hidden = true
      this.verifySMSTarget.hidden = false
    }
  }

  async _makeAuthenticationRequest() {
    const response = await fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify({ authenticity_token: Token() })
    })
    if (response.ok) { return };
    throw { error: response.status }
  }
}