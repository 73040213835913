const LocalStorage = {
  available: (localStorage !== undefined),
  get(key) {
    if (!this.available) return
    const value = localStorage.getItem(key)
    if (!value) return
    return JSON.parse(value)
  },

  add(key, value) {
    if (!this.available) return
    localStorage.setItem(key, JSON.stringify(value))
  },
  
  remove(key) {
    if (!this.available) return
    localStorage.removeItem(key)
  }
}

export default LocalStorage;