import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['template', 'placeContainer', 'activityContainer', 'newRowButton']

  // Prepare a template object for the Place and Activity listings
  templateTargetConnected(el) {
    this[`${el.dataset.interestType}ContainerTarget`]['template'] = el.content.firstElementChild
  }

  // Bind button and container siblings to each other for easier reference without querying document or interpolated calls
  newRowButtonTargetConnected(el) {
    const containerTarget = this[`${el.dataset.interestType}ContainerTarget`]
    containerTarget['button'] = el
    el['container'] = containerTarget
  }

  // Create a new row using template
  addRow(e) {
    e.preventDefault()
    const parentContainer = e.target.container
    parentContainer.button.style.visibility = 'hidden'

    const template = parentContainer.template.cloneNode(true)
    template.innerHTML = template.innerHTML.replace(/REPLACE_WITH_INDEX/g, Math.floor(Math.random() * 100000000))
    parentContainer.appendChild(template).querySelector('input').focus()
  }

  // Delete a row outright for unpersisted listings
  deleteRow(e) {
    e.preventDefault()
    const container = e.target.closest('ul')
    e.target.closest('li').remove();
    this._toggleButton(container)
  }

  toggleRow(e) {
    e.preventDefault()
    // True when 'delete' is clicked, false when 'keep' is clicked
    const markedForDelete = e.params.deleted
    const boxParent = e.target.closest('li');

    // Toggle delete/keep buttons swap
    boxParent.querySelector('.keep-row').style.display = markedForDelete ? '' : 'none'
    boxParent.querySelector('.delete-row').style.display = markedForDelete ? 'none' : ''

    // Toggle text/marked-for-deletion input disabled swap
    boxParent.querySelector('input[type="text"]').disabled = markedForDelete
    boxParent.querySelector('input[type="hidden"]').disabled = !markedForDelete
    boxParent.classList.toggle('deleted', markedForDelete)

    this._toggleButton(e.target.closest('ul'))
  }

  toggleButton({ target }) {
    this._toggleButton(target.closest('ul'))
  }

  // Internal usage call to provide a specific container rather than event based chain
  _toggleButton(container) {
    const emptyContainersPresent = container.querySelectorAll('input.point:enabled:placeholder-shown').length != 0
    container.button.style.visibility = emptyContainersPresent ? 'hidden' : 'visible'
  }

  // Triggered by document-level submit event
  filterEmptyForms(event) {
    if (Array.from(event.target.children).includes(this.element)) {
      const formData = new FormData(event.target).entries()
      for (const [name, value] of formData) if (value == '') { event.target.querySelector(`[name="${name}"]`).disabled = true }
    }
  }
}
