<template lang='pug'>
  .cell.collapse.day(@click='dateSelected'
               :class='dayClasses'
               :data-start-range="startRangeAttr"
               :data-end-range="endRangeAttr"
               :data-during="duringAttr",
               :data-season='season')
    .day-number {{dayOfMonth}}
    span.day-span
    span.day-span
    .season
</template>

<script>
import Ranger from '../Ranger.js';

export default {
  props: {
    day: { required: true },
    active: { required: true },
    valid: { requried: true},
    invalid: { required: true},
    deposits: { type: Array },
    depositRange: { required: true },
    seasonality: { required: true },
    keyEarningStartDate: {required: true}
  },
  methods: {
    emitSelection () {
      if(this.day === '') { return }

      let selectedDate = '';
      if(!this.active) {
        selectedDate = this.formattedDate;
      }
      this.$emit('dateSelected', selectedDate);
    },
    dateSelected () {
      if (!this.selectable) { return }

      this.emitSelection();
    },
    depositType (deposit) {
      if (deposit) {
        if (deposit.depositable) {
          return 'selected'
        }
        return (deposit.status == 'pending') ? 'pending' : 'approved'
      }
    }
  },
  computed: {
    dayEmpty () {
      return '' === this.day;
    },
    dayPresent () {
      return !this.dayEmpty
    },
    formattedDate () {
      if (this.dayPresent) {
        return this.day.format('YYYY-MM-DD');
      }
    },
    season () {
      if (this.formattedDate) {
        return this.seasonality[this.formattedDate] || 'unavailable'
      }
    },
    overlappingSelections () {
      if (this.dayEmpty) {
        return []
      }
      return Ranger.filterRangesByDate(this.day, this.deposits);
    },
    startsOn () {
      if (this.dayPresent) {
        return this.overlappingSelections.
          filter(deposit => deposit.starts_on == this.formattedDate)[0]
      }
    },
    endsOn () {
      if (this.dayPresent) {
        return this.overlappingSelections.
          filter(deposit => deposit.ends_on == this.formattedDate)[0]
      }
    },
    during () {
      if (!this.startsOn && !this.endsOn) {
        return this.overlappingSelections[0]
      }
    },
    startRangeAttr () {
      return this.depositType(this.startsOn)
    },
    endRangeAttr () {
      return this.depositType(this.endsOn)
    },
    duringAttr () {
      return this.depositType(this.during)
    },
    inRange () {
      return this.depositRange.contains(this.day)
    },
    unavailable () {
      return this.dayPresent && !this.inRange
    },
    selectable () {
      return this.dayPresent && this.inRange &&
        (this.open || (this.endsOn && !this.startsOn));
    },
    open () {
      return this.overlappingSelections.length == 0
    },
    lastMinute () {
      return this.dayPresent && this.formattedDate < this.keyEarningStartDate;
    },
    dayClasses () {
      let classes = {
        selectable: this.selectable,
        unavailable: this.unavailable,
        empty: this.dayEmpty,
        "last-minute": this.lastMinute,
        valid: this.valid, // In week of selected day
        invalid: this.invalid, // In week but invalid selection
        active: this.active, // Selected day
      };
      return classes;
    },
    dayOfMonth () {
      if (this.dayPresent) {
        return this.day.format('D');
      }
    }
  }
}
</script>
