import init from '../init';

init.pageLoad 'new-review',
  load: ->
    showRating = (num) ->
      $('.stars').removeClass().addClass("stars interactive v#{num}")

    hiddenRating = ->
      $('#review_rating').val()

    $('.stars > span').on('mouseover', (event) ->
      showRating($(this).data('rating'))
    ).on('mouseout', (event) ->
      showRating(hiddenRating()) unless $(event.target).hasClass('stars')
    ).on('click', (event) ->
      $('#review_rating').val($(this).data('rating'))
    )
    
    if $('div.stars').exists()
      showRating(hiddenRating())
