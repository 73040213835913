import log from '../tools/log';

class EventManager {
  constructor() {
    this.connections = [];
    this.following = [];
  }
  connect(conn) {
    this.connections.push(conn);
    Object.keys(conn).forEach((event) => this._listen(event));

    return conn;
  }
  disconnect(connToRemove) {
    this.connections = this.connections.filter((conn) => conn != connToRemove);
  }
  _listen(eventName) {
    if (this.following.indexOf(eventName) < 0) {
      let manager = this;
      document.addEventListener(eventName, function (...args) {
        let thisArg = this;
        manager._fireEvent(thisArg, ...args);
      });
      this.following.push(eventName);
    }
  }
  _fireEvent(thisArg, e, ...rest) {
    const eventName = e.type;
    const callbacks = this.connections.map((conn) => conn[eventName])
      .filter((value) => value);
    callbacks.map((func) => this._applyCallback(func, thisArg, e, ...rest));
  }
  _applyCallback(func, thisArg, e, ...args) {
    try {
      func.call(thisArg, e, ...args);
    } catch (e) {
      log("EventManager application error:", e);
    }
  }
}

const manager = new EventManager();

export { EventManager };
export default manager;
