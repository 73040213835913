<template lang='pug'>
  .deposit-entry(:class='deposit.season')
    .grid.align-center.collapse-padding
      .cell.date-range.fill
        span.third-font-calendar
        span.starts-on.date {{formattedRange.formattedStart}}
        |
        | to
        |
        span.ends-on.date {{formattedRange.formattedEnd}}
      .cell.fit
        .pill.tiny.season
          | {{deposit.season.replace(/_/g, " ")}}
      .cell.key-count-column.two.text-right
        span.key-count(:title='keyCountTitle')
          span.count
            | {{deposit.keys}}
          | &nbsp;
          span.third-font-key-glyph
        span.tipso_styles.asterisk(v-if='showTipso',
             data-tipso="You will not earn upfront keys for this week. You'll earn keys once it's booked.")
          | &#10033;
      .cell.twelve(v-if='showTipso')
        p.no-bottom-margin.small.strong.red-text(style="line-height:1.1;margin-top:.25rem;") Keys for this week will be awarded once reserved by a guest.
</template>

<script>
export default {
  props: {
    deposit: { type: Object, required: true },
  },
  data () {
    const mStartDate = dayjs(this.deposit.starts_on)
    return {
      ends_on: mStartDate.add(this.deposit.stayLength, 'days').format('YYYY-MM-DD')
    }
  },
  computed: {
    showTipso () {
      return this.deposit.delayed_keys
    },
    keyCountTitle () {
      return `${this.deposit.keys} Keys`
    },
    formattedRange () {
      return formattedStayRange(this.deposit.starts_on, this.deposit.ends_on)
    }
  }
}
</script>
