<template lang='pug'>
  .vue-template-wrapper
    div(v-if='selectedDate')
      div(v-if='multipleStays')
        label Select an end date
        .grid
          DepositRadio(v-for='deposit of orderedOptions'
                        :key='deposit.identifier'
                        :deposit='deposit'
                        @stayPicked='stayPicked')
      div(v-else-if='singleStay')
        Deposit(v-for='deposit of stayOptions'
                :key='deposit.identifier'
                :deposit='deposit')
      p.small(style='margin-top:.25rem')
        | You’ll have the opportunity to review your added week and Name Your Price (if applicable) before it is final.
</template>

<script>
import Deposit from './Deposit.vue';
import DepositRadio from './DepositRadio.vue';
import Ranger from '../Ranger.js';
import orderBy from 'lodash/orderBy';

export default {
  components: { Deposit, DepositRadio },
  props: {
    optionUrl: { type: String, required: true },
    selectedDate: { type: String },
    selectedDeposits: { required: true }
  },
  data () { return { stayOptions: [] } },
  watch: {
    selectedDate (newDate, oldDate) {
      this.stayOptions = [];
      this.$emit('stayPicked', '');
      if(newDate !== '') { this.setStayOptions(); }
    }
  },
  computed: {
    multipleStays () { return this.stayOptions.length > 1 },
    singleStay () { return this.stayOptions.length === 1 },
    orderedOptions () {
      return orderBy(this.stayOptions, 'ends_on');
    }
  },
  methods: {
    stayPicked (deposit) { this.$emit('stayPicked', deposit); },
    setStayOptions () {
      if(!Ranger.dateOverlapsRanges(this.selectedDate, this.selectedDeposits)) {
        this.$emit('error', 'This date conflicts with other selected stays');
        return
      }

      this.$http.get(this.optionUrl, {params: {starts_on: this.selectedDate}})
        .then(response => {
          if(response.body.status !== 'ok') {
            this.$emit('error', response.body.error);
            return
          }

          const stays = response.body.stay_options.filter(stay => {
            return Ranger.datesOverlapRanges(
              stay.starts_on,
              stay.ends_on,
              this.selectedDeposits
            );
          });

          this.stayOptions = stays;
          if(stays.length === 1) {
            this.$emit('stayPicked', stays[0]);
          } else if(stays.length === 0) {
            this.$emit('error', 'This date conflicts with other selected stays');
          }
        });
    }
  }
}
</script>
