var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "deposit-entry", class: _vm.deposit.season },
    [
      _c("div", { staticClass: "grid align-middle edge" }, [
        _c(
          "div",
          {
            staticClass: "cell date-range twelve",
            staticStyle: { "margin-bottom": ".25rem" }
          },
          [
            _c("span", { staticClass: "starts-on date" }, [
              _vm._v(_vm._s(_vm.formattedRange.formattedStart))
            ]),
            _vm._v("\nto\n"),
            _c("span", { staticClass: "ends-on date" }, [
              _vm._v(_vm._s(_vm.formattedRange.formattedEnd))
            ]),
            _vm._v(" (" + _vm._s(_vm.season) + ")")
          ]
        ),
        _c("div", { staticClass: "cell key-count-column fit" }, [
          _c(
            "span",
            { staticClass: "key-count", attrs: { title: _vm.keyCountTitle } },
            [
              _vm.hasKeyPriceOverride()
                ? _c("span", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.key_price_override))
                  ])
                : _c("span", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.deposit.keys))
                  ]),
              _vm._v(" "),
              _c("span", { staticClass: "third-font-key-glyph" })
            ]
          ),
          _vm.showTipso
            ? _c(
                "span",
                {
                  staticClass: "tipso_styles asterisk",
                  attrs: {
                    "data-tipso":
                      "You will not earn upfront keys for this week. You'll earn keys once it's booked."
                  }
                },
                [_vm._v("✱")]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "cell season fill" }),
        _c("div", { staticClass: "cell fit text-right" }, [
          _c("small", [
            _c(
              "a",
              {
                staticClass: "underline",
                on: {
                  click: function($event) {
                    return _vm.$emit("removeDeposit", _vm.deposit.identifier)
                  }
                }
              },
              [_vm._v("Remove")]
            )
          ])
        ]),
        _vm.requireConfirmationIds
          ? _c("div", { staticClass: "cell twelve resort-confirmation" }, [
              _c(
                "div",
                {
                  staticClass: "input",
                  staticStyle: { "margin-top": ".75rem" }
                },
                [
                  _c("label", [
                    _vm._v("Resort Reservation Confirmation Number*")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.deposit.resort_confirmation_id,
                        expression: "deposit.resort_confirmation_id",
                        modifiers: { trim: true }
                      }
                    ],
                    staticStyle: { "margin-bottom": ".25rem" },
                    attrs: {
                      type: "text",
                      name: _vm.formatResortIdInputName()
                    },
                    domProps: { value: _vm.deposit.resort_confirmation_id },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.deposit,
                          "resort_confirmation_id",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm.canNamePrice
        ? _c(
            "div",
            {
              staticClass:
                "grid align-center edge name-your-price name-your-price--flexible"
            },
            [
              _c("div", { staticClass: "cell twelve" }, [
                _c(
                  "div",
                  {
                    staticClass: "input small check_boxes",
                    staticStyle: { "margin-bottom": "0" }
                  },
                  [
                    _c("span", { staticClass: "checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.nameYourPrice,
                            expression: "nameYourPrice"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          id: _vm.deposit.identifier,
                          name: _vm.deposit.identifier,
                          value: "1"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.nameYourPrice)
                            ? _vm._i(_vm.nameYourPrice, "1") > -1
                            : _vm.nameYourPrice
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.nameYourPrice,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "1",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.nameYourPrice = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.nameYourPrice = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.nameYourPrice = $$c
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticStyle: {
                            "padding-left": "0",
                            "margin-left": "8px"
                          },
                          attrs: {
                            for: _vm.deposit.identifier,
                            "data-tipso": _vm.nameYourPriceTooltip
                          }
                        },
                        [_vm._v("Name Your Price")]
                      )
                    ])
                  ]
                )
              ]),
              _vm.nameYourPrice
                ? _c("div", { staticClass: "cell fit" }, [
                    _c(
                      "label",
                      { attrs: { for: _vm.formatKeyPriceOverrides() } },
                      [_vm._v("New Price:")]
                    )
                  ])
                : _vm._e(),
              _vm.nameYourPrice
                ? _c("div", { staticClass: "cell fill" }, [
                    _c("div", { staticClass: "input small" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.key_price_override,
                              expression: "key_price_override"
                            }
                          ],
                          staticStyle: { "margin-bottom": "0" },
                          attrs: {
                            name: _vm.formatKeyPriceOverrides(),
                            id: _vm.formatKeyPriceOverrides()
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.key_price_override = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.keyOptions, function(index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: index } },
                            [_vm._v(_vm._s(index))]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.deposit.identifier,
            expression: "deposit.identifier"
          }
        ],
        attrs: { type: "hidden", name: "dates[]" },
        domProps: { value: _vm.deposit.identifier },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.deposit, "identifier", $event.target.value)
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }