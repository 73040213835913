import { Controller } from "@hotwired/stimulus";
import tooltips from "../../tools/tooltips";
import pasteBufferCopy from "../../tools/paste_buffer_copy";

export default class extends Controller {
  static values = {
    text: String,
    skipTooltip: Boolean,
    showCopyAlert: Boolean
  }

  copy (e) {
    e.preventDefault()
    const shareUrl = this.textValue
    if (shareUrl) {
      pasteBufferCopy(shareUrl)

      if (!this.skipTooltipValue) {
        this._notifyCopied();
      }

      if (this.showCopyAlertValue) {
        this._showCopyAlert();
      }
    }
  }

  _notifyCopied () {
    tooltips.pop(this.element, 'Copied to clipboard')
  }

  _showCopyAlert() {
    const flashContainer = document.querySelector("#flash-container.refer-friend-copy-link");
    const flashMessage = flashContainer.querySelector(".flash-container");

    if (flashContainer && flashMessage) {
      flashMessage.style.display = "block";
    }
  }
}
