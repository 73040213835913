import { Controller } from "@hotwired/stimulus";
import { flashMessage } from '../../tools/flash-messages';

export default class extends Controller {
  static targets = ["watchButton"]

  static values = {
    url: String,
    status: Boolean
  }

  statusValueChanged(event) {
    if (event === true) {
      this.watchButtonTarget.classList.add("active")
    } else {
      this.watchButtonTarget.classList.remove("active")
    }
  }

  toggle(e) {
    e.preventDefault()

    this.statusValue = !this.statusValue
    let targetAction = `${this.statusValue}`
    let targetUrl = `${this.urlValue}?watch=${targetAction}`

    const toggleWatch = fetch(targetUrl, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'}
    })

    toggleWatch.then(response => {
      if (!response.ok) {
        // If Error, reverse 
        this.statusValue = !this.statusValue
        flashMessage('error', 'Something went wrong. Please try again.')
      }

    }).catch((error) => {
      console.log(error)
    })

  }
}