import init from '../init';
import ScheduleSwitcher from './schedule_switcher';
import WeekPostingForm from './week_posting_form';

init.pageLoad 'new-week-posting',
  load: ->
    $form = $('#week-posting-form')
    switcher = new ScheduleSwitcher($form.data('schedule'))
    ko.applyBindings(switcher, $('#schedule-switcher')[0])
    $('.submit-new-week-request').click ->
      $('#week-posting-form')[0].submit()
      return false
    currentKeys = $('#not-enough-keys').data('free-keys')
    form = new WeekPostingForm(currentKeys, [], switcher.schedule, $form.data('prices'))
    ko.applyBindings(form, $form[0])