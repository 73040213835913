<template lang="pug">
  .cell.collapse.day(:class='dayClasses'
                   @click="clickDate"
                   @mouseover="hover"
  )
    template(v-if="day != null")
      .day-number
        | {{ dayLabel }}
    span.tooltip-hidden(data-tipso='Choose a check out date.')
    div.marker-start
    div.marker-end
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    day: { type: Object, required: true },
    dayPassed: { type: Boolean, required: true },
    canStart: { type: Boolean, required: true },
    canEnd: { type: Boolean, required: true },
    classes: { type: Object, default: null }
  },
  computed: {
    ...mapGetters(
      'calendars/stay-request',
      ['stayRange', 'hoverStayRange', 'selectionStart', 'availableRange',
      'selectableEndRange']
    ),
    dayLabel () {
      return this.day ? this.day.format('D') : ''
    },
    endSelectable () {
      if (this.selectableEndRange && !this.stayRange) {
        return this.selectableEndRange.contains(this.day)
      } else {
        return false
      }
    },
    dayClasses () {
      return {
        ...this.classes,
        'end-selectable': this.endSelectable,
        ...this.selectionClassesFor('selection', this.stayRange),
        ...this.selectionClassesFor('hover', this.hoverStayRange),
      }
    },
    showTooltip () {
      return this.day.isSame(this.selectionStart) && !this.stayRange
    }
  },
  watch: {
    showTooltip (show) {
      $(this.$el).find('.tooltip-hidden').tipso(show ? 'show' : 'hide')
    }
  },
  methods: {
    ...mapActions('calendars/stay-request', ['selectDate', 'hoverDate']),
    clickDate () {
      // Users may select a date if either:
      // 1) This day can start a selection, or
      // 2) There is already a start, this day can end, and it's within range of the start.
      if (this.canStart ||
          (this.selectionStart && this.canEnd && this.selectableEndRange.contains(this.day))) {
        this.selectDate(this.day)
      }
    },
    hover () {
      this.hoverDate(this.day)
    },
    selectionClassesFor (rangeName, range) {
      if (range === null) {
        return {}
      } else {
        if (this.day.isSame(range.start, 'day')) {
          return { [`${rangeName}-start`]: true }

        } else if (this.day.isSame(range.end, 'day')) {
          return { [`${rangeName}-end`]: true }

        } else if (range.contains(this.day, { exlcusive: true })) {
          return { [`${rangeName}-within`]: true }
        }
      }
    }
  }
}
</script>
