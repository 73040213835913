/* eslint no-console:0 */
// This file is the entry point for the application Webpack bundle
// To reference this file, add <%= javascript_include_tag 'application' %>
// to the appropriate layout file

require('./dependencies');
require('./hubspot');
require('./navigator');
require('./extensions');
require('./core');
require('./billing');
require('./deposits');
require('./flexible_deposits');
require('./purchases');
require('./reservations');
require('./browse-homes');
require('./location-search');
require('./share-button');
require('./stimulus-controllers');
require('./stay-request-calendar');
require('./property-page');
require('./property-photos');
require('./week-posting');
require('./reviews');
require('./analytics');
require('./identity-assumption');
require('./development_helpers');
require('./notification-tray');
require('./backup-reservations');
require('./travel_profile');
require('./new-user-availabilities');

require('./init/turbo'); // This line must be last.

import "./stylesheets/application.scss"

// Images
require.context('./images', true)
// Include Shared Images
require.context('thirdhome-styles/images/', true, /\.(png|jpe?g|svg)$/)
