import conversations from './conversations';
import tracker from './tracker';

const configElement = document.getElementById('tracker-config');

const config = configElement ? JSON.parse(configElement.innerText) : {};

if (config.conversationId && config.track) {
  if (config.email) {
    tracker.identify({email: config.email});
  }
  conversations.start(config.conversationId);
  $(document).on('click', '.live-chat-open', (e) => {
    e.preventDefault();
    conversations.open();
  });
}

const trackingEventElem = document.querySelector('.flash-hs-tracking-event')

if (trackingEventElem) {
  tracker.trackEvent(trackingEventElem.getAttribute('data-event-name'));
}
