var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input calendar-input hide",
      staticStyle: { "pointer-events": "none" }
    },
    [
      _c("label", [_vm._v("Start Date")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formattedDate,
            expression: "formattedDate"
          }
        ],
        attrs: { type: "text", placeholder: "Select a calendar day" },
        domProps: { value: _vm.formattedDate },
        on: {
          change: function($event) {
            return _vm.$emit("dateSelected", _vm.dateInput)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.formattedDate = $event.target.value
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }