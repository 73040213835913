<template lang='pug'>
  .vue-template-wrapper
    Deposit(:deposit='parent'
            :checked='depositSelected(parent)'
            v-on:checkboxToggle='handleParentCheckboxToggle')
    .children(v-if='parent.children')
      Deposit(v-for='deposit of parent.children'
              :key='deposit.id'
              v-on:checkboxToggle='handleChildCheckboxToggle'
              :deposit='deposit'
              :checked='depositSelected(deposit)')
</template>

<script>
import Deposit from './Deposit.vue'

export default {
  components: { Deposit },
  props: {
    parent: { type: Object, required: true },
    selectedDeposits: { type: Object, required: true }
  },
  methods: {
    handleParentCheckboxToggle (deposit) {
      const selected = this.selectedDeposits[deposit.identifier]
      
      if (selected === deposit) {
        this.setStay(deposit.identifier, null)
      } else {
        this.setStay(deposit.identifier, deposit)
        this.parent.children.forEach(child => this.setStay(child.identifier, null))
      }
    },
    handleChildCheckboxToggle (deposit) {
      const selected = this.selectedDeposits[deposit.identifier]

      if (selected === deposit) {
        this.setStay(deposit.identifier, null)
      } else {
        this.setStay(deposit.identifier, deposit)
        this.setStay(this.parent.identifier, null)
      }
    },
    setStay (id, deposit) {
      this.$emit('staySelected',id, deposit)
    },
    depositSelected (deposit) {
      return this.selectedDeposits[deposit.identifier] === deposit ? true : false
    }
  }
}
</script>
