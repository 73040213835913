var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("search-box", {
        attrs: {
          placeholder: _vm.placeholderName,
          propertySearch: _vm.propertySearch,
          defaultPlaceHolder: _vm.defaultPlaceHolder,
          results: _vm.results
        },
        on: { selected: _vm.loadPlace, query: _vm.queryResults }
      }),
      _c(
        "a",
        {
          staticClass: "search-choice-close input-clear",
          class: { hide: _vm.hideClose },
          on: { click: _vm.clearWhereToText }
        },
        [_vm._m(0)]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "close" }, [
      _c("div", { staticClass: "x" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }