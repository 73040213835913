// Throw an error if we get anything other
// than a 200 response.
const handleErrors = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    let error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

export const updateUserNotifications = (notificationUrl, params, onSuccess) => {
  fetch(notificationUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify(params)
  })
  .then(handleErrors)
  .then(resp => resp.json())
  .then(onSuccess)
  .catch(error => console.log("There was an error: ", error))
}

