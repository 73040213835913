import { expand } from './columnar-json';
import { randomizeCoords } from './randomizeCoords';
import omit  from 'lodash/omit';

function buildProperties (obj) {
  return omit(obj, ['lat', 'lng']);
}

function fromObjects (objects) {
  const features = objects.map(function (obj) {
    let { lng, lat } = obj;

    if (obj.map_mode === 'fuzzy') {
      [lng, lat] = randomizeCoords(lng, lat);
    }

    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lng, lat]
      },
      properties: buildProperties(obj)
    }
  });

  return {
    type: "FeatureCollection",
    features: features
  }
}

function fromColumns (rows, columns) {
  return fromObjects(expand(rows, columns));
}

const defaultExports = {
  fromColumns: fromColumns,
  fromObjects: fromObjects
}

export default defaultExports;
