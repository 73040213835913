import { Controller } from "@hotwired/stimulus"
import introJs from 'intro.js/intro.js';

export default class extends Controller {
  static values = {
    hints: Object
  }

  static targets = ['exampleAvailability']

  initialize() {
    this._removeExampleAvailabilityAnchors()
    this._startHints()
  }

  _startHints() {
    this.intro = introJs().setOptions(this.hintsValue)
    this.intro.showHints();
  }

  _removeExampleAvailabilityAnchors() {
    Array.from(this.exampleAvailabilityTarget.getElementsByTagName('a')).forEach((el) => el.removeAttribute('href'))
  }
}