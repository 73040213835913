const methods = {
  init (elements, content = null) {
    return $(elements).tipso({
      content: content,
      background: "#000",
      titleBackground: "#000",
      onShow: function (e, el) {
        const event = new Event('tipso-show');
        el.dispatchEvent(event);
      }
    }).on('tipso-show', function (e) {
      if (isMobile) {
        setTimeout((() => $(this).tipso('hide')), 5000);
      }
    });
  },
  destroy (elements) {
    $(elements).tipso('destroy')
  }
}

export default methods
