import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['regionField', 'countryField']
  }
  static values = {
    useRegionNameForSelect: Boolean,
    existingState: String
  }
  initialize() {
    this.stateMap = JSON.parse(document.getElementById('region-selections').innerText);
  }

  countryFieldTargetConnected(e) {
    if(e.value) {
      e.dispatchEvent(new Event('change'))
      this.regionFieldTarget.value = this.existingStateValue
    }
  }

  changeCountry (e) {
    const newCountry = e.target.value;
    if (!newCountry) return;
    const newStates = this.stateMap[newCountry];
    if (!newStates) {
      this.regionFieldTarget.innerHTML = '<option></option>';
      this.regionFieldTarget.disabled = true
      return
    };
    this.regionFieldTarget.disabled = false
    const options = newStates.map((state) => {
      const name = state.region.name
      const value = this.useRegionNameForSelectValue ? name : state.region.id
      return `<option value="${value}">${name}</option>`
    });
    options.unshift('<option></option>');
    this.regionFieldTarget.innerHTML = options.join('');
  }
}
