import { Controller } from "@hotwired/stimulus";
import stripeLoader from '../../purchases/stripe-loader'

const defaultStyles = {
  style: {
    base: {
      color: '#797979',
      fontFamily: '"OpenSans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, Arial, sans-serif',
    },
    empty: {
      color: '#797979',
      fontFamily: '"OpenSans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, Arial, sans-serif',
      '::placeholder': {
        color: 'rgba(121, 121, 121, 0.64)'
      }
    }
  }
}

export default class extends Controller {
  static targets = [ 'element', 'name', 'cardWrapper',
                     'nameWrapper', 'localError', 'stripeError' ]
  static classes = [ 'invalid' ]

  initialize() {
    stripeLoader.then((stripe) => {
      this.stripe = stripe
      const elements = this.stripe.elements()
      this.card = elements.create('card', defaultStyles);
    })
  }

  connect() {
    this.localErrors = []
    this.stripeErrors = []

    stripeLoader.then((stripe) => {
      if(this.card) this.card.unmount();
      this.card.mount(this.elementTarget)
      this.card.on('ready', () => {
        if($(this.elementTarget).is(":visible")) {
          this.card.focus()
        }
      })
    })
  }

  submit() {
    if(this.validate()) {
      return this.fetchToken()
    } else {
      return Promise.resolve(false)
    }
  }

  async fetchToken() {
    const {error, token} = await this.stripe.createToken(this.card)

    if(error) {
      this.stripeErrors = [error]
      this.showErrors()
      throw new Error(error.message)
    } else {
      return token.id
    }
  }

  validate() {
    this.clearErrors()

    let nameValue = this.nameTarget.value?.trim();

    if(nameValue) { return true }

    this.localErrors = ["Name on card must be supplied."]
    this.showErrors()
    this.nameTarget.focus();
    return false
  }

  showErrors() {
    if(this.stripeErrors.length > 0) {
      this.stripeErrorTarget.innerHTML = this.stripeErrors.map(e => e.message).join('. ')
      this.cardWrapperTarget.classList.add(this.invalidClass)
    }
    if(this.localErrors.length > 0) {
      this.localErrorTarget.innerHTML = this.localErrors
      this.nameWrapperTarget.classList.add(this.invalidClass)
    }
  }

  clearErrors() {
    this.stripeErrorTarget.innerHTML = ""
    this.localErrorTarget.innerHTML = ""
    this.cardWrapperTarget.classList.remove(this.invalidClass)
    this.nameWrapperTarget.classList.remove(this.invalidClass)
  }
}
