import Vue from 'vue';
import init from '../init';
import DepositsSelection from './DepositsSelection.vue';

init.pageLoad('inflexible-deposit-page',{
  load () {
    /* eslint-disable no-new */
    new Vue({
      el: document.getElementById('deposits-container'),
      components: { DepositsSelection },
    })
  }
});
