import onboardingPropertyMap from './onboarding_property';
import userSignupMap from './user_signup';

prefills = new Map([...onboardingPropertyMap].concat([...userSignupMap]));

$(document).on 'click', 'a.development-test-filler', (e) ->
  e.preventDefault()
  testFields = prefills.get($(@).data('test-fields'))

  testFields.forEach (val, selector) ->
    if (typeof val == "function")
       val(selector)
     else
       $set = $(selector)
       $set.val(val)
     $set ||= $(selector)
     $set.each (i,e) ->
       e.dispatchEvent(new Event('change', {'bubbles': true}))
