import Vue from 'vue'
import NotificationTray from './NotificationTray.vue'
import init from '../init'

init.onLoad(function () {
  const el = document.getElementById('notification-tray')

  if (el) {
    /* eslint-disable no-new */
    new Vue({
      el: el,
      components: { NotificationTray },
    })
  }
});
