import Vue from 'vue'
import VueResource from 'vue-resource'
import Vue2TouchEvents from 'vue2-touch-events'
import TurbolinksAdapter from 'vue-turbolinks'
import { Controller } from "@hotwired/stimulus"
import { createStore } from "../../vuex-store/index.js"
import CounterOffer from "../../week-request-counter-offer/CounterOffer.vue"

Vue.use(VueResource)
Vue.use(Vue2TouchEvents)
Vue.use(TurbolinksAdapter)

export default class extends Controller {
  static targets = ['component']

  connect () {
    this.vueComponent = new Vue({
      el: this.componentTarget,
      components: { CounterOffer },
      store: createStore()
    })
  }
  disconnect () {
    const component = this.vueComponent
    setTimeout(() => {
      component.$destroy()
    }, 200)
    this.vueComponent = null
  }
}
