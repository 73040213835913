import { Controller } from "@hotwired/stimulus";
import { send } from "../../app/bridge";

export default class extends Controller {
  connect() {
    try {
      const message = JSON.parse(this.element.innerText)
      this.sendMessage(message)
    } finally {
      this.element.remove()
    }
  }
  sendMessage(message) {
    send(message)
  }
}
