import dateFormatting from '../tools/date_formatting.js';
import formatCurrency from '../tools/format_currency';

class Week
  constructor: (date) ->
    dateRange = dateFormatting.formatDateRange(date.starts_on, date.ends_on)
    @startsOn = date.starts_on
    @keys = date.keys
    @fee = date.price
    @makeOffer = ko.observable(false)
    @description = ko.computed =>
      label = "#{dateRange}"
      if !@makeOffer()
        label += ": #{@keys} Keys / #{formatCurrency(@fee)}"
      label

export default Week;