<template lang="pug">
.browse-homes(:class="{ 'logged-in': loggedIn }")
  #property-search(v-if="stateLoaded")
    .small-container.search-container
      div(data-step="1" data-position="top" data-title="Choose your destination" data-intro="Type in a place you want to go. <em>Example: Europe.</em>")
        .tail-background(data-step="2" data-tooltip-class="available-tip" data-position="top" data-title="Choose your timeframe" data-intro="Add a date range (the broader the better). <em>HINT: The more flexible you are, the better the club will work for you.</em>")
          .base-form.search-bar.medium-show
            .form.grid.collapse-padding
              .cell.twelve.medium-fill.destination-container
                label.destination-label(:class="{ selected: destinationSelected }")
                  | {{ destinationSelectionText }}
                BrowseSearch(:zones-url="zonesUrl" :property-url="propertyUrl")
              StayDuration(v-if='canViewAvailability' parent="main")
              BedroomCount
              .cell.fit.submit-container.medium-show
                button(class='button')
                  span(class='third-font-search-glyph')
  #combined-results.large-container
    SearchResults(
      :zonesUrl="zonesUrl", 
      :introJsEnabled="introJsEnabled", 
      :savedSearches="savedSearches", 
      :ypoUser="ypoUser", 
      :ypoOnly="ypoOnly", 
      :promoFilterFeatureActive="promoFilterFeatureActive",
      :promotionSlug="promotionSlug"
    )
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import BrowseSearch from '../location-search/BrowseSearch.vue'
import StayDuration from './StayDuration.vue'
import BedroomCount from './BedroomCount.vue'
import SearchResults from './results/SearchResults.vue'

export default {
  components: {
    BrowseSearch,
    StayDuration,
    BedroomCount,
    SearchResults
  },
  props: {
    canViewAvailability: { type: Boolean, required: true },
    introJsEnabled: {type: Boolean, default: false },
    loggedIn: { type: Boolean, default: false },
    promoFilterFeatureActive: { type: Boolean, default: false },
    promotionSlug: { type: String, required: false },
    propertyUrl: {type: String },
    savedSearches: { type: Array, default: () => [] },
    searchResponse: { type: Object, required: true },
    ypoOnly: {type: Boolean, default: false},
    ypoUser: {type: Boolean, default: false},
    zonesUrl: { type: String, required: true },
  },
  data () {
    return { 
      stateLoaded: false, 
    }
  },
  computed: {
    ...sync('browseHomes', {
      destination: 'destination',
      word_search: 'filters@word_search',
    }),
    ...get('browseHomes', ['destinationSelected', 'destinationSelectionText'])
  },
  methods: {
    ...call('browseHomes', {
      loadFromSearchResponse: 'loadFromSearchResponse',
      setSavedSearches: 'setSavedSearches',
      setLoggedIn: 'setLoggedIn',
      setMapHash: 'setMapHash',
      clearDestination: 'clearDestination',
      updateUrl: 'updateUrl',
      fetch: 'fetch'
    })
  },
  async created () {
    await this.setLoggedIn(this.loggedIn)
    if (window.location.hash.length > 0) {
      await this.setMapHash(window.location.hash)
    }
    await this.loadFromSearchResponse(this.searchResponse)
    this.stateLoaded = true
    this.setSavedSearches(this.savedSearches)
  }
}
</script>
