import { make } from 'vuex-pathify'

const state = {
	message: '',
	starts_on: null,
	ends_on: null,
	keys: null
}

const getters = {
	...make.getters(state)
}

const actions = {
	...make.actions(state)
}

const mutations = {
	...make.mutations(state)
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
