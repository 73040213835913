map = new Map()

map.set('onboarding-property-name-filler', new Map([
  ["#property_pending_name", (selector) ->
    $(selector).val("Test Property #{dayjs().format("MMM Do YYYY")}")
  ],
  ["#property_street1", "206 Washington St SW"],
  ["#property_city", "Atlanta"],
  ["#property_region_id", (selector) -> $("#{selector} option:contains(Georgia)").attr('selected', 'selected')],
  ["#property_zip", "30334"],
  ["#property_nearest_airport", "ATL"],
  ["#property_distance_from_airport", "30 minutes by car"]]
))

map.set('onboarding-property-details-filler', new Map([
  ['#property_property_style', (selector) -> $("#{selector} option:contains(Private Residence)").attr('selected', 'selected')],
  ['#property_property_type', (selector) -> $("#{selector} option:contains(Beach)").attr('selected', 'selected')],
  ['#property_user_property_value', '500000'],
  ['#bedrooms', (selector) ->
    $(selector).val(2)
    $(selector).trigger('blur')
  ],
  ['#property_baths', '2'],
  ['#property_levels', '2'],
  ['#property_square_feet', '2500'],
  ['#property_max_occupancy', '6'],
  ['#property_high_speed_internet', (selector) ->
    $(selector).prop('checked', true).trigger('change')
  ],
  ['#property_view_from_property', 'A beautiful lake and some mountains.']]
))

map.set('onboarding-property-nearby-filler', new Map([
  ['#property_place_interest_points_attributes_0_description', 'A nearby farmer\'s market'],
  ['#property_can_do_hiking', (selector) ->
    $(selector).prop('checked', true).trigger('change')
  ],
  ['#property_can_do_fishing', (selector) ->
    $(selector).prop('checked', true).trigger('change')
  ],
  ['#property_activity_interest_points_attributes_0_description', 'Antiquing']]
))


map.set('onboarding-property-description-filler', new Map([  
  ['#property_description', (selector) ->

      s = """   The Rivertrees Estate offers a rare   combination of
      architectural luxury, magnificent panoramic views and secluded
      riverside living while being close to wilderness boundary. The property
      is   accessed from Pine Creek Road and passes an elegant stone gate. The
      thick   forest of the Pine Creek riparian zone creates a magnificent
      corridor towards   the house and river beyond. The two homes lie 50
      yards ahead and are situated   amongst tall cottonwoods and lush ground
      vegetation. Past the house a mere 40   feet is the East Flank of the
      Yellowstone River which runs 800 feet along its   banks. Also flowing
      out of the Absaroka mountains and wilderness area, pine   creek creates
      the north border of the estate for nearly a mile. This quietly
      isolated stretch of the famed Yellowstone is as private as waterfront
      gets.   All the buildings on the property are designed for entertaining,
      sophisticated   living and family gatherings with an unforgettable
      riverfront experience. This   private gated estate directly on the
      Yellowstone River is located just outside   of Livingston, Montana and
      less than 45 minutes to the entrance of Yellowstone   Park. The home has
      a fully equipped fitness center, the master has a steam   room and the
      professional chefs kitchen is impeccably designed for a beginner   or a
      professional. The home has a main house with the first three bedrooms
      and   the guest house has the second three bedrooms. """

      $(selector).val(s.replace(/\s+/g, " "))
  ]]
))

export default map;
