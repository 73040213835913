<template lang='pug'>
div
  search-box(
    placeholder="Where to?" :results="results"
    @selected="selected" @active="active" @query="queryResults"
  )
</template>

<script>
import SearchBox from './SearchBox.vue';
import AutocompleteSearch from './autocomplete_search';
import { navigate } from '../navigator';
import FetchLoader from '../tools/fetch-loader';
import PropertiesSource from './sources/properties';
import ZonesSource from './sources/zones';
import placesSource from './sources/places';
import keywordSource from './sources/keyword';

export default {
  data () {
    return {
      autocompleteSearch: null,
      results: [],
      _loadedSuggested: false
    }
  },
  created () {
    const zonesSource = new ZonesSource(this.zonesUrl);
    const propertiesSource = new PropertiesSource(this.propertyUrl);
    const sources = [propertiesSource, zonesSource, placesSource, keywordSource];
    this.autocompleteSearch = new AutocompleteSearch(sources);
    if (this.suggestedUrl) {
      this.suggestedFetch = new FetchLoader({
        fetchArgs: [this.suggestedUrl],
        parser (json) {
          const searches = json.searches;

          const searchEntries = searches.map((search) => {
            return {
              data: { id: search.id },
              description: search.summary,
              path: search.path,
              type: 'search'
            }
          });

          return searchEntries;
        }
      });
    }
  },
  props: {
    propertyUrl: {required: true},
    zonesUrl: {required: true},
    suggestedUrl: {required: false}
  },
  components: { SearchBox },
  methods: {
    queryResults (inputString) {
      if (inputString) {
        this.autocompleteSearch.search(inputString, (err, results) => {
          if (err) return this.$emit('error', err);
          this.results = results;
        });
      } else {
        this.clearResults();
      }
    },
    selected (choice) {
      navigate(choice.path);
    },
    active () {
      this._loadSuggested();
    },
    _loadSuggested () {
      if (!this.suggestedFetch) return;
      this.suggestedFetch.load().then((searches) => this.results = searches);
    },
    clearResults () {
      if (this.suggestedFetch) {
        this._loadSuggested();
      } else {
        this.results = [];
      }
    }
  }
}
</script>
