var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { attrs: { action: _vm.formatConfirmUrl(), method: "post" } },
    [
      _c("input", {
        attrs: { type: "hidden", name: "authenticity_token" },
        domProps: { value: _vm.csrf }
      }),
      _vm._l(_vm.sortedDeposits, function(deposit) {
        return _c("Deposit", {
          key: deposit.identifer,
          attrs: {
            deposit: deposit,
            requireConfirmationIds: _vm.requireConfirmationIds,
            ownerCanNyop: _vm.ownerCanNyop
          },
          on: { removeDeposit: _vm.depositRemoved }
        })
      }),
      _c(
        "div",
        {
          staticClass: "agree-to-terms-container",
          staticStyle: { "margin-top": ".5rem" }
        },
        [
          _c(
            "div",
            {
              staticClass: "input check_boxes",
              staticStyle: { "margin-bottom": "0" }
            },
            [
              _c("span", { staticClass: "checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.checkedTerm2,
                      expression: "checkedTerm2"
                    }
                  ],
                  attrs: {
                    type: "checkbox",
                    name: "agree_to_liability_for_week_reservation",
                    id: "agree_to_liability_for_week_reservation",
                    value: "3"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.checkedTerm2)
                      ? _vm._i(_vm.checkedTerm2, "3") > -1
                      : _vm.checkedTerm2
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.checkedTerm2,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "3",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.checkedTerm2 = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.checkedTerm2 = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.checkedTerm2 = $$c
                      }
                    }
                  }
                }),
                _vm._m(0)
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "input check_boxes",
              staticStyle: { "margin-bottom": "0" }
            },
            [
              _c("span", { staticClass: "checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.checkedTerm3,
                      expression: "checkedTerm3"
                    }
                  ],
                  attrs: {
                    type: "checkbox",
                    name: "agree_to_liability_for_cancel_reservation",
                    id: "agree_to_liability_for_cancel_reservation",
                    value: "2"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.checkedTerm3)
                      ? _vm._i(_vm.checkedTerm3, "2") > -1
                      : _vm.checkedTerm3
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.checkedTerm3,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "2",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.checkedTerm3 = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.checkedTerm3 = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.checkedTerm3 = $$c
                      }
                    }
                  }
                }),
                _vm._m(1)
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "input check_boxes",
              staticStyle: { "margin-bottom": "0" }
            },
            [
              _c("span", { staticClass: "checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.checkedTerm1,
                      expression: "checkedTerm1"
                    }
                  ],
                  attrs: {
                    type: "checkbox",
                    name: "agree_to_liability_for_reservation",
                    id: "agree_to_liability_for_reservation",
                    value: "1"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.checkedTerm1)
                      ? _vm._i(_vm.checkedTerm1, "1") > -1
                      : _vm.checkedTerm1
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.checkedTerm1,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "1",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.checkedTerm1 = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.checkedTerm1 = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.checkedTerm1 = $$c
                      }
                    }
                  }
                }),
                _vm._m(2)
              ])
            ]
          ),
          _c("input", {
            staticClass: "button full",
            attrs: {
              type: "submit",
              value: "Confirm Weeks",
              "data-disable-with": "Processing...",
              disabled: _vm.confirmButtonDisabled
            }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { attrs: { for: "agree_to_liability_for_week_reservation" } },
      [
        _c(
          "p",
          { staticClass: "small", staticStyle: { "margin-bottom": "0.5rem" } },
          [
            _vm._v(
              "I’ve set aside this week solely for ThirdHome, so there’s no risk of double booking. I understand I must honor the week once booked by a ThirdHome member."
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { attrs: { for: "agree_to_liability_for_cancel_reservation" } },
      [
        _c(
          "p",
          { staticClass: "small", staticStyle: { "margin-bottom": "0.5rem" } },
          [
            _vm._v(
              "If I cancel on a Guest, I understand I am responsible for any costs incurred by refunding the Guest’s Keys and Exchange Fee."
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { attrs: { for: "agree_to_liability_for_reservation" } },
      [
        _c("p", { staticClass: "small" }, [
          _vm._v("I agree to "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.thirdhome.com/terms-and-conditions/",
                target: "_blank"
              }
            },
            [_vm._v("ThirdHome's Terms and Conditions.")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }