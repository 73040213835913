map = new Map()

map.set('user-signup-filler', new Map([
  ["#user_first_name", "Test"],
  ["#user_last_name", "User"],
  ["#user_phone", "+17704761323"],
  ["#user_email", (selector) ->
    rand = Math.round(Math.random() * 1000)
    $(selector).val("user+#{rand}@example.org")
  ],
  ["[type='password']", (selector) ->
    el = document.querySelector(selector)
    return if !el
    el.value = "pass word"
    e = new Event("input", el)
    el.dispatchEvent(e)
  ],
  ["#user_street1", "1007 E Grand Ave."],
  ["#user_city", "Des Moines"],
  ["#user_state", "IA"],
  ["#user_zip", "50319"],
  ['#privacy_and_terms_agreement', (selector) ->
    $(selector).prop('checked', true)
  ]
]))

export default map;
