var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.depositClasses }, [
    _c(
      "label",
      {
        staticClass:
          "single-checkbox-container grid collapse align-center hold collapse-padding"
      },
      [
        _c("div", { staticClass: "cell checkbox-column fit" }, [
          _c("div", { staticClass: "input check_boxes" }, [
            _c("span", { staticClass: "checkbox" }, [
              _vm.deposit.depositable
                ? _c("input", {
                    attrs: { type: "checkbox" },
                    domProps: { checked: _vm.checked },
                    on: { click: _vm.toggleCheck }
                  })
                : _vm._e(),
              _c("label")
            ])
          ])
        ]),
        _c("div", { staticClass: "cell date-range collapse" }, [
          _c("span", { staticClass: "third-font-calendar" }),
          _c("span", { staticClass: "starts-on date" }, [
            _vm._v(_vm._s(_vm.formattedRange.formattedStart))
          ]),
          _vm._v("\nto\n"),
          _c("span", { staticClass: "ends-on date" }, [
            _vm._v(_vm._s(_vm.formattedRange.formattedEnd))
          ])
        ]),
        _c("div", { staticClass: "cell status six medium-fit text-center" }, [
          _c("small", [_vm._v(_vm._s(_vm.deposit.description) + " ")])
        ]),
        _c(
          "div",
          { staticClass: "cell four medium-two text-right medium-text-center" },
          [
            _c("div", { staticClass: "pill tiny season" }, [
              _vm._v(_vm._s(_vm.deposit.season.replace(/_/g, " ")))
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "cell key-count-column two medium-one text-right" },
          [
            _c(
              "span",
              { staticClass: "key-count", attrs: { title: _vm.keyCountTitle } },
              [
                _c("span", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.deposit.keys))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "third-font-key-glyph" })
              ]
            ),
            _vm.showTipso
              ? _c(
                  "span",
                  {
                    staticClass: "tipso_styles asterisk",
                    attrs: {
                      "data-tipso":
                        "You will not earn upfront keys for this week. You'll earn keys once it's booked."
                    }
                  },
                  [_vm._v("✱")]
                )
              : _vm._e()
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }