async function getPropertyTooltips (features) {
  const propertyIds = [...new Set(features.map(f => f.properties.id))];
  const url = `/map_properties/property_cards?property_ids=${propertyIds}`;
  const response = await fetch(url, {
    headers: {
      'Accept': 'text/html'
    }
  });
  const status = response.status;
  if (status != 200) { throw { tooltipResponse: status }};

  return await response.text();
}

export default getPropertyTooltips;
