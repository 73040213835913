const keywordSource = {
  search(value) {
    return new Promise((resolve) => {
      if (value === '') return resolve([]);

      return resolve([{
        data: {keyword: value},
        description: `keyword search "${value}"`,
        path: `/properties/search?keyword=${value}`,
        type: 'keyword',
      }]);
    })
  }
}

export default keywordSource;
