import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['display', 'slider', 'toggle']
  static values = { sliderProperties: Object }

  initialize() {
    this.setupSliders()
  }

  // Called on initialize and when unit is changed  
  setupSliders() {
    this._toggleSlider()
    this._setIncrementSteps()
    this._setNumberFieldInputValues()
    this._setDefaults()
  }

  // Update slider on drag
  connect() {
    this.sliderTarget.addEventListener("input", () => {
      this._updateAllDisplays(this.sliderTarget.value);
      cleanInputErrorBlock('property_application_area_errors');
    });
  }

  // Called when slider is dragged - Snap value to nearest step
  snap({target}) {
    const value = target.value
    const output = this.steps.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    this._updateAllDisplays(output)
  }

  // Called when number is input manually - No snap update slider
  updateValueDisplay({target}) {
   this._updateAllDisplays(parseInt(target.value) || 0)
   cleanInputErrorBlock('property_application_area_errors');
  }

  // Update slider properties (min, max, slider notches) when toggling unit between ft/m
  _toggleSlider() {
    const unit = this.toggleTarget.value
    this.currentSliderProperties = this.sliderPropertiesValue[unit]
    const {sliderMin, max, step, dataList} = this.currentSliderProperties
    this.sliderTarget.min = sliderMin
    this.sliderTarget.max = max
    this.sliderTarget.step = step
    this.sliderTarget.setAttribute('list', dataList)
  }

  _setDefaults() {
    if (this.displayTarget.value == ''){
      this.displayTarget.value = this.sliderTarget.min
    }
    this._updateAllDisplays(this.displayTarget.value)
  }

  // Sets up the steps for the slider snapping
  _setIncrementSteps() {
    const step = this.sliderTarget.step
    const min = parseInt(this.sliderTarget.min)
    const max = parseInt(this.sliderTarget.max)
    const steps = (max - min) / step + 1

    this.steps = Array.from({length: steps}, (_value, index) => index * step + min)
  }
  
  // Sets validation values on the number field for form submission
  _setNumberFieldInputValues() {
    this.displayTarget.max = this.sliderTarget.max
    this.displayTarget.size = this.sliderTarget.max.length
  }

  // Update both the slider position and the visual number box
  _updateAllDisplays(value) {
    const containedValue = Math.min(Math.max(value, this.sliderTarget.min), this.sliderTarget.max);
    this.sliderTarget.value = containedValue
    this.displayTarget.value = containedValue
  }
}

function cleanInputErrorBlock(id){
  var el = document.getElementById(id);
    
  if(el != null || el != undefined){
    el.style.display = 'none'
  }
}
