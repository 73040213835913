function expand (rows, columns) {
  return rows.map(function (row) {
    return columns.reduce(function (rowObj, columnName, index) {
      rowObj[columnName] = row[index];
      return rowObj;
    }, {});
  });
}

export { expand };
