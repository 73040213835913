import { Controller } from "@hotwired/stimulus";
import mapboxLoader from '../../maps/mapbox';
import MapDataset from '../../maps/map-dataset';
import ClusterMap from '../../maps/cluster-map';
import debounce from 'lodash/debounce'


export default class extends Controller {
  static targets = ['availabilityToggle'];

  initialize () {
    this.filteredData = new MapDataset('/map_properties?with_availability=1');
    this.fullData = new MapDataset('/map_properties');
    this.resize = debounce(this._ensureMobileHeight, 250);
    this.hasUser = this.data.get('has-user') == 'true' ? true : false

    let viewport = this.data.get('viewport');
    if (viewport) {
      viewport = viewport.split(',');
    }
    let center = this.data.get('center');
    if (center) {
      center = center.split(',');
    }

    this.clusterMap = new ClusterMap({
      element: this.element,
      viewport: viewport,
      center: center,
      zoom: this.data.get('zoom'),
      hasUser: this.hasUser
    });
    this.clusterMap.on('move', (map, data) => {
      this._updateMapSettings(data);
    });
  }

  connect () {
    this._ensureMobileHeight();
    if (this.hasAvailabilityToggleTarget) {
      this.filtered = this.availabilityToggleTarget.checked;
    }

    this.dataset.load().then((data) => {
      this.clusterMap.start({
        data: data
      });
    });
  }

  disconnect () {
    this.clusterMap.remove();
  }

  refreshResults () {
    this.dataset.load().then((data) => {
      this._setMapData(data);
    });
  }

  get dataset () {
    return (this.filtered) ? this.filteredData : this.fullData;
  }

  toggleFilter (event) {
    this.filtered = this.availabilityToggleTarget.checked;

    this.refreshResults();
  }

  _setMapData (data) {
    this.clusterMap.updateData(data);
  }

  _ensureMobileHeight () {
    if (!window.isMobile) return;
    if (!window.visualViewport) return;

    const visibleHeight = window.visualViewport.height;
    const navElem = document.querySelector('.main-nav')
    const navOffset = navElem.offsetHeight;
    const mainElement = document.querySelector('.main');

    mainElement.style.height = `${visibleHeight}px`;
    this.element.style.height = `${visibleHeight - navOffset}px`;

    window.scrollTo(0,0);
    this.clusterMap.resize();
  }

  _updateMapSettings (data) {
    const {lat, lng, zoom} = data;
    this.data.set('center', `${lng},${lat}`);
    this.data.set('zoom', zoom);
  }
}
