<template lang='pug'>
  .deposit-date-picker
    .grid.collapse-padding.align-center
      .cell.fit.collapse
        a(class='arrow left'
        v-if='showPreviousArrow'
        @click="$emit('previousMonth')") 
          .notch
      .cell.fill
        h5.no-bottom-margin
          | {{calLabel}}
      .cell.fit.collapse
        a(class='arrow right'
          v-if='showNextArrow'
          @click="$emit('nextMonth')")
          .notch
    .grid.collapse-padding
      .cell.day.collapse Sun
      .cell.day.collapse Mon
      .cell.day.collapse Tue
      .cell.day.collapse Wed
      .cell.day.collapse Thu
      .cell.day.collapse Fri
      .cell.day.collapse Sat
    .days.grid.collapse-padding(v-for='week of monthWeeks')
      CalendarDay(v-for='day of week'
                  :key='day.$index'
                  :day='day'
                  :active='active(day)'
                  :invalid='invalid(day)'
                  :valid='valid(day)'
                  :deposits='overlappingDeposits'
                  :depositRange="depositRange"
                  @dateSelected='dateSelected',
                  :seasonality='seasonality',
                  :keyEarningStartDate='keyEarningStartDate'
                  )

</template>

<script>
import CalendarDay from './CalendarDay.vue'

export default {
  components: { CalendarDay },
  props: {
    showPreviousArrow: { type: Boolean },
    showNextArrow: { type: Boolean },
    startDay: { type: String, required: true },
    selectedDate: { type: String, required: true },
    deposits: { type: Array, required: true },
    depositRange: { required: true },
    seasonality: { required: true },
    keyEarningStartDate: {required: true},
    selectedRange: {}
  },
  methods: {
    dateSelected (date) { this.$emit('dateSelected', date); },
    active (day) {
      if(day === '') { return false; }
      return day.format('YYYY-MM-DD') == this.selectedDate;
    },
    valid(day) {
      if(day === ''|| !this.selectedRange.valid) { return false; }
      return this.selectedRange.range.contains(day)
    },
    invalid (day) {
      if(day === '' || !(this.selectedRange?.valid == false)) { return false; }
      return this.selectedRange.range.contains(day)
    },
  },
  computed: {
    range () {
      return dayjs.range(this.startDay, this.endDay)
    },
    endDay () {
      return dayjs(this.startDay).endOf('month').format('YYYY-MM-DD')
    },
    overlappingDeposits () {
      return this.deposits.filter(
        deposit => !((deposit.starts_on > this.endDay) || (deposit.ends_on < this.startDay))
      );
    },
    monthWeeks () {
      let monthWeeks = Array.apply(null, Array(6)).map(function() {
        return ['', '', '', '', '', '', ''];
      });

      const offset     = parseInt(this.mStart.day()) - 1;
      const monthRange = this.range;
      for(let day of monthRange.by('day')) {
        const dayOfWeek   = parseInt(day.day());
        const dayOfMonth  = parseInt(day.format('D'));
        const weekOfMonth = parseInt((offset + dayOfMonth) / 7);

        monthWeeks[weekOfMonth][dayOfWeek] = day;
      };

      const lastWeekEmpty = monthWeeks[5].reduce((a, b) =>
        (a === b) ? a : NaN
      ) === '';

      if(lastWeekEmpty) {
        monthWeeks.splice(5,1);
      }

      return monthWeeks;
    },
    mStart () { return dayjs(this.startDay); },
    calLabel () { return this.mStart.format('MMMM YYYY'); }
  }
}
</script>
