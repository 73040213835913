<template lang="pug">
.cell.twelve.medium-hide.search-type
  h5.no-bottom-margin Search Type
  .input.radio_buttons
    span.radio(for="prop-select" :class="labelClass('properties')")
      input(type="radio"
            name="search_type"
            value="properties"
            :checked="radioSearchType !== 'availability'"
            @input="selectSearchType"
            id="prop-select")
      label(for="prop-select")
        | Properties
        br
        small Browse by properties with or without availability.

    span.radio(for="search-select" :class="{ 'option-disabled': disableAvailability }" ref="availabilityLabel")
      input(type="radio"
            name="search_type"
            value="availability"
            :checked="radioSearchType === 'availability'"
            @input="selectSearchType"
            id="search-select"
            :disabled="disableAvailability")
      label(for="search-select")
        | Availability
        sup.availability-popup(ref="availabilityPopup"
                            v-show="disableAvailability") ?
        br
        small Browse by unique stays.

    span.radio(for="map-select" :class="labelClass('properties')")
      input(type="radio"
            name="search_type"
            value="map"
            :checked="radioSearchType === 'map'"
            @input="selectSearchType"
            id="map-select")
      label(for="map-select")
        | Map
        br
        small Browse by map.

</template>

<script>
import { get, sync, call } from 'vuex-pathify'

export default {
  data () {
    return {
      $label: null,
      radioSearchType: null
    }
  },
  computed: {
    ...sync('browseHomes', {
      search_type: 'filters@search_type',
      canViewAvailability: 'canViewAvailability',
      loggedIn: 'loggedIn'
    }),
    disableAvailability () {
      return !this.canViewAvailability || !this.loggedIn
    }
  },
  mounted () {
    this.radioSearchType = this.search_type
    this.$label = $(this.$refs.availabilityLabel)
    this.checkShowAvailabilityTooltip()
  },
  watch: {
    canViewAvailability (value) {
      this.checkShowAvailabilityTooltip()
    },
    search_type (value) {
      this.radioSearchType = value
    }
  },
  methods: {
    updateSearchType: call('browseHomes/updateSearchType'),
    selectSearchType (event) {
      const { value, checked } = event.target
      if (checked) {
        this.updateSearchType(value)
      }
    },
    labelClass (searchType) {
      return {
        selected: this.radioSearchType === searchType ||
                  (this.radioSearchType === 'properties' && searchType === 'map'),

      }
    },
    checkShowAvailabilityTooltip () {
      if (this.disableAvailability) {
        this.$label.tipso({
          background: 'black',
          titleBackground: 'black',
          position: 'left',
          offsetY: -11,
          content: "Please log in to see availability results."
        })
      } else {
        this.$label.tipso('destroy')
      }
    }
  }
}
</script>
