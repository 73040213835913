var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "small-12 columns", attrs: { id: "add-photo" } }, [
      _c("div", { ref: "addPhotoButton", staticClass: "add-photo-button" }, [
        _vm._m(0),
        _c("div", { ref: "fileUploader", attrs: { id: "fine-file-uploader" } })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "zone" }, [
      _c("h4", { staticStyle: { "margin-bottom": "0" } }, [
        _vm._v("+ Add a photo")
      ]),
      _c(
        "p",
        {
          staticClass: "small",
          staticStyle: { "margin-left": "auto", "margin-right": "auto" }
        },
        [_vm._v("Click or drag and drop images here.")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }