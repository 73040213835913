import Vue from 'vue';
import { openModal } from "../core/modals";
import init from '../init';
import delegateEvent from '../init/delegate-event'


init.pageLoad('new-reservation', {
  load() {
    let $el = $('#new-reservation');

    document.querySelector('#confirm-booking-modal #agree-reservation-modal').addEventListener('click', (e) => {
      e.target.disabled = true;
      e.target.innerHTML = "Processing..."
      app.submitForm();
    })
  }
});
