import ScriptLoader from '../tools/script-loader';
import init from '../init';


function moveForm (el) {
 const anchor = document.getElementById('hubspot-form-container')
 anchor.appendChild(el)
}

init.pageLoad('travel-profile', {
  load () {
    const loader = new ScriptLoader('https://js.hsforms.net/forms/v2.js');
    loader.then(() => {
      hbspt.forms.create({
        portalId: "7702583",
        formId: "b608919a-9b1f-4865-91dc-27f9073d4038"
      });
      const el = document.querySelector('.hbspt-form')
      moveForm(el)
    });
  }
})