export const SmoothHorizontalScrolling = (e, time, pos) => {
  
  // Unset ScrollSnap
  e.style.scrollSnapType = 'none'
  
  let currentPos = e.scrollLeft

  //Center first un-centered image
  if (currentPos == 0) {
    pos = pos + (pos - e.clientWidth)/2 
  }

  let start = null;
  if(time == null) time = 500
  pos = +pos, time = +time
  
  window.requestAnimationFrame(function step(currentTime) {
      start = !start ? currentTime : start
      let progress = currentTime - start
      if (currentPos < pos) {
          e.scrollLeft = ((pos - currentPos) * progress / time) + currentPos
      } else {
          e.scrollLeft = currentPos - ((currentPos - pos) * progress / time)
      }
      if (progress < time) {
          e.style.scrollSnapType = 'none'
          window.requestAnimationFrame(step)
      } else {
          e.scrollLeft = pos
          // Reset ScrollSnap
          e.style.scrollSnapType = 'x mandatory'
      }
  });
  
}

