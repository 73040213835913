export const MIN_STAY = 3
export const MAX_STAY = 14

export function generateMonthWeeks (start, unavailable) {
  // Generate the days for the month, including trailing days before/after.
  let days = []
  const monthStart = dayjs(start).startOf('month')
  // Since .endOf() sets the time to 23:59:59 by default and causes problems
  // with .contains(), we have to set this to the start of day.
  const monthEnd = dayjs(start).endOf('month').startOf('day')

  const firstOffset = start.day()
  if (firstOffset > 0) {
    // This month doesn't start on Sunday, so we add days before monthStart
    // to make a full 7 day row.
    days.push(...Array.from(
      dayjs.range(
        dayjs(monthStart).subtract(firstOffset, 'days'),
        dayjs(monthStart).subtract(1, 'days')
      ).by('days')
    ))
  }

  // Add days in start's month.
  const monthRange = dayjs.range(
    monthStart,
    monthEnd
  )
  days.push(...Array.from(monthRange.by('days')))

  const lastOffset = monthEnd.day()
  if (lastOffset !== 6) {
    // This month doesn't end on a Saturday, so we add days after monthEnd
    // to make a full 7 day row.
    days.push(...Array.from(
      dayjs.range(
        dayjs(monthEnd).add(1, 'days'),
        dayjs(monthEnd).add(6 - lastOffset, 'days')
      ).by('days')
    ))
  }

  // Add all the properties we'll need for each day.
  days = days.map(day => {
    const dayPassed = day.isSameOrBefore(dayjs(), 'day')
    let canStart = !dayPassed
    let canEnd = !dayPassed
    const classes = {
      ['day-passed']: dayPassed,
      leading: day.isBefore(monthStart, 'day'),
      trailing: day.isAfter(monthEnd, 'day'),
    }

    for (const reserved of unavailable) {
      const { range, status } = reserved
      // Check if the day has been reserved or is available through
      // the standard reservation page.
      if (range.contains(day)) {
        let statusName = ''
        if (reserved.status === 'unavailable') {
          statusName = 'reserved'
        } else if (reserved.status === 'available') {
          statusName = 'available'
        }
        if (range.contains(day, { exclusive: true })) {
          classes[`${statusName}-within`] = true
          canStart = false
          canEnd = false
        } else if (range.start.isSame(day, 'day')) {
          classes[`${statusName}-start`] = true
          canStart = false
        } else if (range.end.isSame(day, 'day')) {
          classes[`${statusName}-end`] = true
          canEnd = false
        }
      } else if (range.contains(dayjs(day).add(MIN_STAY - 1, 'days'))) {
        // Check that the day isn't too close to a reservation to start.
        canStart = false
      }
    }
    classes['cant-start'] = !canStart

    return {
      day,
      dayPassed,
      canStart,
      canEnd,
      classes
    }
  })

  // Divide array of days into weeks of exactly seven days.
  const weeks = []
  while (days.length > 0) {
    weeks.push(days.splice(0, 7))
  }
  return weeks
}

// We could potentially extract the shared functionality of several
// calendars here.
