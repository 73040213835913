import init from '../init';
import Form from './form';
import Modal from './modal';
import StartState from './start_state';
import './posting_form';

init.pageLoad 'week-postings',
  load: ->
    $form = $('#week-postings-form')
    $modal = $('#week-postings-modal')
    startState = new StartState($form)
    window.form = new Form(startState.postings, $modal, $form.data('requires-confirmation-id'))
    modal = new Modal(form.selections, $form[0])

    ko.applyBindings form, $form[0]
    ko.applyBindings modal, $modal[0]
