var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "week-request-counter-offer" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.railsForm.starts_on,
          expression: "railsForm.starts_on"
        }
      ],
      attrs: { type: "hidden", name: "week_request_offer[starts_on]" },
      domProps: { value: _vm.railsForm.starts_on },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.railsForm, "starts_on", $event.target.value)
        }
      }
    }),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.railsForm.ends_on,
          expression: "railsForm.ends_on"
        }
      ],
      attrs: { type: "hidden", name: "week_request_offer[ends_on]" },
      domProps: { value: _vm.railsForm.ends_on },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.railsForm, "ends_on", $event.target.value)
        }
      }
    }),
    _c("div", { staticClass: "container grid collapse vertical" }, [
      _c(
        "div",
        { staticClass: "cell collapse collapse-padding" },
        [
          _c("StayRequestCalendarList", {
            attrs: {
              monthRange: _vm.monthRange,
              weekRequestCalendar: _vm.weekRequestCalendar,
              startingMonth: _vm.startingMonth
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "cell twelve collapse" }, [
        _c("div", { staticClass: "grid collapse collapse-padding" }, [
          _c(
            "div",
            {
              staticClass: "cell six input",
              class: { invalid: _vm.notEnoughKeys || _vm.keysBelowMinimum }
            },
            [
              _c("label", { attrs: { for: "offer_keys" } }, [
                _vm._v("Keys Offered")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.offerKeys,
                    expression: "offerKeys"
                  }
                ],
                attrs: {
                  name: "week_request_offer[keys]",
                  id: "offer_keys",
                  type: "number",
                  min: _vm.minKeys,
                  max: _vm.maxKeys
                },
                domProps: { value: _vm.offerKeys },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.offerKeys = $event.target.value
                  }
                }
              }),
              _vm.quote
                ? _c("div", { staticClass: "feedback" }, [
                    _vm._v("Minimum " + _vm._s(_vm.quote.keys)),
                    _c("span", { staticClass: "third-font-key-glyph" })
                  ])
                : _vm._e()
            ]
          ),
          !_vm.isOwner
            ? _c("div", { staticClass: "cell six counter-fee" }, [
                _c("div", { staticClass: "fee-label" }, [_vm._v("Fee")]),
                _c("div", { staticClass: "fee-amount" }, [
                  _vm._v(_vm._s(_vm.quote && _vm.formatFee(_vm.quote.fee)))
                ])
              ])
            : _vm._e()
        ])
      ]),
      _vm.error
        ? _c("div", { staticClass: "cell" }, [
            _c("p", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.error.message))
            ])
          ])
        : _vm.notEnoughKeys
        ? _c("div", { staticClass: "cell" }, [
            _c("p", [
              _vm._v(
                "You do not have enough available Keys needed to make this request. "
              ),
              _vm.depositUrl
                ? _c("a", { attrs: { href: _vm.depositUrl } }, [
                    _vm._v("Consider depositing more Weeks to earn Keys.")
                  ])
                : _vm._e()
            ])
          ])
        : _vm.keysBelowMinimum
        ? _c("div", { staticClass: "cell" }, [
            _c("p", [
              _vm._v(
                "You must offer at least the minimum Key count. (" +
                  _vm._s(_vm.minKeys)
              ),
              _c("span", { staticClass: "third-font-key-glyph" }),
              _vm._v(")")
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "cell" }, [
        _c("div", { staticClass: "input check_boxes" }, [
          _c("span", { staticClass: "checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.confirm,
                  expression: "confirm"
                }
              ],
              attrs: {
                type: "checkbox",
                id: "confirm-counter",
                disabled:
                  !_vm.quote ||
                  _vm.notEnoughKeys ||
                  _vm.keysBelowMinimum ||
                  _vm.counterSameAsCurrent
              },
              domProps: {
                checked: Array.isArray(_vm.confirm)
                  ? _vm._i(_vm.confirm, null) > -1
                  : _vm.confirm
              },
              on: {
                change: function($event) {
                  var $$a = _vm.confirm,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.confirm = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.confirm = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.confirm = $$c
                  }
                }
              }
            }),
            _c("label", { attrs: { for: "confirm-counter" } }, [
              _vm._v(
                "I am submitting a counteroffer " +
                  _vm._s(_vm.formattedRange) +
                  " for " +
                  _vm._s(_vm.formattedKeyCount) +
                  ", and I understand that this offer may be accepted. An accepted offer will automatically begin the booking process and transfer Keys."
              )
            ])
          ])
        ])
      ]),
      _vm._m(0),
      _c("div", { staticClass: "cell text-right" }, [
        _c("a", { staticClass: "text-close" }, [_vm._v("Go Back")]),
        _vm._v("   "),
        _c("input", {
          staticClass: "button counter",
          attrs: { type: "submit", value: "Counter", disabled: !_vm.confirm }
        })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell" }, [_c("br")])
  }
]
render._withStripped = true

export { render, staticRenderFns }