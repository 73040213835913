<template lang='pug'>
  div(:class='depositClasses')
    label.single-checkbox-container.grid.collapse.align-center.hold.collapse-padding
      .cell.checkbox-column.fit
        .input.check_boxes
          span.checkbox
            input(type='checkbox',
                  v-if='deposit.depositable',
                  @click="toggleCheck",
                  v-bind:checked="checked")
            label
      .cell.date-range.collapse
        span.third-font-calendar
        span.starts-on.date {{formattedRange.formattedStart}}
        |
        | to
        |
        span.ends-on.date {{formattedRange.formattedEnd}}
      .cell.status.six.medium-fit.text-center
        small
          | {{deposit.description}}&nbsp;
      .cell.four.medium-two.text-right.medium-text-center
        .pill.tiny.season
         | {{deposit.season.replace(/_/g, " ")}}
      .cell.key-count-column.two.medium-one.text-right
        span.key-count(:title='keyCountTitle')
          span.count
            | {{deposit.keys}}
          | &nbsp;
          span.third-font-key-glyph
        span.tipso_styles.asterisk(v-if='showTipso',
             data-tipso="You will not earn upfront keys for this week. You'll earn keys once it's booked.")
          | &#10033;
</template>

<script>
export default {
  props: {
    deposit: { type: Object, required: true },
    checked: { type: Boolean, required: true }
  },
  methods: {
    toggleCheck (e) {
      this.$emit('checkboxToggle', this.deposit)
    }
  },
  computed: {
    keyCountTitle () {
      return `${this.deposit.keys} Keys`
    },
    showTipso () {
      return this.deposit.delayed_keys
    },
    formattedRange () {
      return formattedStayRange(this.deposit.starts_on, this.deposit.ends_on)
    },
    depositClasses () {
      const classes = {
        'deposit-entry': true,
        'new-deposit': !this.deposit.existing_deposit,
        'last-minute': this.deposit.last_minute,
        'grayed': !this.deposit.depositable
      }
      classes[this.deposit.season] = true

      if(this.deposit.status) {
        classes[this.deposit.status] = true
      }

      return classes
    }
  }
}
</script>
