class Form
  constructor: (postings, @modal, @requiresConfirmationId)->
    @postings = ko.observableArray(postings)
    @selections = ko.computed =>
      posting for posting in @postings() when posting.selected()
    @canSubmit = ko.computed =>
      if @requiresConfirmationId
        @selections().length && @selections().every((posting) => posting.resortConfirmationId())
      else
        @selections().length 
    @totalKeys = ko.computed =>
      total = 0
      total += selection.keys for selection in @selections()
      total
    @keyDisplay = ko.computed =>
      keyCount @totalKeys()
  submit: ->
    if @canSubmit()
      @modal.reveal()
    false

export default Form;
