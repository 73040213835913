import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['card']

  static values = {url: String, showAlert: Boolean}

  connect() {
    if (this.showAlertValue) {
      this.showPopUp()
    }
  }

  showPopUp() {
    this.cardTarget.classList.remove('hide')
  }

  dismissPopUp() {
    event.preventDefault()
    this.cardTarget.classList.add('hide')
    let targetUrl = `${this.urlValue}?deposit_alert_viewed=true`

    fetch(targetUrl, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'}
    })
  }
}
