class PasswordAttributes
  constructor: () ->
    $('body').on('strongPassword.lengthChanged', this._lengthChanged)
    $('body').on('strongPassword.scoreChanged', this._scoreChanged)

  _lengthChanged: (event, length) =>
    @length = length
  _scoreChanged: (event, score) =>
    @score = score

export default PasswordAttributes;
