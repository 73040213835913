import Vue from 'vue';
import CardCollector from '../purchases/card-collector.vue';
import init from '../init';

init.pageLoad 'edit-billing-page',
  load: ->
    $billing = $('#manage-billing')

    savedCard = $billing.data('saved-card')
    form = $billing.find('form')[0]

    viewModel =
      savedCard: ko.observable(savedCard)
      cardForm: ko.observable(!savedCard)
      token: ko.observable()
      submitForm: (token) ->
        @token(token)
        form.submit()
      saveChanges: ->
        return if @processing
        @processing = true
        if @cardForm()
          cardCollector.$refs.collector.attempt().then(
            (token) => @submitForm(token)
            => @processing = false
          )
        else
          @submitForm()
      toggleCardForm: ->
        @cardForm(!@cardForm())
        if @cardForm()
          cardCollector.$refs.collector.focus();

    cardCollector = new Vue(
      el: '#collect-new-card'
      components:
        CardCollector: CardCollector
      methods:
        submit: -> viewModel.saveChanges()
    )

    ko.applyBindings(viewModel, $billing[0])