<template lang="pug">
div
  
  .primary-photo.photo
    template(v-if="photo")
      h4 Primary Photo

      img(:src="photo.big"
          :alt="photo.image_description"
          :title="photo.image_description")
      br
      br
      br

</template>

<script>
export default {
  props: {
    photo: {
      type: Object,
      default: null
    }
  }
}
</script>
