<template lang="pug">
a.pill.icon-pill.saved-search(@click="toggleSaveSearch" :class="{active: saved}" data-step="4" data-title="Stay in the know" data-intro="Receive notifications when new availability is added to this search by clicking the 'Save Search' button.")
  .pill-icon
    span.third-font-star.default-icon
    span.third-font-star-glyph.active-icon
  span.pill-text Save Search
</template>

<script>
import { get, call } from 'vuex-pathify'

export default {
  computed: {
    saved: get('browseHomes/currentSearchIsSaved'),
  },
  methods: {
    saveSearch: call('browseHomes/toggleSaveSearch'),
    toggleSaveSearch() {
      this.saveSearch()
      // Advance tour if running
      const event = new Event("proceed-introjs-tour", {bubbles: true})
      this.$el.dispatchEvent(event)
    }
  }
}

</script>
