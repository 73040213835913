<template lang="pug">
.week-request-counter-offer
  input(type="hidden" v-model="railsForm.starts_on"
        name="week_request_offer[starts_on]")
  input(type="hidden" v-model="railsForm.ends_on"
        name="week_request_offer[ends_on]")

  .container.grid.collapse.vertical
    .cell.collapse.collapse-padding
      StayRequestCalendarList(:monthRange="monthRange"
                              :weekRequestCalendar="weekRequestCalendar"
                              :startingMonth="startingMonth")

    .cell.twelve.collapse
      .grid.collapse.collapse-padding
        .cell.six.input(:class="{ invalid: (notEnoughKeys || keysBelowMinimum) }")
          label(for="offer_keys") Keys Offered
          input(v-model="offerKeys" name="week_request_offer[keys]"
                id="offer_keys" type="number"
                :min="minKeys" :max="maxKeys")
          .feedback(v-if="quote")
            | Minimum {{ quote.keys }}
            span.third-font-key-glyph
        .cell.six.counter-fee(v-if="!isOwner")
          .fee-label Fee
          .fee-amount {{ quote && formatFee(quote.fee) }}


    .cell(v-if="error")
      p.error
        | {{ error.message }}

    .cell(v-else-if="notEnoughKeys")
      p
        | You do not have enough available Keys needed to make this request. 
        a(v-if='depositUrl' :href='depositUrl')
          | Consider depositing more Weeks to earn Keys.

    .cell(v-else-if="keysBelowMinimum")
      p You must offer at least the minimum Key count. ({{ minKeys }}
        span.third-font-key-glyph
        | )

    .cell
      .input.check_boxes
        span.checkbox
          input(type="checkbox" id="confirm-counter" v-model="confirm"
              :disabled="!quote || notEnoughKeys || keysBelowMinimum || counterSameAsCurrent")
          label(for="confirm-counter") I am submitting a counteroffer {{ formattedRange }} for {{ formattedKeyCount }}, and I understand that this offer may be accepted. An accepted offer will automatically begin the booking process and transfer Keys.

    .cell
      br

    .cell.text-right
      a.text-close Go Back
      | &nbsp;&nbsp;&nbsp;
      input.button.counter(type="submit" value="Counter", :disabled="!confirm")
</template>

<script>
import { get, call } from 'vuex-pathify'
import StayRequestCalendarList from '../stay-request-calendar/StayRequestCalendarList.vue'
import pluralize from '../tools/pluralize.coffee'

export default {
  components: {
    StayRequestCalendarList
  },
  props: {
    monthRange: { type: Object, required: true },
    weekRequestCalendar: { type: Array, required: true },
    freeKeyCount: { type: Number, required: true },
    isOwner: { type: Boolean, required: true },
    isCurrentOfferer: { type: Boolean, required: true },
    currentOffer: { type: Object, required: true },
    quoteUrl: { type: String, required: true },
    depositUrl: { type: String, default: null }
  },
  data () {
    return {
      confirm: false,
      offerKeys: 0,
      confirmButton: null
    }
  },
  watch: {
    quote () {
      this.confirm = false
    },
    notEnoughKeys () {
      this.confirm = false
    },
    keysBelowMinimum () {
      this.confirm = false
    },
    counterSameAsCurrent (same) {
      if (same) {
        this.confirm = false
      }
    },
    async offerKeys (keys) {
      await this.setOptions({ keys })
      if (this.stayRange) this.fetchQuote()
    }
  },
  computed: {
    ...get('calendars/stay-request', ['stayRange', 'quote', 'options', 'error']),
    minKeys () {
      return this?.quote?.keys || 1
    },
    maxKeys () {
      if (this.isOwner) {
        return 99
      } else {
        return Math.max(this?.quote?.keys || 0, this.freeKeyCount)
      }
    },
    notEnoughKeys () {
      if (!this.stayRange || this.isOwner) {
        return false
      } else {
        return Math.max(this?.quote?.keys || 0, this.offerKeys) > this.freeKeyCount
      }
    },
    keysBelowMinimum () {
      return this.offerKeys < this.minKeys
    },
    counterSameAsCurrent() {
      if (!this.stayRange) return false

      const { starts_on, ends_on, keys } = this.currentOffer.week_request_offer
      return this.stayRange.start.isSame(starts_on, 'day') &&
             this.stayRange.end.isSame(ends_on, 'day') &&
             keys === Number(this.offerKeys)
    },
    startingMonth () {
      const { starts_on } = this.currentOffer.week_request_offer
      return dayjs(starts_on).startOf('month')
    },
    railsForm () {
      return {
        starts_on: this?.stayRange?.start?.format('YYYY-MM-DD') || '',
        ends_on: this?.stayRange?.end?.format('YYYY-MM-DD') || '',
      }
    },
    formattedRange () {
      if (!this.stayRange) return

      const {formattedStart, formattedEnd } = formattedStayRange(...Object.values(this.railsForm))
      return `for ${formattedStart} to ${formattedEnd}`
    },
    formattedKeyCount(){
      return pluralize(this.offerKeys, 'Key', 'Keys')
    }
  },
  methods: {
    ...call('calendars/stay-request', ['setSelection', 'setUrls', 'setOptions', 'fetchQuote']),
    formatFee (fee) {
      return Number(fee).toLocaleString(
        'en-US', { style: 'currency', currency: 'USD' }
      );
    }
  },
  async mounted () {
    this.confirmButton = document.querySelector('#calendar-modal input.button.counter')
    this.setUrls({
      quote: this.quoteUrl
    })
    // Rails' to_json wraps the object, unwrap here.
    const { starts_on, ends_on, keys } = this.currentOffer.week_request_offer
    await this.setSelection({
      start: dayjs(starts_on),
      end: dayjs(ends_on),
    })
    this.offerKeys = keys
  }
}
</script>

<style scoped>
.counter-fee .fee-amount {
  line-height: 3.5em;
}
</style>
