import Schedule from './schedule';

class ScheduleSwitcher
  constructor: (data) ->
    schedules = for schedule in data
      new Schedule(schedule)
    @needsSwitcher = schedules.length > 1
    @schedules = ko.observableArray(schedules)
    @wday = ko.observable(data[0].wday)
    @selectedWday = ko.observable(@wday())
    @schedule = ko.computed =>
      (schedule for schedule in @schedules() when schedule.wday is @wday())[0]

export default ScheduleSwitcher;