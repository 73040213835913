<template lang="pug">
  .saved-search-widget
    StarButton
</template>

<script>
import { get, call } from 'vuex-pathify'
import SavedSearches from './SavedSearches.vue'
import StarButton from './StarButton.vue'

export default {
  components: {
    SavedSearches,
    StarButton
  },
  data () {
    return {
      expandMenu: false
    }
  },
  computed: {
    searches: get('browseHomes/savedSearches'),
    hasSearches () {
      return this.searches.length > 0;
    }
  },
  methods: {
    toggleSearchMenu () {
      this.expandMenu = !this.expandMenu;
    },
    closeSearchMenu () {
      this.expandMenu = false;
    },
    clickOutsideSearchMenu (e) {
      if (this.expandMenu) {
        this.closeSearchMenu()
      }
    }
  }
}
</script>
