class AddressWrapper {
  constructor (feature) {
    this.feature = feature
  }
  get street () {
    return [this.feature.address, this.feature.text].filter(e => e).join(' ')
  }
  get postcode () {
    return this._queryContextFeatureByType('postcode')?.text
  }
  get city () {
    return (this._queryContextFeatureByType('place') || this._queryContextFeatureByType('locality'))?.text
  }
  get region () {
    return this._queryContextFeatureByType('region')?.text
  }
  get country () {
    return this._queryContextFeatureByType('country')?.text
  }
  get countryCode () {
    return this._queryContextFeatureByType('country')?.short_code
  }
  get lnglat () {
    return this.feature.center
  }
  get lng () {
    if (this.lnglat) return this.lnglat[0]
  }
  get lat () {
    if (this.lnglat) return this.lnglat[1]
  }
  get id () {
    return this.feature.id
  }
  get placeName () {
    return this.feature.place_name
  }
  _queryContextFeatureByType (type) {
    return this._queryContextFeature(e => e.id.startsWith(type))
  }
  _queryContextFeature (func) {
    return this.feature.context.find(func)
  }
}

export default AddressWrapper
