class PropertyViewTracker
  constructor: ->
    @backoffFactor = 1.5
  start: (@url, @monitor) ->
    $.getJSON @url, (data) =>
      @active = ko.observable(@monitor.active())
      @_startClock(data.duration)
      @_scheduleUpdate(1000)
      @monitor.active.subscribe (value) =>
        @active(value)
        if value
          @activeTime = @_currentTime()
  stop: ->
    if @scheduledUpdate
      clearTimeout(@scheduledUpdate)
    @logUpdate()
  _startClock: (initialTime) ->
    @totalTime = initialTime
    @activeTime = @_currentTime()
  logUpdate: ->
    @_tick()
    $.ajax(@url, type: "POST", dataType: "json", data: {_method: 'PATCH', duration: @totalTime})
  _executeScheduledUpdate: (lastDelay) ->
    @logUpdate() if @active()
    @_scheduleUpdate(@_determineNextUpdateInterval(lastDelay))
  _determineNextUpdateInterval: (lastDelay) ->
    if @active()
      nextDelay = lastDelay * @backoffFactor
    else
      nextDelay = 1000
    nextDelay
  _currentTime: ->
    new Date()
  _scheduleUpdate: (delay) ->
    @scheduledUpdate = setTimeout((=> @_executeScheduledUpdate(delay)), delay)
  _tick: ->
    if @active()
      previousActiveTime = @activeTime
      newTime = @_currentTime()
      elapsedSinceLastTime = Math.round((newTime - previousActiveTime)/1000)
      @totalTime = @totalTime + elapsedSinceLastTime
      @activeTime = newTime
    @totalTime

export default PropertyViewTracker
