class Posting
  constructor: (obj, selected) ->
    {@id, @starts_on, @ends_on, @keys, @price, @can_accept, @number_of_guests, @overlap, @wrong_schedule, @template, @resortConfirmationId} = obj
    @selected = ko.observable(selected)
    [@startDate, @endDate] = formatDateRangeArray(@starts_on, @ends_on)
    @dateRange = formatDateRange(@starts_on, @ends_on)
    @keyCount = keyCount(@keys)
    @guestsTitle = "#{@number_of_guests} Guests"
    @resortConfirmationIdName = "resort_confirmation_ids[#{@id}]"
    @resortConfirmationId = ko.observable()
    @disabledReason = if @template
      "This property cannot accept weeks."
    else if @overlap
      "This period overlaps an existing deposit at your property."
    else if @wrong_schedule
      "This period conflicts with your property's rental schedule."

export default Posting;
