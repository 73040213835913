import { Controller } from "@hotwired/stimulus";
import { openModalSelector } from "../../core/modals"

export default class extends Controller {
  static targets = ['totpRegion', 'smsRegion', 'totpToggle', 'smsToggle', 'setupRegion']

  static values = {
    alreadyEnabled: Boolean
  }

  totpToggleTargetConnected(e) {
    if (e.checked) this.toggleTotp()
  }

  smsToggleTargetConnected(e) {
    if (e.checked) this.toggleSms()
  }

  toggleTotp(){
    this.totpRegionTarget.style.removeProperty('display')
    this.smsRegionTarget.style.display = 'none'
  }

  toggleSms(){
    this.totpRegionTarget.style.display = 'none'
    this.smsRegionTarget.style.removeProperty('display')
  }

  toggleTwoFactorRegion({target}) {
    if(target.checked) { this.setupRegionTarget.style.removeProperty('display') }

    if(!target.checked){ 
      if (this.alreadyEnabledValue) {
        target.checked = true
        openModalSelector('#disable-2fa')
      } else {
        this.setupRegionTarget.style.display = 'none'
      }
    }
  }
}