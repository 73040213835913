class Tooltip {
  constructor (el, content, opts) {
    this.el = el
    this.content = content
    this.opts = opts
  }
  show () {
    const args = {
      background: "#000",
      titleBackground: "#000",
      content: this.content,
      hideDelay: 1000,
      onShow: () => {
        setTimeout((() => this.showTimeout()), 5000);
      },
      onHide: () => { this.teardown() }
    };
    Object.assign(args, this.opts || {})
    $(this.el).tipso(args).tipso('show');
  }
  get active () {
    return $(this.el).hasClass('tipso_style');
  }
  showTimeout () {
    if (this.active) {
      const $el = $(this.el);
      $el.tipso('close');
    }
  }
  teardown () {
    if (this.active) {
      const $el = $(this.el);
      $el.tipso('destroy');
    }
  }
}

export default {
  pop (el, content, opts) {
    new Tooltip(el, content, opts).show();
  }
}
