import Vue from 'vue'
import VueX from 'vuex'
import pathify from 'vuex-pathify'
import cloneDeep from 'lodash/cloneDeep'

import browseHomes from './browse-homes/index.js'
import calendars from './calendars/index.js'
import weekRequest from './week-request/index.js'

Vue.use(VueX)

const modules = {
  browseHomes,
  calendars,
  weekRequest
}

export const store = new VueX.Store({
  plugins: [pathify.plugin],
  modules: cloneDeep(modules)
})

export default store

export const createStore = () => {
  return new VueX.Store({
    plugins: [pathify.plugin],
    modules: cloneDeep(modules)
  })
}
