import ScriptLoader from '../tools/script-loader';
import mapBoxLoader from './mapbox';

const loader = new ScriptLoader(
  'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.min.js',
  function () {
    return window.MapboxGeocoder
    //mapBoxLoader.then((mapbox) => {
    //})
  }
);

export default loader;
