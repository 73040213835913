import { Controller } from "@hotwired/stimulus";
import analytics from '../../analytics';

export default class extends Controller {
  static get targets() {
    return ['tracked'];
  }

  connect () {
    this.title = this.element.dataset.title;
  }

  handleClick (e) {
    if (!this.title) return;
    if (e.defaultPrevented) return;

    const clicked = e.currentTarget;
    const index = this.trackedTargets.indexOf(clicked);

    if (index != -1) {
      analytics.trackEvent('Homepage', 'Click', this.title, index);
    }
  }
}
