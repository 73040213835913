import { Controller } from "@hotwired/stimulus";
import { formatCurrency } from '../../tools/formatCurrency';
import { openModal, closeModal } from "../../core/modals";

export default class extends Controller {
  static targets = [ 'submit', 'terms', 'total', 'points', 'useSavedCard',
                     'autoRenew', 'stripeWrapper', 'payment', 'applyCredit',
                     'creditTotal', 'applyKeylessToken', 'keylessTokenTotal',
                     'keyTotal'
                  ]
  static outlets = [ 'stripe' ]
  static values = {
    total: Number,
    subtotal: Number,
    keylessSubtotal: Number,
    credit: { type: Number, default: 0 },
    confirmationModal: String,
    keysRequired: Number
  }

  connect() {
    if(this.confirmationModalValue) { this._enableConfirmationModal() }
    this._calculateNewTotal()
    this.totalValueChanged()
    this._setCreditTotal()
    this._setKeylessTokenTotal()
    this._resetTerms()
  }

  setStripeVisibility() {
    if(this.hasUseSavedCardTarget && this.useSavedCardTarget.checked) {
      this._hide(this.stripeWrapperTarget)
    } else {
      this._reveal(this.stripeWrapperTarget)
    }
  }

  showAutoRenew(event) {
    if(event.target.checked) {
      this._reveal(this.autoRenewTarget)
    } else {
      this._hide(this.autoRenewTarget)
    }
  }

  submit(event) {
    event.preventDefault()
    if (this.needsConfirmation) { return openModal(this.confirmationModal) }
    this.submitTarget.disabled = true
    if(this.totalValue > 0 && (!this.hasUseSavedCardTarget || !this.useSavedCardTarget.checked)) {
      this.stripeOutlet.submit().then((token) => {
        if(token) {
          this._addTokenHiddenInput(token)
          this.element.submit()
        } else {
          this._resetTerms()
        }
      }).catch(error => {
        this._resetTerms()
        closeModal(this.confirmationModal)
      })
    } else {
      this.element.submit()
    }
  }

  toggleApplyCredit() {
    this._calculateNewTotal()
    this._setCreditTotal()
  }

  toggleApplyKeylessToken() {
    this._calculateNewTotal()
    this._setKeylessTokenTotal()
  }

  _calculateNewTotal() {
    const isUsingKeylessToken = this.hasApplyKeylessTokenTarget && this.applyKeylessTokenTarget.checked
    const isUsingCredit = this.hasApplyCreditTarget && this.applyCreditTarget.checked
    const baseSubtotal = isUsingKeylessToken ? this.keylessSubtotalValue : this.subtotalValue
    const newTotal = isUsingCredit ? (baseSubtotal - this.creditValue) : baseSubtotal
    this._calculateNewKeyTotal(isUsingKeylessToken)
    this.totalValue = newTotal
  }

  totalValueChanged() {
    this.totalTarget.innerHTML = formatCurrency(this.totalValue)
    if(document.querySelector('.loyalty-points')) {
      this.pointsTarget.innerHTML = this.totalValue
    }
    if(this.totalValue > 0) {
      this._reveal(this.paymentTarget)
    } else {
      this._hide(this.paymentTarget)
    }
  }

  validateSubmitButton() {
    this.submitTarget.disabled = !this.termsTargets.every(term=> term.checked)
  }

  _addTokenHiddenInput(token) {
    let hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'purchase[cc_token]')
    hiddenInput.setAttribute('value', token)
    this.element.append(hiddenInput)
  }

  _hide(target) {
    target.classList.add('hide')
  }

  _reveal(target) {
    target.classList.remove('hide')
  }

  _resetTerms() {
    if (this.hasTermsTarget) { 
      this.termsTargets.every(term => term.checked = false)
      this.submitTarget.disabled = true
    } else {
      this.submitTarget.disabled = false
    }
  }

  _setCreditTotal() {
    if (this.hasApplyCreditTarget) {
      let creditTotalValue = this.applyCreditTarget.checked ? this.creditValue : 0
      this.creditTotalTarget.innerHTML = `-${formatCurrency(creditTotalValue)}`
    }
  }

  _setKeylessTokenTotal() {
    if (!this.hasKeylessTokenTotalTarget) { return }

    this.keylessTokenTotalTarget.style.visibility = this.applyKeylessTokenTarget.checked ? 'visible' : 'hidden'
  }

  _calculateNewKeyTotal(isUsingKeylessToken) {
    if (!this.hasKeyTotalTarget) { return }

    this.keyTotalTarget.innerHTML = isUsingKeylessToken ? 0 : this.keysRequiredValue
  }

  _enableConfirmationModal() {
    // Cannot use target due to nesting of modal being outside parent
    const modal = document.querySelector(this.confirmationModalValue)
    if (modal) {
      this.confirmationModal = modal
      // Flag as needing to confirm modal to submit
      this.needsConfirmation = true
      const confirmationButton = modal.querySelector('button')
      confirmationButton.addEventListener('click', ((ev) => {
        ev.target.disabled = true
        ev.target.innerHTML = 'Processing...'
        this.needsConfirmation = false
        this.element.dispatchEvent(new Event('submit'))
      })
      )
    }
  }
}
