var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.clickAway,
          expression: "clickAway"
        }
      ]
    },
    [
      _c("div", { staticClass: "text-input" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.rawValue,
              expression: "rawValue"
            }
          ],
          ref: "input",
          staticClass: "word-search",
          attrs: {
            type: "text",
            placeholder: _vm.placeholder,
            autocorrect: "off",
            autocapitalize: "off",
            autocomplete: "off",
            spellcheck: "false"
          },
          domProps: { value: _vm.rawValue },
          on: {
            keyup: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown"
                  ])
                ) {
                  return null
                }
                return _vm.advance($event)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp"
                  ])
                ) {
                  return null
                }
                return _vm.retreat($event)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape"
                  ])
                ) {
                  return null
                }
                return _vm.escape($event)
              }
            ],
            keydown: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown"
                  ])
                ) {
                  return null
                }
                $event.preventDefault()
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp"
                  ])
                ) {
                  return null
                }
                $event.preventDefault()
              },
              _vm.focus,
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.submit($event)
              }
            ],
            focus: _vm.focus,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.rawValue = $event.target.value
            }
          }
        })
      ]),
      _c("div", { staticClass: "suggestions search-suggestions" }, [
        _vm.active
          ? _c(
              "ul",
              { staticClass: "options" },
              [
                _vm.loading ? _c("li", [_vm._m(0)]) : _vm._e(),
                _vm._l(_vm.results, function(choice) {
                  return _c("search-box-choice", {
                    key: choice.type + choice.description,
                    class: { active: choice === _vm.choiceInFocus },
                    attrs: { result: choice },
                    on: { selected: _vm.selected }
                  })
                }),
                _vm.showGoogleLogo
                  ? _c("li", { staticClass: "google-places-logo" })
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loader-container" }, [
      _c("div", { staticClass: "loader" }),
      _c("div", { staticClass: "loader-text" }, [
        _c("span", [_vm._v("L")]),
        _c("span", [_vm._v("o")]),
        _c("span", [_vm._v("a")]),
        _c("span", [_vm._v("d")]),
        _c("span", [_vm._v("i")]),
        _c("span", [_vm._v("n")]),
        _c("span", [_vm._v("g")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }