import { Controller } from "@hotwired/stimulus";
import QRious from 'qrious'

export default class extends Controller {
  static targets = ['qrCodeCanvas']
  static values = {
    url: String
  }

  _generateQrCode(uri) {
    new QRious({ element: this.qrCodeCanvasTarget, value: uri, size: 260, foreground: "#212121" });
  }

  initialize () {
    this._generateQrCode(this.urlValue)
  }
}