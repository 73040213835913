import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['currencySelector', 'propertyValueLabels']
  }

  connect () {
    this.setCurrencyRangeRadios();
  }

  setCurrencyRangeRadios(e){
    this.enableRangeRadiosByCurrency();
    this.swapCurrencyRangeText();
  }

  enableRangeRadiosByCurrency() {
    this.propertyValueLabelsTargets.forEach((propertyValueLabel) => {
      let enableFor = JSON.parse(propertyValueLabel.dataset.enableFor);
      propertyValueLabel.classList.toggle('hidden',
        !enableFor.includes(this.currencySelectorTarget.value));
    });
  }

  swapCurrencyRangeText(){
    const selectedCurrencyOption =
      this.currencySelectorTarget[this.currencySelectorTarget.selectedIndex];
    const currencySymbol = selectedCurrencyOption.dataset.currencySymbol;
    this.propertyValueLabelsTargets.forEach((propertyValueLabel) => {
      let currencyRangeTexts = JSON.parse(propertyValueLabel.dataset.currencyRangeText);
      propertyValueLabel.innerHTML = currencyRangeTexts[currencySymbol];
    });
  }
}
