var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cell fill medium-four arrive-depart-container",
      class: { longtext: _vm.longtext }
    },
    [
      _c("label", [_vm._v("from / to")]),
      _c("input", {
        ref: "picker",
        staticClass: "search-date-range-picker",
        attrs: { type: "text", placeholder: "ANYTIME", readonly: "" },
        domProps: { value: _vm.stayText }
      }),
      _c(
        "div",
        {
          staticClass: "input-clear",
          class: { hide: !_vm.startDate && !_vm.endDate },
          on: { click: _vm.drpCancel }
        },
        [_vm._m(0)]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "close" }, [
      _c("div", { staticClass: "x" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }