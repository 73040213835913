var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        version: "1.1",
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 24 24",
        "enable-background": "new 0 0 24 24",
        "xml:space": "preserve"
      }
    },
    [
      _c("g", { attrs: { id: "XMLID_1414_" } }, [
        _c("circle", {
          attrs: {
            id: "XMLID_1366_",
            fill: "none",
            stroke: "#303C42",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-miterlimit": "10",
            cx: "12",
            cy: "7",
            r: "5.5"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: "XMLID_1368_",
            fill: "none",
            stroke: "#303C42",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-miterlimit": "10",
            d:
              "\n    M12,14.5c-3.3359776,0-6.3709235,1.2041245-8.3102798,2.1998253C2.3479097,17.3887348,1.5,18.7599583,1.5,20.2682838V21\n    c0,0.8284264,0.6715729,1.5,1.5,1.5h18c0.8284264,0,1.5-0.6715736,1.5-1.5v-0.7317162\n    c0-1.5083256-0.8479099-2.879549-2.1897202-3.5684586C18.370924,15.7041245,15.3359776,14.5,12,14.5z"
          }
        })
      ]),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g"),
      _vm._v(" "),
      _c("g")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }