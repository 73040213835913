import init from '../init';

init.pageLoad('new-user-availability', {
  load () {
    const sortSelector = document.getElementById('search-sort')

    sortSelector.addEventListener('change', function() {
      if (sortSelector.value == 'recent'){
        localStorage.removeItem('availability.sortType');
      }else{
        localStorage.setItem('availability.sortType', sortSelector.value);
      }
    })

    if (localStorage.getItem('availability.sortType') !== null){
      var sortCurrentValue = sortSelector.value;
      var availabilitySortType = localStorage.getItem('availability.sortType');

      if (sortCurrentValue !== availabilitySortType){
        sortSelector.value = availabilitySortType;
        sortSelector.dispatchEvent( new CustomEvent('change'));
      }
    }
  }
})
