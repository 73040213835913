var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cell collapse day",
      class: _vm.dayClasses,
      on: { click: _vm.clickDate, mouseover: _vm.hover }
    },
    [
      _vm.day != null
        ? [
            _c("div", { staticClass: "day-number" }, [
              _vm._v(_vm._s(_vm.dayLabel))
            ])
          ]
        : _vm._e(),
      _c("span", {
        staticClass: "tooltip-hidden",
        attrs: { "data-tipso": "Choose a check out date." }
      }),
      _c("div", { staticClass: "marker-start" }),
      _c("div", { staticClass: "marker-end" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }