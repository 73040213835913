const addCommasToNumber = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const formatCurrency = function(float) {
  if (typeof(float) !== "number") { float = parseFloat(float); }
  if (isNaN(float)) { return "$NaN"; }

  let [dollars, cents] = Array.from(float.toFixed(2).split("."));
  dollars = addCommasToNumber(dollars);
  const amount = cents === "00" ? dollars : `${dollars}.${cents}`;

  return `$${amount}`;
};
