var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-template-wrapper grid edge" },
    [
      _c(
        "div",
        { staticClass: "cell twelve medium-eight" },
        [
          _c("DepositCalendar", {
            attrs: {
              calendarStart: _vm.calendarStart,
              selectedDate: _vm.selectedDate,
              existingDeposits: _vm.existingDeposits,
              selectedDeposits: _vm.selectedDeposits,
              depositRange: _vm.range,
              seasonality: _vm.seasonality,
              keyEarningStartDate: _vm.keyEarningStartDate,
              selectedRange: _vm.selectedRange
            },
            on: {
              dateSelected: _vm.dateSelected,
              previousMonth: _vm.gotoPreviousMonth,
              nextMonth: _vm.gotoNextMonth,
              "update:selectedRange": function($event) {
                _vm.selectedRange = $event
              },
              "update:selected-range": function($event) {
                _vm.selectedRange = $event
              }
            }
          }),
          _c("StayPicker", {
            attrs: {
              optionUrl: _vm.optionUrl,
              selectedDeposits: _vm.selectedDeposits,
              selectedDate: _vm.selectedDate,
              splitWeeks: _vm.splitWeeks
            },
            on: {
              dateSelected: _vm.dateSelected,
              stayPicked: _vm.stayPicked,
              addDeposit: _vm.depositAdded
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "cell twelve medium-four" }, [
        _c("div", { staticClass: "deposit-sidebar" }, [
          _c(
            "div",
            {
              staticClass: "card white-card",
              attrs: {
                "data-title": "Confirm & Submit",
                "data-intro": "Confirm and Submit Weeks to Club for Review",
                "data-step": "3"
              }
            },
            [
              _c(
                "div",
                { staticClass: "card-content" },
                [
                  _c("h4", [_vm._v("Week Selection")]),
                  _vm.hasSelectedDeposits
                    ? _c("Review", {
                        attrs: {
                          selectedDeposits: _vm.selectedDeposits,
                          confirmUrl: _vm.confirmUrl,
                          requireConfirmationIds: _vm.requireConfirmationIds,
                          shouldTrackPrompt: _vm.shouldTrackPrompt,
                          redirectPath: _vm.redirectPath,
                          ownerCanNyop: _vm.ownerCanNyop
                        },
                        on: { removeDeposit: _vm.depositRemoved }
                      })
                    : _vm._e(),
                  !_vm.hasSelectedDeposits
                    ? _c("div", [_vm._m(0), _c("br"), _c("br")])
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ])
      ]),
      _c("NonKeyEarningModal", {
        attrs: {
          selectedDeposit: _vm.lastAddedDeposit,
          isAffiliateProperty: _vm.isAffiliateProperty
        },
        on: { remove: _vm.depositRemoved }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("span", {
        staticClass: "third-font-calendar-plus",
        staticStyle: { "font-size": "3.5rem", opacity: ".16" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }