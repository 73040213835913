import 'jquery-ui/ui/widgets/slider'
import Vue from 'vue'
import TurbolinksAdapter from 'vue-turbolinks'
import vClickOutside from 'v-click-outside'
import init from '../init'
import navigator from '../navigator/index.js'
import { createStore } from '../vuex-store/index.js'
import BrowseHomes from './BrowseHomes.vue'

Vue.use(vClickOutside)
Vue.use(TurbolinksAdapter)

init.pageLoad('browse-homes', {
  load() {
    // Remove Turbo from the history.state so it doesn't try to handle the page.
    window.history.replaceState(
      { browseHomes: true,
        url: window.location.toString()
      },
      document.title,
      window.location
    )

    this.store = createStore()

    const browseHomes = new Vue({
      el: '#browse-homes-container',
      components: { BrowseHomes },
      store: this.store
    })

    navigator.connectBrowseHomesDelegate((e) => this.processNavigationEvent(e))
  },
  unload() {
    navigator.disconnectBrowseHomesDelegate()
  },
  methods: {
    processNavigationEvent(event) {
      if (event.state?.browseHomes && event.state?.url) {
        // We've arrived at a Browse Homes page via the back/forward buttons, so
        // dispatch fetchProperties() to load the search and its filters here.
        this.store.dispatch('browseHomes/fetchProperties', {
          url: event.state.url,
          updateHistory: false
        })
      }
    }
  }
})

document.addEventListener('turbo:before-render', (event) => {
  document.body.classList.remove('show-modal')
})
