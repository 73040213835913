import SelectorProcessor from './selector_processor';
import init from './index';

class SelectorObserver
  constructor: ->
    @addProcessor = new SelectorProcessor()
    @removeProcessor = new SelectorProcessor()
    @observer = new MutationObserver @processMutations
    init.once =>
      setTimeout (=>
        @observer.observe document, childList: true, subtree: true
        @processBody()
      ), 1

  add: (selector, callback) ->
    @addProcessor.add(selector, callback)

  disconnectAdd: (selector, callback) ->
    @addProcessor.remove(selector, callback)

  remove: (selector, callback) ->
    @removeProcessor.add(selector, callback)

  processBody: () ->
    @addProcessor.processElements([document.body])

  processMutations: (mutations) =>
    addedElements = new Set
    removedElements = new Set

    for mutation in mutations
      switch mutation.type
        when "childList"
          for node in mutation.addedNodes when node.nodeType is Node.ELEMENT_NODE
            addedElements.add(node)
          for node in mutation.removedNodes when node.nodeType is Node.ELEMENT_NODE
            removedElements.add(node)

    @removeProcessor.processElements(removedElements)
    @addProcessor.processElements(addedElements)

export default SelectorObserver;
