import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    lastModified: String,
    url: String
  }

  connect() {
    this.startPolling()
  }

  disconnect() {
    this.stopPolling()
  }

  startPolling() {
    if (this.pollingInterval) return

    this.pollingInterval =
      setInterval(() => {
        this.poll()
      }, 3000)
  }

  stopPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval)
      delete this.pollingInterval
    }
  }

  updateActivity(text) {
    Turbo.renderStreamMessage(text)
  }

  poll() {
    let headers = {
      'Accepts': 'text/vnd.turbo-stream.html',
      'If-Modified-Since': this.lastModifiedValue
    }

    fetch(this.urlValue, {headers: headers}).then(
      (response) => {
        if (response.ok) {
          this.lastModifiedValue = response.headers.get('Last-Modified')
          return response.text()
        }
      }).then(
      (text) => {
        if (text) this.updateActivity(text)
      })
  }
}