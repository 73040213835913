import manager from '../init/event_manager';
import ScriptLoader from '../tools/script-loader';
import observer from '../init/observer';
import tracker from './tracker';

function refreshConversations () {
  window.HubSpotConversations && window.HubSpotConversations.widget.refresh();
}

function open () {
  window.HubSpotConversations && window.HubSpotConversations.widget.open();
}

function turboPageViews () {
  manager.connect({
    'turbo:load': function (e) {
      tracker.trackCurrentPageView();
    }
  });
}

function setUpHubspot () {
  turboPageViews();
  moveConversations();
}

function promoteHubspotConversationStylesToHead () {
  let styles = [...document.body.querySelectorAll('style[type="text/css"]')]
    .filter((e) => e.textContent.match(/hubspot-messages-iframe-container/));
  styles.forEach((el) => document.head.appendChild(el));
}

function moveConversations () {
  const callback = function (els) {
    const el = els[0];
    observer.disconnectAdd('#hubspot-messages-iframe-container', callback);
    document.documentElement.appendChild(el);
    promoteHubspotConversationStylesToHead();
  }

  observer.add('#hubspot-messages-iframe-container', callback);
}

function start (conversationId) {
  window.hsConversationsSettings = {
    loadImmediately: true,
    enableWidgetCookieBanner: false,
    disableAttachment: false
  };

  const loader = new ScriptLoader(`https://js.hs-scripts.com/${conversationId}.js`);
  loader.then(() => {
    if (window.HubSpotConversations) {
      setUpHubspot();
    } else {
      window.hsConversationsOnReady = [setUpHubspot];
    }
  });
}

export default {start, open}
