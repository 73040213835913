
const localeObject = {
  name: 'en-notifications',
  relativeTime : {
    future: "in %s",
    past:   "%s",
    s  : '1s',
    ss : '%ds',
    m:  "1m",
    mm: "%dm",
    h:  "1h",
    hh: "%dh",
    d:  "1d",
    dd: "%dd",
    M:  "1mo",
    MM: "%dmo",
    y:  "1y",
    yy: "%dy"
  }
}

const notificationTimeFormatter = (date) => {
  return dayjs(date).locale(localeObject).fromNow();
}

export default notificationTimeFormatter
