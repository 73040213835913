var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cell fit beds-container" }, [
    _c("label", [_vm._v("Bedrooms")]),
    _c("input", {
      staticClass: "min-beds number",
      attrs: { type: "number", size: "3", min: "1", max: "30" },
      domProps: { value: _vm.beds },
      on: { input: _vm.changeMinBeds }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }