<template lang="pug">
  div
    br
    br
    #add-photo.small-12.columns
      .add-photo-button(ref='addPhotoButton')
        .zone
          h4(style="margin-bottom:0;") + Add a photo
          p.small(style="margin-left:auto;margin-right:auto") Click or drag and drop images here.

        #fine-file-uploader(ref='fileUploader')
</template>

<script>


export default {
  props: {
    photoUploadUrl: { type: String, required: true }
  },
  mounted () {
    const csrfParam = $('meta[name=csrf-param]').attr('content')
    const csrfToken = $('meta[name=csrf-token]').attr('content')

    $(this.$refs.fileUploader).fineUploader({
      request: {
        endpoint: this.photoUploadUrl,
        params: {
          'authenticity_token': csrfToken
        },
        forceMultipart: false,
      },
      maxConnections: 1,
      failedUploadTextDisplay: {
        mode: "custom"
      },
      maxChars: 150,
      button: $(this.$refs.addPhotoButton),
      validation: {
        allowedExtensions: ['jpg', 'jpeg', 'png', 'gif', 'pjpeg', 'webp', 'heic', 'heif', 'avif'],
        acceptFiles: ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp", "image/pjpeg", "image/webp", "image/heic", "image/heif", "image/avif"]
      }
    }).on('complete', (event, id, fileName, { photos }) => {
      if (photos) {
        this.$emit('update:photos', photos)
      }
    }).on('error', (event, id, fileName, reason) => {
      alert(`Please notify ThirdHome of the following error: ${reason}`)
    })
  }
}
</script>
