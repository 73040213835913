var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "photo cell six medium-four text-right",
      attrs: { photoId: _vm.photo.id }
    },
    [
      _c("img", { attrs: { src: _vm.photo.small, alt: "image_description" } }),
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.showEditModal(_vm.photo)
            }
          }
        },
        [_vm._v("edit")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }