import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    modalSelector: String
  }

  clicked (e) {
    if (e.target.tagName.toLowerCase() !== 'a') {
      this.dispatch('open', {detail: {modal: this.modalSelectorValue}})
    }
  }
}