import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputContainer", "containerSection"]

  toggleContainerSection(e) {
    if (e.target.value == 'true') {
      this.containerSectionTarget.classList.remove('hide')
    } else {
      this.containerSectionTarget.classList.add('hide')
    }
  }
  
  add(e) {
    e.preventDefault()
    const container = this.inputContainerTargets[0]
    const containerCopy = container.cloneNode(true)
    containerCopy.querySelector('input').value = ''
    e.target.parentElement.insertAdjacentElement('beforebegin', containerCopy)
  }

  remove(e) {
    e.preventDefault()
    if (this.inputContainerTargets.length == 1) {
      this.inputContainerTargets[0].querySelector('input').value = ''
    } else {
      e.target.parentElement.parentElement.remove()
    }
  }
}